var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-autocomplete',{attrs:{"no-filter":"","clearable":!_vm.readonly,"search-input":_vm.query,"items":_vm.items,"item-text":"address","item-value":"waypoint","return-object":"","label":_vm.label,"dark":_vm.dark,"append-icon":_vm.appendIcon
      ? _vm.appendIcon
      : _vm.address
        ? _vm.address.address == ''
          ? 'mdi-pencil'
          : ''
        : 'mdi-pencil',"attach":"","placeholder":_vm.placeholder,"persistent-placeholder":"","error-messages":_vm.errorMsg,"loading":_vm.loading || _vm.fetching,"hide-no-data":_vm.query === null || _vm.query === '' || _vm.query.length === 1,"no-data-text":_vm.fetching ? 'Loading' : 'Nothing matches your search criteria',"readonly":_vm.readonly},on:{"update:searchInput":function($event){_vm.query=$event},"update:search-input":function($event){_vm.query=$event},"change":_vm.flagForUpdate,"click:clear":function($event){return _vm.$emit('clear')},"click":function($event){return _vm.$emit('click')},"click:append":function($event){return _vm.$emit('append')}},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})
}
var staticRenderFns = []

export { render, staticRenderFns }