<template>
  <v-card
    @click="$emit('click')"
    ripple
    class="rounded-lg"
    :class="{
      'pl-0': removeLeftPadding,
      'pr-0': removeRightPadding,
    }"
    :flat="flat"
    :disabled="disabled"
    :loading="loading"
    color="background"
    max-width="90px"
  >
    <v-card-text
      class="pb-2"
      :class="{
        'pl-0': removeLeftPadding,
        'pr-0': removeRightPadding,
      }"
    >
      <v-card
        class="d-flex justify-center align-center rounded-lg"
        :color="color"
        width="50px"
        height="50px"
        flat
      >
        <v-icon :color="iconColor" :size="iconSize">{{ icon }}</v-icon>
      </v-card>
    </v-card-text>
    <v-card-text
      class="text-center pt-0"
      :class="{
        'pl-0': removeLeftPadding,
        'pr-0': removeRightPadding,
      }"
    >
      <span class="text-caption">{{ label }}</span>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "CardBtn",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "click me",
    },
    color: {
      type: String,
      default: "primary",
    },
    icon: {
      type: String,
      default: "mdi-cursor-default-click",
    },
    iconColor: {
      type: String,
      default: "white",
    },
    iconSize: {
      type: Number,
      default: 34,
    },
    flat: {
      type: Boolean,
      default: false,
    },
    removeLeftPadding: {
      type: Boolean,
      default: false,
    },
    removeRightPadding: {
      type: Boolean,
      default: false,
    },
  },
});
</script>
