<template>
  <v-card class="mb-5 rounded-lg grey lighten-3" @click="openDetails" flat>
    <v-card-title
      class="grey--text text--darken-4 text-body-1 font-weight-medium"
    >
      {{ station.name }}
    </v-card-title>
    <v-card-subtitle
      class="grey--text text--darken-4 text-body-2 font-weight-medium"
      v-if="station.address"
    >
      {{ station.address }}
    </v-card-subtitle>
    <v-card-title class="pt-0 mt-n2" v-else>
      address not provided
    </v-card-title>
    <template v-if="!isShowingTrip">
      <v-card-text
        class="d-flex justify-space-between"
        v-if="chargingDetails && !incompatible"
      >
        <span>
          Charge from {{ chargingDetails.percentageBeforeCharging }}% to
          {{ chargingDetails.percentageAfterCharging }}%
        </span>
        <span>
          {{ chargingDetails.chargingTime }}
        </span>
        <span> ~${{ chargingDetails.chargingCost }} </span>
      </v-card-text>
      <v-card-text v-else-if="incompatible">
        Your currently selected EV is not compatible with this station.
      </v-card-text>
      <v-card-text v-else>
        Please set an EV profile for your EV to see estimates.
      </v-card-text>
    </template>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import Charger from "@/logic/classes/charger_classes/charger";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import Connector from "@/logic/classes/charger_classes/connector";
import EVSE from "@/logic/classes/charger_classes/evse";
import { MutationTypes } from "@/logic/store/store_types";
import { MainDialogContent } from "@/logic/store/store_types";
import { GettersTypes } from "@/logic/store/store_types";
import { ChargingDetails } from "@/logic/types/sheared_local_types";
import type { State } from "@/logic/store/store_types";
import { mapState } from "vuex";

/** `Vue component:` renders a list tile card with limited details of charging stations
 * to be displayed in the `StationsContent` component
 *
 * @props station - the whole `Charger`class object. See `chargingStationsStore` for details.
 * */
export default Vue.extend({
  name: "ChargingStationCard",
  props: {
    station: Object as PropType<Charger>,
  },
  methods: {
    openDetails() {
      this.$store.commit(MutationTypes.setSelectedCharger, this.station.id);
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.CHARGING_STATION_DETAILS
      );
    },
  },
  computed: {
    incompatible(): boolean {
      const vehicle: Vehicle | undefined =
        this.$store.getters[GettersTypes.selectedVehicleData];
      if (!vehicle || !this.station) return true;
      if (this.station.isCompatible(vehicle) === "incompatible") return true;
      return false;
    },
    chargingDetails(): ChargingDetails | undefined {
      const typedState = this.$store.state as State;
      const vehicle: Vehicle | undefined =
        this.$store.getters[GettersTypes.selectedVehicleData];
      if (!vehicle || !this.station) return;

      const chargingDetails: (ChargingDetails | undefined)[] = (
        this.station as Charger
      ).evses.flatMap((evse: EVSE): (ChargingDetails | undefined)[] =>
        evse.connectors.map((connector: Connector) =>
          connector.getChargingEstimateDetails(
            vehicle,
            { min: typedState.SOCMin * 100, max: typedState.SOCMax * 100 },
            typedState.defaultPublicCostPerKWh,
            typedState.defaultCostPerMinDC
          )
        )
      );

      const filteredArray: ChargingDetails[] = [];

      chargingDetails.forEach((details) => {
        if (details) filteredArray.push(details);
      });

      return filteredArray[0];
    },
    ...mapState({
      isShowingTrip: (state: unknown) => !!(state as State).selectedTrip,
    }),
  },
});
</script>
