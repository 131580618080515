<template>
  <v-card flat class="px-5 pb-3">
    <v-row no-gutters justify="space-between">
      <span>
        <v-card-title>{{ displayName }}</v-card-title>
        <v-card-subtitle>{{ makeModelDisplayName }}</v-card-subtitle>
      </span>
      <v-btn icon>
        <v-icon @click="close">mdi-close</v-icon>
      </v-btn>
    </v-row>
    <v-card-text>
      <v-row class="mb-3">
        <v-col cols="4">
          <v-img
            width="100%"
            height="auto"
            max-height="160"
            :lazy-src="fallbackImgSrc"
            :src="imgSrc"
            class="rounded-lg"
            contain
            aspect-ratio="1"
          ></v-img>
        </v-col>
        <v-col cols="8">
          <v-card style="min-height: 120px">
            <v-card-text>
              <span>
                {{ vehicle.fuelType ? vehicle.fuelType : "Electric" }}
              </span>
              <v-row
                v-if="vehicle.fuelType === 'Electric' || !vehicle.fuelType"
                no-gutters
                justify="space-between"
              >
                <span>Charge</span>
                <span
                  >{{
                    vehicle.stateOfCharge
                      ? Math.round(vehicle.stateOfCharge)
                      : 100
                  }}%</span
                >
              </v-row>
              <v-slider
                v-if="vehicle.fuelType === 'Electric'"
                readonly
                :value="
                  vehicle.stateOfCharge
                    ? Math.round(vehicle.stateOfCharge)
                    : 100
                "
                min="1"
                max="100"
                track-color="grey lighten-2"
                hide-details
              ></v-slider>
              <span class="d-flex flex-column">
                <span>Last Known Location</span>
                <span>
                  <PulseLoader v-if="loading" />
                  <a v-else> {{ location }} </a>
                </span>
              </span>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <ElevatedBlockBtn class="mb-3" @click="handleViewBooking">
        Book Vehicle
      </ElevatedBlockBtn>
      <OutlinedBlockBtn class="mb-3" @click="handleViewSettings">
        Vehicle settings
      </OutlinedBlockBtn>
      <OutlinedBlockBtn class="mb-3" @click="handleSelect" v-if="!isSelected">
        Select This Vehicle
      </OutlinedBlockBtn>
      <TextBlockBtn @click="handleSelectOther"> Select Other </TextBlockBtn>
    </v-card-text>
    <v-card-title>Connectors</v-card-title>
    <v-card-text class="mb-5">
      <v-card class="mb-8">
        <v-card-title>Compatible</v-card-title>
        <v-card-subtitle>
          These are the connectors compatible with this vehicle.
        </v-card-subtitle>
        <ConnectorDisplayRow :connectors="connectors" />
      </v-card>
      <v-card>
        <v-card-title>Your Cables</v-card-title>
        <v-card-subtitle>
          These are the cables you are bringing with you.
        </v-card-subtitle>
        <ConnectorDisplayRow :connectors="cables" />
      </v-card>
    </v-card-text>
    <!-- TODO: add an image carousel here -->
    <VehicleImageCarousel :vehicle="vehicle" />
  </v-card>
</template>
<script lang="ts">
// frameworks
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
// types/class definitions
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  ManagedContentNames,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import type { VehicleConnectorsDisplayData } from "@/logic/types/vehicle_specific_types";
// helper functions
import getAssetSrc from "@/logic/utils/getAssetSrc";
// components
import PulseLoader from "../../ui-elements/loaders/PulseLoader.vue";
import ConnectorDisplayRow from "../../common/ConnectorDisplayRow.vue";
import VehicleImageCarousel from "./VehicleImageCarousel.vue";
import ElevatedBlockBtn from "../../ui-elements/buttons/ElevatedBlockBtn.vue";
import OutlinedBlockBtn from "../../ui-elements/buttons/OutlinedBlockBtn.vue";
import TextBlockBtn from "../../ui-elements/buttons/TextBlockBtn.vue";

export default Vue.extend({
  name: "VehicleDetailsContent",
  data() {
    return {
      loading: true,
      location: "unknown",
    };
  },
  computed: {
    /** List of hard point connectors indicated by the user that this vehicle is
     * equipped with for routing. */
    connectors(): VehicleConnectorsDisplayData[] {
      // ASSUMES: `Vehicle` class method that returns `VehicleConnectorsDisplayData`
      // still adds cable to display name for connectors that can connect to
      // socketed connectors on a charger.
      return (
        // filter out connectors with `"cable"` included in its display name.
        (this.vehicle as Vehicle | undefined)?.connectorsDisplayData.filter(
          (connector) => !connector.displayName.includes("cable")
        ) ?? []
      );
    },
    /** List of cables indicated that the user has to use with this vehicle for
     * routing. */
    cables(): VehicleConnectorsDisplayData[] {
      // ASSUMES: `Vehicle` class method that returns `VehicleConnectorsDisplayData`
      // still adds cable to display name for connectors that can connect to
      // socketed connectors on a charger.
      return (
        // filter out connectors without `"cable"` included in its display name.
        (this.vehicle as Vehicle | undefined)?.connectorsDisplayData.filter(
          (connector) => connector.displayName.includes("cable")
        ) ?? []
      );
    },
    /** Image source to default filler image. */
    fallbackImgSrc(): string {
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
    /** Image source for vehicle/model image, provides default filler image if no image. */
    imgSrc(): string {
      if (this.vehicle as Vehicle | undefined) {
        const images = (this.vehicle as Vehicle).imageSrcPaths;
        if (images.length) return images[0];
        else if ((this.vehicle as Vehicle).evModel)
          return (
            (this.vehicle.evModel as EVModel)?.imageSrc ??
            getAssetSrc("car_images/No_Image_Powersell.png")
          );
      }
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
    ...mapGetters({
      /** `Vehicle` class object for the target vehicle. */
      vehicle: GettersTypes.viewedFleetVehicleData,
    }),
    ...mapState({
      isSelected: (state: unknown) =>
        (state as State).viewedFleetVehicle ===
        (state as State).selectedVehicle,
      tooltipContent: (state: unknown) =>
        (state as State).managedContent.find(
          (content) => content.Name === ManagedContentNames.SoHToolTip
        ),
    }),
    displayName(): string {
      if ((this.vehicle as Vehicle | undefined)?.licensePlate)
        return this.vehicle.licensePlate;
      if ((this.vehicle as Vehicle | undefined)?.name) return this.vehicle.name;
      if (this.$vuetify.breakpoint.smAndDown) return "no license plate";
      return "unspecified license plate";
    },
    makeModelDisplayName(): string {
      if ((this.vehicle as Vehicle | undefined)?.evModel)
        return (this.vehicle.evModel as EVModel).name;
      return "unknown make and model";
    },
  },
  methods: {
    handleSelect() {
      this.$store.dispatch(ActionTypes.selectVehicle, this.vehicle);
    },
    handleSelectOther() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY
      );
    },
    async getLocation(): Promise<string> {
      return (
        (await (this.vehicle as Vehicle | undefined)?.locationDisplayStr()) ??
        "unknown"
      );
    },
    close() {
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
      this.$store.commit(MutationTypes.setViewedFleetVehicle, undefined);
    },
    handleViewSettings() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_SETTINGS
      );
    },
    handleViewBooking() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_BOOKING
      );
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.location = await this.getLocation();
      this.loading = false;
    });
  },
  components: {
    PulseLoader,
    ConnectorDisplayRow,
    VehicleImageCarousel,
    ElevatedBlockBtn,
    OutlinedBlockBtn,
    TextBlockBtn,
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
