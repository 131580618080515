<template>
  <v-row align="center" justify="center" class="mb-7 px-5">
    <v-btn large icon @click="decrementValue">
      <v-icon large> mdi-minus </v-icon>
    </v-btn>
    <v-text-field
      :value="passedValue ?? null"
      class="rounded-lg center-aligned-input none-underlined-input pt-5"
      type="number"
      hide-spin-buttons
      :rules="[validateValue]"
      @input="handleSetValue"
      dense
      :error-messages="errorMessages"
    ></v-text-field>
    <v-btn large icon @click="incrementValue">
      <v-icon large> mdi-plus </v-icon>
    </v-btn>
  </v-row>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";

export default Vue.extend({
  name: "ExtraDetailsCard",
  props: {
    passedValue: {
      type: Number,
      default: 0,
    },
    wholeNumber: {
      type: Boolean,
      default: false,
    },
    allowNegatives: {
      type: Boolean,
      default: false,
    },
    errorMessages: {
      type: String as PropType<string | string[] | undefined>,
    },
  },
  methods: {
    emitUpdate(val: number): void {
      this.$emit("update", val);
    },
    /** Increases the extra weight by 1 kg. */
    incrementValue(): void {
      // exit early if extra weight is null
      if (!this.passedValue) {
        this.handleSetValue(1);
        return;
      }
      // parse value
      const parsedVal = parseIntOrFloat(this.passedValue);
      // exit early if parsing value failed
      if (!parsedVal) return;
      // increment value
      this.handleSetValue(parsedVal + 1);
    },
    /** Reduces the value by 1 kg. */
    decrementValue(): void {
      // exit early if value is null.
      if (this.passedValue === null) return;
      // exit early if value is 0
      if (!this.allowNegatives && this.passedValue <= 0) return;
      // parse value
      const parsedVal = parseIntOrFloat(this.passedValue);
      // exit early if parsing value failed
      if (parsedVal === undefined) return;
      // decrement value
      this.handleSetValue(parsedVal - 1);
    },
    validateValue(val: number | string | null): boolean | string {
      // allow no value to pass validation
      if (val === null || val === 0 || val === "0") return true;
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return "needs to be a number";
      if (this.wholeNumber && !Number.isInteger(parsedVal))
        return "needs to be an whole number";
      return true;
    },
    handleSetValue(val: number | string | null): void {
      if (val === null || val === 0 || val === "0") {
        this.emitUpdate(0);
        return;
      }
      const parsedVal = parseIntOrFloat(val);
      if (!parsedVal) return;
      if (this.wholeNumber && !Number.isInteger(parsedVal)) return;
      this.emitUpdate(parsedVal);
    },
  },
});
</script>

<style scoped>
#battery-custom-input {
  /* position off set centered element 145px width within a 80% width container */
  padding-left: calc(40% - 108px);
  padding-right: calc(40% - 37px);
}

.right-aligned-input >>> input {
  text-align: right;
}

.center-aligned-input >>> input {
  text-align: center;
}

.center-aligned-input >>> .v-messages__message {
  text-align: center;
}

.none-underlined-input >>> .v-input__slot::before {
  border-style: none !important;
}

.off-set-input-message >>> .v-messages__message {
  padding-top: 16px;
}
</style>
