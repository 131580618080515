<template>
  <v-card flat class="background pa-2">
    <v-card-title>
      Edit stop details

      <v-spacer />
      <ElevatedBtn
        :disabled="!validForUpdate"
        :loading="loading"
        @click="handleUpdate"
      >
        Update
      </ElevatedBtn>
    </v-card-title>
    <v-card-text>
      <AddressAutocompleteInput
        :initialValue="addressData"
        @update="handleAddressUpdate"
        allowFavLocations
        placeholder="Search for stop address..."
        :errorMsg="addressError"
        :loading="addressLoading"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Duration
    </v-card-title>
    <v-card-text>
      <StayDurationInput
        @update="handleStayDurationChange"
        :initialValue="duration"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Charging
    </v-card-title>
    <v-card-text>
      <v-slider
        :label="`charge up to ${chargeTo}%`"
        track-color="grey lighten-2"
        color="primary"
        v-model="chargeTo"
        min="0"
        max="100"
        step="1"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Weight changes
    </v-card-title>
    <v-card-text>
      <BatteryLikeInput
        wholeNumber
        allowNegatives
        :passedValue="weightChange"
        @update="handleWeightSet"
        :errorMessages="weightChangeError"
      />
    </v-card-text>
    <v-card-title class="tertiary--text pt-5 pb-0 mb-0">
      Energy use
    </v-card-title>
    <v-card-text>
      <v-slider
        :label="`${energyUsed}kWh`"
        track-color="grey lighten-2"
        color="primary"
        v-model="energyUsed"
        min="0"
        max="1"
        step="0.01"
      />
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import Vue, { PropType } from "vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../ui-elements/inputs/AddressAutocompleteInput.vue";
import StayDurationInput, {
  StayDurationInputUpdateObj,
} from "../../ui-elements/inputs/StayDurationInput.vue";
import BatteryLikeInput from "../../ui-elements/inputs/BatteryLikeInput.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { mapState } from "vuex";
import { State } from "@/logic/store/store_types";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
export default Vue.extend({
  name: "WaypointEditForm",
  components: {
    AddressAutocompleteInput,
    StayDurationInput,
    BatteryLikeInput,
    ElevatedBtn,
  },
  props: {
    initialValue: {
      type: Object as PropType<TripLocation>,
    },
    remainingLoad: {
      type: Number,
      default: 0,
    },
  },
  mounted() {
    if (this.initialValue) {
      this.setInitialValue();
    }
  },
  watch: {
    initialValue() {
      this.setInitialValue();
    },
  },
  data() {
    return {
      loading: false,
      errorMsg: "",
      addressData: undefined as processedAddressObj | undefined,
      addressLoading: false,
      addressError: null as string | null,
      duration: 0,
      chargeTo: 0,
      energyUsed: 0,
      weightChange: 0,
      weightChangeError: undefined as string | undefined,
    };
  },
  computed: {
    ...mapState({
      favLocations: (state: unknown) => (state as State).favLocations,
    }),
    validForUpdate(): boolean {
      // guard clause
      if (!this.addressData) return false;
      if (this.weightChangeError) return false;
      if (this.addressError) return false;

      // check if anything has changed
      if (
        this.addressData.address === this.initialValue.address &&
        this.duration === (this.initialValue.stay ?? 0) &&
        this.chargeTo === (this.initialValue.stateOfChargeAfterCharging ?? 0) &&
        this.energyUsed === (this.initialValue.nonDrivingEnergyUsed ?? 0) &&
        this.weightChange === (this.initialValue.weightChange ?? 0)
      ) {
        return false;
      }

      return true;
    },
  },
  methods: {
    setInitialValue() {
      // guard clause
      if (!this.initialValue) return;
      // set initial values based on values in the trip location object
      this.addressData = {
        address: this.initialValue.address,
        coordinates: this.initialValue.coordinates.asCapitalizedObj,
        name: this.initialValue.name,
      };
      this.duration = this.initialValue.stay ?? 0;
      this.chargeTo = this.initialValue.stateOfChargeAfterCharging ?? 0;
      this.energyUsed = this.initialValue.nonDrivingEnergyUsed ?? 0;
      this.weightChange = this.initialValue.weightChange ?? 0;
    },
    handleStayDurationChange(val: StayDurationInputUpdateObj): void {
      this.duration = val.duration;
    },
    handleAddressUpdate(val: AddressAutocompleteInputUpdateObj): void {
      this.addressData = val.addressData;
      if (val.addressData?.localId) {
        const favLocationData = this.favLocations.find(
          (loc) => loc.localId === val.addressData?.localId
        );
        if (!favLocationData) return;
        if (favLocationData.planningData?.loadWeightChange) {
          this.weightChange = favLocationData.planningData.loadWeightChange;
        }
        if (favLocationData.planningData?.energyUsed) {
          this.energyUsed = favLocationData.planningData.energyUsed;
        }
        if (favLocationData.planningData?.SOCAfterCharging) {
          this.chargeTo = favLocationData.planningData.SOCAfterCharging;
        }
        if (favLocationData.planningData?.stayDuration) {
          this.duration = favLocationData.planningData.stayDuration;
        }
      }
    },
    handleWeightSet(val: number) {
      // validation
      if (
        this.remainingLoad + (this.initialValue.weightChange ?? 0) + val <
        0
      ) {
        this.weightChangeError =
          "you can't off load more than you are carrying";
      } else {
        this.weightChangeError = undefined;
      }
      // update sate
      this.weightChange = val;
    },
    handleUpdate() {
      // guard clauses
      if (!this.validForUpdate) return;
      if (!this.addressData) return; // should never happen as checked in `validForUpdate`, satisfies TS no non-null assertions.

      // update trip location
      const newLocation = new TripLocation({
        ...this.initialValue,
        address: this.addressData.address,
        coordinates: new Coordinate({
          latitude: this.addressData.coordinates.Latitude,
          longitude: this.addressData.coordinates.Longitude,
        }),
        name: this.addressData?.name,
        stay: this.duration,
        stateOfChargeAfterCharging: this.chargeTo,
        nonDrivingEnergyUsed: this.energyUsed,
        weightChange: this.weightChange,
      });
      // emit update event
      this.$emit("update", newLocation);
    },
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
