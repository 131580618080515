<template>
  <!-- planning animation page -->
  <InfoPanelCardWrapper v-if="planning">
    <DescriptiveLoader message="Almost there!" />
  </InfoPanelCardWrapper>
  <!-- select vehicle page -->
  <InfoPanelCardWrapper v-else-if="trip">
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-md-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            style="max-width: 15%"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <SelectedComparisonVehicleCard
      :vehicle="trip.displayedComparisonData.vehicle"
    />
    <v-card flat style="max-width: 90vw">
      <v-card-title class="pl-0"> Vehicles you are comparing </v-card-title>
      <v-slide-group>
        <v-slide-item
          v-for="(comparison, index) in trip.comparisons"
          :key="'comparison-' + index"
          style="width: 150px"
          class="my-3 mr-2"
          :class="index == 0 ? 'pl-1' : ''"
        >
          <ComparisonCard
            :comparison="comparison"
            :isShowing="comparison.localId === trip.displayedComparisonId"
            @showMe="showComparison(comparison.localId)"
          />
        </v-slide-item>
      </v-slide-group>
      <v-row no-gutters class="mt-3 mb-5">
        <v-spacer />
        <ElevatedBtn @click="() => (showDialog = true)">
          add comparison vehicle
        </ElevatedBtn>
      </v-row>
    </v-card>
    <v-card class="mb-5 rounded-lg">
      <v-card-title> Performance summary </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-icon color="primary" small class="mr-1">
            mdi-progress-clock
          </v-icon>
          {{
            trip.displayedComparisonData &&
            trip.displayedComparisonData.drivingTime
              ? niceDuration(trip.displayedComparisonData.drivingTime)
              : "unknown "
          }}
          driving time
        </v-row>
        <v-row no-gutters>
          <v-icon color="primary" small class="mr-1">
            mdi-map-marker-radius-outline
          </v-icon>
          {{
            trip.displayedComparisonData &&
            trip.displayedComparisonData.drivingDistance
              ? Math.round(
                  trip.displayedComparisonData.drivingDistance / 1000
                ) + " km"
              : "unknown"
          }}
          distance traveled
        </v-row>
      </v-card-text>
      <v-card-title class="text-subtitle-2"> Charging Time </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col class="background mr-1">
            <v-icon color="primary" small> mdi-progress-clock </v-icon>
            <span class="primary--text mr-1 font-weight-medium"> AC </span>
            {{
              trip.displayedComparisonData
                ? niceDuration(
                    trip.displayedComparisonData.estimatedChargingTime
                      .ac_charging_time
                  )
                : "unknown "
            }}
          </v-col>
          <v-col class="background ml-1">
            <v-icon color="primary" small> mdi-progress-clock </v-icon>
            <span class="primary--text mr-1 font-weight-medium"> DC </span>
            {{
              trip.displayedComparisonData
                ? niceDuration(
                    trip.displayedComparisonData.estimatedChargingTime
                      .dc_charging_time
                  )
                : "unknown "
            }}
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title class="text-subtitle-2"> Battery usage </v-card-title>
      <v-card-text>
        <v-row no-gutters class="background mb-2">
          <v-icon color="primary" small class="mr-1"> mdi-ev-station </v-icon>
          {{
            trip.displayedComparisonData
              ? Math.round(
                  trip.displayedComparisonData.numberOfChargingStopsNeeded
                ) +
                "-" +
                (Math.round(
                  trip.displayedComparisonData.numberOfChargingStopsNeeded
                ) +
                  1)
              : "unknown "
          }}
          charging stops required
        </v-row>
        <v-row no-gutters class="background mb-2">
          <v-icon color="primary" small class="mr-1">
            mdi-battery-charging
          </v-icon>
          {{
            trip.displayedComparisonData
              ? Math.round(trip.displayedComparisonData.totalChargeUsed * 100) +
                "% "
              : "unknown "
          }}
          battery capacity used
        </v-row>
        <v-row no-gutters class="background mb-2">
          <v-icon color="primary" small class="mr-1">
            mdi-lightning-bolt-circle
          </v-icon>
          {{
            trip.displayedComparisonData
              ? Math.round(trip.displayedComparisonData.totalEnergy) + "kWh "
              : "unknown energy "
          }}
          needed to complete this trip
        </v-row>
      </v-card-text>
    </v-card>
    <v-card class="mb-10 rounded-lg">
      <v-card-title>
        <v-icon color="primary" class="mr-1"> mdi-piggy-bank-outline </v-icon>
        {{
          trip.displayedComparisonData
            ? "$" +
              getFuelSavingsEstimate(trip.displayedComparisonData).toFixed(2)
            : "unknown"
        }}
        fuel savings
      </v-card-title>
      <v-card-title> cost estimates </v-card-title>
      <v-card-text>
        <v-row no-gutters class="background mb-2">
          <v-icon color="primary" small class="mr-1">
            mdi-card-account-details-outline
          </v-icon>
          {{
            trip.displayedComparisonData
              ? "$" +
                getRUCEstimate(
                  trip.displayedComparisonData.drivingDistance
                ).toFixed(2) +
                " "
              : "unknown "
          }}
          road user charges
        </v-row>
        <v-row no-gutters>
          <v-col class="background mr-1">
            <v-icon color="primary" small> mdi-currency-usd </v-icon>
            {{
              trip.displayedComparisonData
                ? "$" +
                  getACCostEstimate(
                    trip.displayedComparisonData.totalEnergy
                  ).toFixed(2) +
                  " "
                : "unknown"
            }}
            <span> max slow charging cost </span>
          </v-col>
          <v-col class="background ml-1">
            <v-icon color="primary" small> mdi-currency-usd </v-icon>
            {{
              trip.displayedComparisonData
                ? "$" +
                  getDCCostEstimate(
                    trip.displayedComparisonData.totalEnergy,
                    trip.displayedComparisonData.estimatedChargingTime
                      .dc_charging_time
                  ).toFixed(2) +
                  " "
                : "unknown"
            }}
            <span> max fast charging cost </span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title> Fuel savings </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-col class="background mr-1">
            <v-icon color="primary" small> mdi-currency-usd </v-icon>
            {{
              trip.displayedComparisonData
                ? "$" +
                  getFuelCostEstimate(trip.displayedComparisonData).toFixed(2) +
                  " "
                : "unknown "
            }}
            <span> equivalent fuel cost </span>
          </v-col>
          <v-col class="background ml-1">
            <v-icon color="primary" small> mdi-piggy-bank-outline </v-icon>
            {{
              trip.displayedComparisonData
                ? "$" +
                  getFuelSavingsEstimate(trip.displayedComparisonData).toFixed(
                    2
                  ) +
                  " "
                : "unknown "
            }}
            <span> fuel savings </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <!-- Actions section -->
    <div
      class="px-5"
      :class="classes"
      style="
        position: fixed;
        top: calc(100dvh - 50px);
        left: 0;
        width: 40%;
        max-width: 600px;
      "
      :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;' : ''"
    >
      <div class="background rounded-lg">
        <ElevatedBlockBtn @click="planTrip" class="mb-3">
          Create itinerary with this vehicle
        </ElevatedBlockBtn>
      </div>
    </div>
    <GenericDialog :activator="showDialog" @close="showDialog = false">
      <VehicleSearchContent @vehicle-select="addComparison" />
    </GenericDialog>
  </InfoPanelCardWrapper>
  <!-- No Trip Page -->
  <InfoPanelCardWrapper v-else>
    <NoTripContent />
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import Vue from "vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { RouteNames } from "@/logic/router";
import { RawLocation } from "vue-router";
import {
  ActionTypes,
  CalcVsType,
  GettersTypes,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import ComparisonCard from "../components/trips/statistics/info-panel-content/ComparisonCard.vue";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import Trip from "@/logic/classes/trip_classes/trip";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import queryValueToString from "@/logic/utils/queryValueToString";
import parseIntOrFloat from "@/logic/utils/parseNumOrFloat";
import { mapGetters, mapState } from "vuex";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import ElevatedBtn from "../components/ui-elements/buttons/ElevatedBtn.vue";
import NoTripContent from "../components/trips/NoTripContent.vue";
import DescriptiveLoader from "../components/ui-elements/loaders/DescriptiveLoader.vue";
import GenericDialog from "../components/dialog-wrappers/GenericDialog.vue";
import VehicleSearchContent from "../components/trips/comparison/VehicleSearchContent.vue";
import ElevatedBlockBtn from "../components/ui-elements/buttons/ElevatedBlockBtn.vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import TripComparison from "@/logic/classes/trip_classes/tripComparison";
import SelectedComparisonVehicleCard from "../components/vehicles/SelectedComparisonVehicleCard.vue";

export default Vue.extend({
  name: "PlanningSelectVehicleView",
  components: {
    InfoPanelCardWrapper,
    TripsHomeBtn,
    ComparisonCard,
    ElevatedBtn,
    NoTripContent,
    DescriptiveLoader,
    GenericDialog,
    VehicleSearchContent,
    ElevatedBlockBtn,
    SelectedComparisonVehicleCard,
  },
  computed: {
    items(): { text: string; to: RawLocation }[] {
      const useSmall = this.$vuetify.breakpoint.smAndDown;
      if ((this.trip as Trip | undefined)?.status !== "unplanned")
        return [
          {
            text: useSmall ? "Destination" : "Add Destination",
            to: { name: RouteNames.tripAddDestination },
          },
          {
            text: useSmall ? "Origin" : "Add Origin",
            to: { name: RouteNames.tripAddOrigin },
          },
          {
            text: useSmall ? "Details" : "Add Details",
            to: { name: RouteNames.tripAddDetails },
          },
          {
            text: useSmall ? "Stops" : "Add Stops",
            to: { name: RouteNames.tripAddStops },
          },
          {
            text: useSmall ? "Vehicle" : "Select Vehicle",
            to: { name: RouteNames.tripSelectVehicle },
          },
          {
            text: "Itinerary",
            to: { name: RouteNames.tripItinerary },
          },
        ];
      return [
        {
          text: useSmall ? "Destination" : "Add Destination",
          to: { name: RouteNames.tripAddDestination },
        },
        {
          text: useSmall ? "Origin" : "Add Origin",
          to: { name: RouteNames.tripAddOrigin },
        },
        {
          text: useSmall ? "Details" : "Add Details",
          to: { name: RouteNames.tripAddDetails },
        },
        {
          text: useSmall ? "Stops" : "Add Stops",
          to: { name: RouteNames.tripAddStops },
        },
        {
          text: useSmall ? "Vehicle" : "Select Vehicle",
          to: { name: RouteNames.tripSelectVehicle },
        },
      ];
    },
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
      fetching: GettersTypes.fetching,
    }),
    ...mapState({
      kWhCostAC: (state: unknown) => (state as State).defaultHomeCostPerKWh,
      kWhCostDC: (state: unknown) => (state as State).defaultPublicCostPerKWh,
      minCostDC: (state: unknown) => (state as State).defaultCostPerMinDC,
      evRUC: (state: unknown) => (state as State).electricRoadUserCharges,
      fuelCostPerL: (state: unknown) =>
        (state as State).calcVs === CalcVsType.PETROL
          ? (state as State).petrolCostPerLitre
          : (state as State).dieselCostPerLitre,
      kmPerL: (state: unknown) =>
        (state as State).calcVs === CalcVsType.PETROL
          ? (state as State).petrolKmPerLitre
          : (state as State).dieselKmPerLitre,
      animation: (state: unknown) => {
        return (state as State).infoPanelAnimation;
      },
    }),
    classes() {
      if (this.animation == "slide-left")
        return "pwt-info-panel-right-to-left-animation";
      if (this.animation == "slide-right")
        return "pwt-info-panel-left-to-right-animation";
      return "";
    },
  },
  data() {
    return {
      showDialog: false,
      planning: true,
    };
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripAddStops ||
        to.name === RouteNames.tripAddDetails ||
        to.name === RouteNames.tripAddOrigin ||
        to.name === RouteNames.tripAddDestination ||
        to.name === RouteNames.tripSelectVehicle ||
        to.name === RouteNames.tripStats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
  methods: {
    /**
     * A wrapper for getNiceDuration that always returns a string.
     * The boolean argument is passed through to getNiceDuration to indicate
     * the type of human readable string that should be returned.
     * @param {number} duration_seconds - duration in seconds
     * @returns {string} - duration in a human-readable format (e.g. 1hr 2mins)
     */
    niceDuration(duration_seconds: number) {
      return getNiceDuration(duration_seconds, true);
    },
    /**
     * Updates the currently displayed trip comparison by localId, and commits
     * the updated trip to the store.
     * @param {string} localId - The localId of the comparison to display.
     */
    showComparison(localId: string) {
      const updatedTrip = this.trip;
      updatedTrip.displayedComparisonId = localId;
      this.$store.commit(MutationTypes.updateIndividualTrip, updatedTrip);
    },
    /**
     * Builds a trip from the query parameters and commits the trip to the store.
     * The trip is built by extracting the origin, destination, and waypoints from
     * the query parameters and creating a new instance of the Trip class as well
     * as additional route planning parameters e.g. state of charge parameters,
     * driving style adjustments ect. The trip is then planned and the resulting
     * comparison is stored on the trip object. The trip is then committed to the
     * store with the localId as the key.
     * @param {Query} $route.query - The query object containing the parameters
     *   for the trip.
     * @returns {Promise<void>}
     */
    async buildTripFromQuery() {
      this.planning = true; // trigger planning animation

      // get query values
      const originAddress = queryValueToString(this.$route.query.origAddress);
      const originLat = queryValueToNumber(this.$route.query.origLat);
      const originLon = queryValueToNumber(this.$route.query.origLon);
      const originName = queryValueToString(this.$route.query.origName);

      const destAddress = queryValueToString(this.$route.query.destAddress);
      const destLat = queryValueToNumber(this.$route.query.destLat);
      const destLon = queryValueToNumber(this.$route.query.destLon);
      const destName = queryValueToString(this.$route.query.destName);

      let waypoints: TripLocation[] = [];
      const stopKeys = Object.keys(this.$route.query).filter((key) =>
        key.startsWith("stop")
      );
      let groupedKeys = [];
      for (let i = 0; i < stopKeys.length; i++) {
        const key = stopKeys[i];
        const stopNumber = parseIntOrFloat(key[4]); // ASSUMES pattern of `stop[stop index + 1][stop property key]` is still being used
        if (!stopNumber) continue;
        if (!groupedKeys[stopNumber - 1]) groupedKeys[stopNumber - 1] = [key];
        else groupedKeys[stopNumber - 1].push(key);
      }
      for (let index = 0; index < groupedKeys.length; index++) {
        const group = groupedKeys[index];
        const newWaypoint = new TripLocation();
        for (let i = 0; i < group.length; i++) {
          const key = group[i];
          if (key === `stop${index + 1}Address`) {
            const parsedAddress = queryValueToString(this.$route.query[key]);
            if (parsedAddress) newWaypoint.address = parsedAddress;
          }
          if (key === `stop${index + 1}Lat`) {
            const parsedLat = queryValueToNumber(this.$route.query[key]);
            if (parsedLat) newWaypoint.coordinates.latitude = parsedLat;
          }
          if (key === `stop${index + 1}Lon`) {
            const parsedLon = queryValueToNumber(this.$route.query[key]);
            if (parsedLon) newWaypoint.coordinates.longitude = parsedLon;
          }
          if (key === `stop${index + 1}Name`) {
            const parsedName = queryValueToString(this.$route.query[key]);
            if (parsedName) newWaypoint.name = parsedName;
          }
          if (key === `stop${index + 1}Stay`) {
            const parsedStay = queryValueToNumber(this.$route.query[key]);
            if (parsedStay) newWaypoint.stay = parsedStay;
          }
          if (key === `stop${index + 1}LeavingSoC`) {
            const parsedSoC = queryValueToNumber(this.$route.query[key]);
            if (parsedSoC) newWaypoint.stateOfChargeAfterCharging = parsedSoC;
          }
          if (key === `stop${index + 1}Weight`) {
            const parsedWeight = queryValueToNumber(this.$route.query[key]);
            if (parsedWeight) newWaypoint.weightChange = parsedWeight;
          }
          if (key === `stop${index + 1}UsedEnergy`) {
            const parsedEnergy = queryValueToNumber(this.$route.query[key]);
            if (parsedEnergy) newWaypoint.nonDrivingEnergyUsed = parsedEnergy;
          }
        }
        waypoints.push(newWaypoint);
      }
      // guard clauses
      if (!originAddress || !originLat || !originLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      if (!destAddress || !destLat || !destLon) {
        // TODO: show error
        this.planning = false;
        return;
      }

      // prep locations
      const locations: TripLocation[] = [];
      // add origin
      locations.push(
        new TripLocation({
          address: originAddress,
          coordinates: new Coordinate({
            latitude: originLat,
            longitude: originLon,
          }),
          name: originName,
        })
      );
      // add stops

      locations.push(...waypoints);
      // add destination
      locations.push(
        new TripLocation({
          address: destAddress,
          coordinates: new Coordinate({
            latitude: destLat,
            longitude: destLon,
          }),
          name: destName,
        })
      );
      // create trip
      const trip = new Trip({
        locations: locations,
        roundTripFlag: false,
      });
      trip.SOCAct =
        queryValueToNumber(this.$route.query.SOCAct) ??
        (this.$store.state as State).SOCAct;
      trip.SOCEnd =
        queryValueToNumber(this.$route.query.SOCEnd) ??
        (this.$store.state as State).SOCEnd;
      trip.SOCMin =
        queryValueToNumber(this.$route.query.SOCMin) ??
        (this.$store.state as State).SOCMin;
      trip.SOCMax =
        queryValueToNumber(this.$route.query.SOCMax) ??
        (this.$store.state as State).SOCMax;
      trip.SpeedAdjustment = queryValueToNumber(this.$route.query.speedAdj);
      trip.startingLoad =
        queryValueToNumber(this.$route.query.extraWeight) ?? 0;
      trip.passengers = queryValueToNumber(this.$route.query.passengers) ?? 0;
      trip.vehicle = this.$store.getters[GettersTypes.selectedVehicleData] as
        | Vehicle
        | undefined;
      // plan trip
      // await trip.planTrip();
      await trip.currentTripToComparison();
      this.$store.commit(MutationTypes.updateIndividualTrip, trip);
      this.$store.commit(MutationTypes.setSelectedTrip, trip.local_id);
      this.planning = false;
    },
    /**
     * Updates the route query based on the current trip state.
     * It will include all the waypoints, as well as the origin and destination.
     * If any of the waypoints do not have a name, it will not be included in the query.
     * If the trip is a round trip, the origin and destination will be the same.
     * It will also remove any stops that do not have a valid location.
     * @returns {void}
     */
    updateRouteQuery() {
      const typedTrip = this.trip as Trip | undefined;
      if (!typedTrip) return;

      const newQuery = {
        ...this.$route.query,
      };

      const originAddressData = typedTrip.locations[0];
      const destinationAddressData = typedTrip.roundTripFlag
        ? typedTrip.locations[0]
        : typedTrip.locations[typedTrip.locations.length - 1];
      const waypoints = [...typedTrip.locations];
      waypoints.shift(); // remove origin
      if (!typedTrip.roundTripFlag) waypoints.pop(); // remove destination

      if (destinationAddressData) {
        newQuery.destAddress = encodeURI(destinationAddressData.address);
        newQuery.destLat =
          destinationAddressData.coordinates.latitude.toString();
        newQuery.destLon =
          destinationAddressData.coordinates.longitude.toString();
        if (destinationAddressData.name) {
          newQuery.destName = destinationAddressData.name;
        } else {
          delete newQuery.destName;
        }
      } else {
        delete newQuery.destAddress;
        delete newQuery.destLat;
        delete newQuery.destLon;
        delete newQuery.destName;
      }

      if (originAddressData) {
        newQuery.origAddress = encodeURI(originAddressData.address);
        newQuery.origLat = originAddressData.coordinates.latitude.toString();
        newQuery.origLon = originAddressData.coordinates.longitude.toString();
        if (originAddressData.name) {
          newQuery.origName = originAddressData.name;
        } else {
          delete newQuery.origName;
        }
      } else {
        delete newQuery.origAddress;
        delete newQuery.origLat;
        delete newQuery.origLon;
        delete newQuery.origName;
      }

      // rebuild stops

      Object.keys(newQuery)
        .filter((key) => key.startsWith("stop"))
        .forEach((key) => {
          delete newQuery[key];
        }); // remove old stops

      waypoints.forEach((stop, index) => {
        newQuery[`stop${index + 1}Address`] = encodeURI(stop.address);
        newQuery[`stop${index + 1}Lat`] = stop.coordinates.latitude.toString();
        newQuery[`stop${index + 1}Lon`] = stop.coordinates.longitude.toString();
        if (stop.name) newQuery[`stop${index + 1}Name`] = stop.name;
        if (stop.stay) newQuery[`stop${index + 1}Stay`] = stop.stay.toString();
        if (stop.stateOfChargeAfterCharging)
          newQuery[`stop${index + 1}LeavingSoC`] =
            stop.stateOfChargeAfterCharging.toString();
        if (stop.weightChange)
          newQuery[`stop${index + 1}Weight`] = stop.weightChange.toString();
        if (stop.nonDrivingEnergyUsed)
          newQuery[`stop${index + 1}UsedEnergy`] =
            stop.nonDrivingEnergyUsed.toString();
      }); // add new stops

      // check if nothing changed
      if (
        Object.keys(newQuery).every(
          (key) => newQuery[key] === this.$route.query[key]
        ) &&
        Object.keys(this.$route.query).every(
          (key) => newQuery[key] === this.$route.query[key]
        )
      )
        return; // nothing to update

      // update query
      this.$router.replace({
        query: newQuery,
      });
    },
    /**
     * Adds the current trip to the comparisons list and updates the store.
     * It will not add a duplicate comparison if the comparison already exists.
     * @return {void}
     */
    buildComparisonFromSelectedEV() {
      this.trip.currentTripToComparison();
      this.$store.commit(MutationTypes.updateIndividualTrip, this.trip);
    },
    /**
     * Adds a comparison vehicle to the trip and shows the comparison results.
     * If the trip is not loaded, it will not add the comparison.
     * @param {Vehicle} vehicle - The vehicle to add as a comparison.
     * @return {Promise<void>} - A promise that resolves when the comparison is added and processed.
     */
    async addComparison(vehicle: Vehicle) {
      this.showDialog = false;
      this.planning = true;
      if (this.trip) {
        await this.trip.addComparison(vehicle);
      }
      this.planning = false;
    },
    /**
     * Plan the trip with the currently selected vehicle and show the itinerary.
     * @return {void}
     */
    planTrip() {
      this.planning = true;
      const trip = this.trip as Trip | undefined;
      if (!trip) return;
      const selectedVehicleData = trip.displayedComparisonData?.vehicle;
      if (!selectedVehicleData) return;
      trip.vehicle = selectedVehicleData;
      this.$store.dispatch(ActionTypes.showTrip, trip);
      this.planning = false;
    },
    /**
     * Calculates the cost of charging at home in NZD given the energy used in kWh.
     * @param {number} energy - The energy used in kWh.
     * @return {number} The cost of charging at home in NZD.
     */
    getACCostEstimate(energy: number) {
      return this.kWhCostAC * energy;
    },
    /**
     * Calculates the cost of fast charging in NZD given the energy used in kWh
     * and the time taken in seconds.
     * @param {number} energy - The energy used in kWh.
     * @param {number} timeSeconds - The time taken to charge in seconds.
     * @return {number} The cost of charging in NZD.
     */
    getDCCostEstimate(energy: number, timeSeconds: number) {
      return this.kWhCostDC * energy + this.minCostDC * (timeSeconds / 60);
    },
    /**
     * Calculates the road user charges estimate in NZD given the distance in meters.
     * @param {number} distance_meters - The distance in meters.
     * @return {number} The road user charges estimate in NZD.
     */
    getRUCEstimate(distance_meters: number) {
      const distance_km = distance_meters / 1000;
      const distance_1000_km = distance_km / 1000;
      return distance_1000_km * this.evRUC;
    },
    /**
     * Calculates the fuel cost estimate in NZD given the TripComparison object.
     * @param {TripComparison} comparison - The TripComparison object.
     * @return {number} The fuel cost estimate in NZD.
     */
    getFuelCostEstimate(comparison: TripComparison) {
      return comparison.equivalentFuelCost({
        fuelCostPerLitre: this.fuelCostPerL,
        kmPerLitre: this.kmPerL,
      });
    },
    /**
     * Calculates the fuel savings estimate in NZD given the TripComparison object.
     * It is the difference between the fuel cost estimate and the fast charging cost estimate.
     * @param {TripComparison} comparison - The TripComparison object.
     * @return {number} The fuel savings estimate in NZD.
     */
    getFuelSavingsEstimate(comparison: TripComparison) {
      const fuelCostEstimate = this.getFuelCostEstimate(comparison);
      const DCCostEstimate = this.getDCCostEstimate(
        comparison.totalEnergy,
        comparison.estimatedChargingTime.dc_charging_time
      );

      return fuelCostEstimate - DCCostEstimate;
    },
  },
  async mounted() {
    this.$nextTick(async () => {
      // ASSUMES: if still fetching base optimiser data watch will be triggered once finished.
      if (!this.fetching) {
        if (this.trip) {
          if (!this.trip.comparisons.length)
            this.buildComparisonFromSelectedEV();
          await this.updateRouteQuery();
          this.planning = false;
        }
        if (!this.trip) {
          await this.buildTripFromQuery();
        }
      }
    });
  },
  watch: {
    async fetching(val) {
      if (!val) {
        if (this.trip) this.planning = false;
        if (!this.trip) await this.buildTripFromQuery();
      }
    },
  },
});
</script>
<style scoped>
.pwt-info-panel-left-to-right-animation {
  left: -41%;
  -webkit-animation: left-to-right 700ms linear forwards;
  animation: left-to-right 700ms linear forwards;
}

.pwt-info-panel-right-to-left-animation {
  left: 0;
  -webkit-animation: right-to-left 1s linear forwards;
  animation: right-to-left 1s linear forwards;
}

@-webkit-keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}
</style>
