<template>
  <v-card flat class="background">
    <v-card-text>
      <OutlinedBlockBtn
        v-if="$vuetify.breakpoint.smAndDown"
        @click="$emit('back')"
        class="mb-3 mt-10"
      >
        back to saved trips
      </OutlinedBlockBtn>
    </v-card-text>
    <v-row
      no-gutters
      :align="$vuetify.breakpoint.smAndDown ? 'start' : 'center'"
      :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : 'flex-row'"
    >
      <v-col md="8">
        <v-card-title v-if="trip.name">
          <span class="text-truncate"> {{ trip.name }} </span>
        </v-card-title>
        <v-card-title v-else>
          <span class="text-truncate">
            Trip to
            {{
              trip.locations[trip.locations.length - 1].name ??
              trip.locations[trip.locations.length - 1].address
            }}
          </span>
        </v-card-title>
      </v-col>
      <v-col md="4" class="pr-3">
        <TextBtn v-if="$vuetify.breakpoint.smAndDown" @click="showTrip">
          Open trip plan on map
        </TextBtn>
        <TextBlockBtn v-else @click="showTrip">
          Open trip plan on map
        </TextBlockBtn>
      </v-col>
    </v-row>
    <v-card-subtitle v-if="!trip.name">
      <span class="text-truncate">
        from
        {{ trip.locations[0].name ?? trip.locations[0].address }}
      </span>
    </v-card-subtitle>

    <v-card-text>
      <!-- vehicle section -->
      <p
        v-if="trip.vehicle_data && trip.vehicle_data.licensePlate"
        class="pb-0"
      >
        Using vehicle {{ trip.vehicle_data?.licensePlate }}
      </p>
      <p v-if="trip.vehicle_data && trip.vehicle_data.evModel" class="pb-0">
        {{ trip.vehicle_data?.evModel?.name }}
      </p>
      <v-card class="py-4 pl-md-12 rounded-lg background mb-10">
        <v-row class="flex-column flex-md-row">
          <v-col md="3">
            <!-- image section -->
            <v-skeleton-loader
              type="image"
              width="100%"
              height="140px"
              v-if="!trip.vehicle_data"
            />
            <v-img
              v-else
              contain
              :src="imageSrc(trip.vehicle_data)"
              aspect-ratio="1"
              :width="$vuetify.breakpoint.mdAndUp ? '100%' : '40%'"
              :height="$vuetify.breakpoint.mdAndUp ? '100%' : 'auto'"
              style="margin: auto"
              class="flex-grow-0"
            ></v-img>
          </v-col>
          <v-col md="9">
            <!-- details section -->
            <div v-if="trip.vehicle_data">
              <v-card-title>
                {{ trip.vehicle_data.name ?? "unnamed vehicle" }}
              </v-card-title>
              <v-card-text class="pr-12">
                <p class="mb-0">
                  {{
                    trip.vehicle_data.licensePlate ?? "unrecorded license plate"
                  }}
                </p>
                <p v-if="trip.vehicle_data.evModel">
                  {{ trip.vehicle_data.evModel.name }}
                </p>
                <BatteryDisplay
                  :battery="trip.vehicle_data.stateOfCharge ?? 0"
                />
              </v-card-text>
            </div>
            <div v-else>
              <v-row no-gutters class="mb-4">
                <v-skeleton-loader type="heading" width="70%" />
                <v-skeleton-loader type="heading" width="30%" />
              </v-row>
              <v-skeleton-loader type="text" width="40%" />
              <v-skeleton-loader type="text" width="70%" class="mb-4" />
              <v-skeleton-loader type="heading" width="100%" />
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- stats section -->
      <v-card class="mb-5 rounded-lg">
        <v-card-text>
          <v-row no-gutters class="mb-2">
            <v-col md="4"> Total travel time </v-col>
            <v-col md="8">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ niceDuration(trip.itinerary.totalTime) }}
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col md="4"> Total travel time </v-col>
            <v-col md="4" class="pr-3">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ niceDuration(trip.itinerary.totalTravelTime) }}
              </div>
            </v-col>
            <v-col md="4">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ Math.round(trip.itinerary.totalDrivingDistance / 1000) }} km
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters class="mb-2">
            <v-col md="4"> Charging time </v-col>
            <v-col md="4" class="pr-3">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ niceDuration(trip.itinerary.totalChargingTime) }}
              </div>
            </v-col>
            <v-col md="4">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{ Math.round(trip.itinerary.totalEnergyAdded) }} kWh
              </div>
            </v-col>
          </v-row>
          <v-row no-gutters v-if="trip.frequency">
            <v-col md="4"> Frequency </v-col>
            <v-col md="8">
              <div style="border-bottom: 1px solid lightgray; width: 100%">
                {{
                  trip.frequency.numberOfTimes + "x " + trip.frequency.timeFrame
                }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- emissions section -->
      <v-card class="mb-5 rounded-lg pa-4">
        <v-row no-gutters>
          <v-col md="4"> Emissions avoided </v-col>
          <v-col md="8">
            <div style="border-bottom: 1px solid lightgray; width: 100%">
              {{ Math.round(stats?.avoidedCO2 ?? 0) }} kg CO₂
            </div>
          </v-col>
        </v-row>
      </v-card>
      <!-- cost section -->
      <v-card class="mb-5 rounded-lg pa-4">
        <v-card-title>
          <v-icon color="primary" class="mr-3"> mdi-piggy-bank-outline </v-icon>
          ${{
            (
              (stats?.fuelCost ?? 0) -
              (stats?.privateChargingCost ?? 0) -
              (stats?.publicChargingCost ?? 0)
            ).toFixed(2)
          }}
          fuel savings for this trip
        </v-card-title>
        <v-card class="background" flat>
          <v-icon color="primary" class="mr-1 ml-2 py-2">
            mdi-water-outline
          </v-icon>
          <span>
            ${{ (stats?.fuelCost ?? 0).toFixed(2) }}
            equivalent fuel cost
          </span>
        </v-card>
        <v-row no-gutters class="my-4">
          <v-col md="6">
            <v-card class="background mr-md-1" flat>
              <v-icon color="primary" class="mr-1 ml-2 py-2">
                mdi-currency-usd
              </v-icon>
              <span>
                ${{ (stats?.publicChargingCost ?? 0).toFixed(2) }}
                spent on public charging
              </span>
            </v-card>
          </v-col>
          <v-col md="6">
            <v-card class="background ml-md-1" flat>
              <v-icon color="primary" class="mr-1 ml-2 py-2">
                mdi-currency-usd
              </v-icon>
              <span>
                ${{ (stats?.privateChargingCost ?? 0).toFixed(2) }}
                spent on private charging
              </span>
            </v-card>
          </v-col>
        </v-row>
        <v-card-actions>
          <OutlinedBtn @click="showStats">
            See trip's full performance summary
          </OutlinedBtn>
        </v-card-actions>
      </v-card>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import Vue, { PropType } from "vue";
import TextBlockBtn from "../ui-elements/buttons/TextBlockBtn.vue";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import { ActionTypes, MutationTypes, State } from "@/logic/store/store_types";
import BatteryDisplay from "../ui-elements/BatteryDisplay.vue";
import OutlinedBlockBtn from "../ui-elements/buttons/OutlinedBlockBtn.vue";
import TextBtn from "../ui-elements/buttons/TextBtn.vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import OutlinedBtn from "../ui-elements/buttons/OutlinedBtn.vue";
import { RouteNames } from "@/logic/router";

export default Vue.extend({
  name: "TripDetailsPage",
  props: {
    trip: {
      type: Object as PropType<Trip>,
      required: true,
    },
  },
  computed: {
    stats() {
      return this.trip.getTripStats({
        calcVs: (this.$store.state as State).calcVs,
        dieselCostPerLitre: (this.$store.state as State).dieselCostPerLitre,
        dieselKmPerLitre: (this.$store.state as State).dieselKmPerLitre,
        kWhCostHome: (this.$store.state as State).defaultHomeCostPerKWh,
        petrolCostPerLitre: (this.$store.state as State).petrolCostPerLitre,
        petrolKmPerLitre: (this.$store.state as State).petrolKmPerLitre,
      });
    },
  },
  components: {
    TextBtn,
    TextBlockBtn,
    BatteryDisplay,
    OutlinedBlockBtn,
    OutlinedBtn,
  },
  methods: {
    /**
     * Returns the source URL of the image associated with the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle object.
     * @return {string} The source URL of the image.
     */
    imageSrc(vehicle: Vehicle): string {
      // get uploaded image to car in first instance.
      const images = vehicle.imageSrcPaths;
      if (images.length) return images[0];
      // get model image in second instance.
      if (vehicle.evModel)
        return (
          (vehicle.evModel as EVModel)?.imageSrc ??
          getAssetSrc("car_images/No_Image_Powersell.png")
        );
      // get company logo image in third instance.
      const company = (this.$store.state as State).fleet;
      if (company && company.logoSrc) {
        return company.logoSrc;
      }
      // get default image in fourth instance.
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
    showTrip() {
      this.$store.dispatch(ActionTypes.showTrip, this.trip);
      this.$emit("view-trip");
    },
    showStats() {
      this.$store.commit(MutationTypes.setSelectedTrip, this.trip);
      this.$router.push({ name: RouteNames.tripStats });
      this.$emit("view-stats");
    },
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
  },
});
</script>
