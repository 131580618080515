<template>
  <v-dialog
    v-model="open"
    max-width="350px"
    content-class="rounded-lg"
    @click:outside="resetAndClose"
    @keydown="keyDown($event)"
    style="z-index: 1102"
  >
    <template v-slot:activator="{ on }">
      <v-row align="end" no-gutters class="flex-nowrap mb-3">
        <span class="pr-2"> {{ label }} </span>
        <v-text-field
          :value="time"
          type="time"
          readonly
          class="flex-grow-1 flex-shrink-1"
          required
          @click="openTimePicker"
          :dark="dark"
          v-on="on"
          @click:clear="clear"
          clearable
          dense
          hide-details
        />
      </v-row>
    </template>

    <v-card class="pb-2">
      <v-time-picker
        v-model="time"
        ampm-in-title
        format="ampm"
        full-width
        color="primary"
      />
      <v-card-actions>
        <ElevatedBtn class="flex-grow-1" @click="updateTime">
          Update time
        </ElevatedBtn>
        <OutlinedBtn @click="resetAndClose"> Cancel </OutlinedBtn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import ElevatedBtn from "../buttons/ElevatedBtn.vue";
import OutlinedBtn from "../buttons/OutlinedBtn.vue";

export interface TimePickerInputUpdateObj {
  identifier: string;
  time: string;
}

/** Vue component: renders a reusable input filed and time picker modal that
 * emits an object with a passed identifier and a selected time.
 *
 * @prop `identifier` - string - passed identifier included in the emitted return.
 * @prop `initialValue` - string in 24hr time e.g. "09:00".
 * @prop `label` - string - the label to be displayed on the input element.
 * @prop `dark` - (optional) boolean - flags for input field to be rendered in dark mode, false by default.
 * @emits update - with a value in the shape of:
 * `{
 *    identifier: string \\ tha passed id value
 *    time: string \\ the selected value
 * }`
 */
export default Vue.extend({
  name: "TimePickerInput",
  components: { ElevatedBtn, OutlinedBtn },
  props: {
    initialValue: String as PropType<string | undefined>,
    identifier: String,
    label: String,
    dark: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },
  data() {
    return {
      time: "",
      open: false,
    };
  },
  methods: {
    setInitialValue() {
      if (this.initialValue) {
        this.time = this.initialValue;
      } else {
        this.time = "";
      }
    },
    openTimePicker() {
      this.open = true;
    },
    closeTimePicker() {
      this.open = false;
    },
    resetAndClose() {
      this.setInitialValue();
      this.closeTimePicker();
    },
    updateTime() {
      this.$emit("update", {
        identifier: this.identifier,
        time: this.time,
      } as TimePickerInputUpdateObj);
      this.closeTimePicker();
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.resetAndClose();
    },
    clear() {
      this.time = "";
      this.updateTime();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValue();
    });
  },
});
</script>
<style scoped>
.pwt-text-input-text input {
  cursor: text;
}
</style>
