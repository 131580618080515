<template>
  <v-card flat class="pa-5">
    <slot></slot>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "InfoPanelCardWrapper",
});
</script>
