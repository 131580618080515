<template>
  <div class="d-flex align-center">
    <span class="mr-2"> {{ battery }}% </span>
    <div
      class="rounded-pill grey lighten-4"
      style="
        width: 100%;
        height: 18px;
        box-shadow: inset -2px 3px 2px rgba(0, 0, 0, 0.2);
        overflow: hidden;
      "
    >
      <div
        class="primary rounded-pill"
        :style="{ width: `${Math.max(battery, 1)}%`, height: '100%' }"
      ></div>
    </div>
  </div>
</template>
<script lang="ts">
import Vue from "vue";

export default Vue.extend({
  name: "BatteryDisplay",
  props: {
    battery: {
      type: Number,
      default: 0,
    },
  },
});
</script>
