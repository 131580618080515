<template>
  <v-card flat class="pb-3">
    <v-card-text class="py-0 grey--text">
      Give this trip a frequency, so it can be included in your 5-year savings
      estimate.
    </v-card-text>
    <v-card-text class="py-0">
      <v-switch
        v-model="isFrequentTrip"
        inset
        label="This is a frequent trip"
        @change="updateTripFrequency"
        :color="darkBlue"
      />
    </v-card-text>
    <v-card-text class="grey--text" v-if="isFrequentTrip">
      How often do you do this trip?
      <v-row class="px-3 py-4 flex-column flex-sm-row align-sm-center">
        <v-text-field
          v-model="numberOfTimes"
          type="number"
          outlined
          dense
          style="max-width: 70px; border-radius: 12px"
          hide-details
          @change="validateNumber"
          hide-spin-buttons
        />
        <span class="py-1 py-sm-0 px-sm-3">
          {{ numberOfTimes == 1 ? "time" : "times" }} per</span
        >
        <v-select
          v-model="frequency"
          :items="frequencyItems"
          outlined
          dense
          hide-details
          style="max-width: 140px; border-radius: 12px"
          @change="updateTripFrequency"
        >
        </v-select>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { TripFrequency } from "@/logic/types/trip_specific_types";
import Vue, { PropType } from "vue";
import { powerTripDarkBlue } from "@/logic/data/const";

export default Vue.extend({
  name: "FrequencySelectInput",
  props: {
    initialValue: Object as PropType<TripFrequency | undefined>,
  },
  data() {
    return {
      darkBlue: powerTripDarkBlue,
      isFrequentTrip: false,
      numberOfTimes: 1,
      frequency: "day" as
        | "day"
        | "weekday"
        | "week"
        | "fortnight"
        | "month"
        | "quarter"
        | "half-year"
        | "year",
      frequencyItems: [
        "day",
        "week",
        "weekday",
        "fortnight",
        "month",
        "quarter",
        "half-year",
        "year",
      ],
    };
  },
  methods: {
    setInitialValue(initialValue: TripFrequency | undefined) {
      if (initialValue) {
        this.numberOfTimes = initialValue.numberOfTimes;
        this.frequency = initialValue.timeFrame;
        this.isFrequentTrip = true;
      }
    },
    updateTripFrequency() {
      let frequencyObj: TripFrequency | undefined = undefined;
      if (this.isFrequentTrip) {
        frequencyObj = {
          numberOfTimes: this.numberOfTimes,
          timeFrame: this.frequency,
        };
      }
      this.$emit("update", frequencyObj);
    },
    validateNumber(val: number) {
      if (isNaN(val)) {
        this.numberOfTimes = 1;
      } else if (val > 0) {
        this.numberOfTimes = val;
      } else {
        this.numberOfTimes = 1;
      }
      this.updateTripFrequency();
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValue(this.initialValue);
    });
  },
});
</script>
