<template>
  <v-autocomplete
    no-filter
    v-model="address"
    :clearable="!readonly"
    :search-input.sync="query"
    :items="items"
    item-text="address"
    item-value="waypoint"
    return-object
    @change="flagForUpdate"
    :label="label"
    :dark="dark"
    :append-icon="
      appendIcon
        ? appendIcon
        : address
          ? address.address == ''
            ? 'mdi-pencil'
            : ''
          : 'mdi-pencil'
    "
    attach
    :placeholder="placeholder"
    persistent-placeholder
    :error-messages="errorMsg"
    :loading="loading || fetching"
    :hide-no-data="query === null || query === '' || query.length === 1"
    :no-data-text="
      fetching ? 'Loading' : 'Nothing matches your search criteria'
    "
    :readonly="readonly"
    @click:clear="$emit('clear')"
    @click="$emit('click')"
    @click:append="$emit('append')"
  />
</template>
<script lang="ts">
import { fetchLocationAutoCompleteDetails } from "@/logic/api/calls/maps_little_monkey_calls";
import Coordinate from "@/logic/classes/common_classes/coordinate";
import { State } from "@/logic/store/store_types";
import processAddressSearchResults, {
  AutoCompleteReturnObject,
  processedAddressObj,
} from "@/logic/utils/processAddressSearchResults";
import Vue, { PropType } from "vue";
import { mapState } from "vuex";

export interface AddressAutocompleteInputUpdateObj {
  id: string;
  addressData: processedAddressObj | undefined;
}

/** Vue component: renders a reusable address auto complete input filed that
 * emits an object with a passed ID a selected address and its coordinate
 * values.
 *
 * @prop `id` - string - passed identifier included in the emitted return.
 * @prop `initialValue` - processedAddressObj
 * @prop `label` - string - the label to be displayed on the input element.
 * @prop `dark` - (optional) boolean - flags for input field to be rendered in dark mode, false by default.
 * @emits update - with a value in the shape of:
 * `{
 *    id: string \\ tha passed id value
 *    address: processedAddressObj \\ the selected value
 * }`
 */
export default Vue.extend({
  name: "AddressAutocompleteInput",
  props: {
    id: String,
    initialValue: Object as PropType<processedAddressObj | undefined>,
    label: String,
    dark: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
    errorMsg: {
      type: String as PropType<string | null>,
      default: null,
    },
    loading: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
    geoLocation: {
      type: Object as PropType<Coordinate | undefined>,
    },
    allowFavLocations: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: "Enter an address",
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    appendIcon: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  computed: {
    ...mapState({
      userGeoLocation: (state: unknown) => (state as State).userGeoIPData,
      pannedCenter: (state: unknown) => (state as State).pannedCenter,
      favLocations: (state: unknown) => (state as State).favLocations,
    }),
  },
  data() {
    return {
      address: undefined as processedAddressObj | undefined,
      items: [] as Array<processedAddressObj>,
      query: null as null | string,
      results: null as Array<AutoCompleteReturnObject> | null,
      initialLoad: true,
      fetching: false,
    };
  },
  watch: {
    query(val) {
      if (this.initialLoad && this.initialValue) {
        this.initialLoad = false;
        return;
      }
      if (this.initialLoad) this.initialLoad = false; // trigger rerender only if needed.
      if (val) {
        this.fetching = true;
        // find address auto complete options
        fetchLocationAutoCompleteDetails(
          val,
          this.getBiasLocation(),
          this.geoLocation || this.pannedCenter ? 0.5 : undefined // prioritize higher bias if location gathered from user interaction with the UI (form/map).
        ).then((data) => {
          this.results = data;
          this.fetching = false;
        });
      }
    },
    results() {
      if (this.results && this.query) {
        // process API search results into usable items
        const processedRes = processAddressSearchResults(this.results);
        // process fav locations into usable items
        const processedFav = this.favLocations.map(
          (fav) => fav.toProcessedAddressObj
        );
        // filter out fav locations based on search criteria
        const filteredFav = this.query
          ? processedFav.filter((fav) =>
              fav.address
                .toLowerCase()
                .replace(",", "")
                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                .includes(this.query!.toLowerCase().replace(",", ""))
            )
          : [];
        // set items
        this.items = this.allowFavLocations
          ? [...filteredFav, ...processedRes]
          : processedRes;
      }
    },
    initialValue() {
      if (!this.initialLoad) {
        this.address = this.initialValue;
      }
    },
  },
  methods: {
    flagForUpdate() {
      this.$emit("update", {
        id: this.id,
        addressData: this.address,
      } as AddressAutocompleteInputUpdateObj);
    },
    setInitialValues() {
      if (this.initialValue) {
        this.address = this.initialValue;
        if (this.address.address != "") {
          this.items = [this.initialValue];
        }
      }
    },
    getBiasLocation(): Coordinate | undefined {
      // prioritize passed location over user panned map display central location over programmatically sourced user location.
      if (this.geoLocation) return this.geoLocation; // use passed location.
      if (this.pannedCenter)
        return new Coordinate({
          latitude: this.pannedCenter.lat,
          longitude: this.pannedCenter.lng,
        }); // use map display center.
      return this.userGeoLocation; // use ip sourced location.
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setInitialValues();
    });
  },
});
</script>
