<template>
  <v-card flat>
    <DashboardBreadcrumbs
      v-if="$vuetify.breakpoint.mdAndUp"
      :items="
        showingTrip
          ? [
              { text: 'Home' },
              { text: 'Saved trips' },
              { text: 'Trip details', disabled: true },
            ]
          : [{ text: 'Home' }, { text: 'Saved trips', disabled: true }]
      "
      @navigation="breadcrumbNavigation"
      @back="breadcrumbNavigation({ text: 'Home' })"
    />
    <TripDetailsPage
      v-if="showingTrip"
      :trip="showingTrip"
      @back="showingTrip = undefined"
      @view-trip="close"
      @view-stats="close"
    />
    <v-card v-else flat class="p7-5 pa-md-5 background">
      <v-card-title> Unsaved trips </v-card-title>
      <v-card-text>
        <UnsavedTripCarousel />
      </v-card-text>
      <v-card-title> Saved trips </v-card-title>
      <FrequentTripSavings />
      <v-card-text>
        <v-row no-gutters>
          <v-col
            v-for="(trip, i) in savedTrips"
            :key="'saved-trip-' + i"
            cols="12"
            md="6"
          >
            <div
              :class="
                $vuetify.breakpoint.mdAndUp
                  ? i % 2 === 0
                    ? 'pr-1'
                    : 'pl-1'
                  : ''
              "
            >
              <SavedTripCard
                :trip="trip"
                :style="$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''"
                emitOnly
                @view-trip="showTripDetails(trip)"
              />
            </div>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import UnsavedTripCarousel from "../trips/history/UnsavedTripCarousel.vue";
import FrequentTripSavings from "../trips/statistics/info-panel-content/FrequentTripSavings.vue";
import Trip from "@/logic/classes/trip_classes/trip";
import { GettersTypes, MutationTypes } from "@/logic/store/store_types";
import SavedTripCard from "../trips/history/SavedTripCard.vue";
import DashboardBreadcrumbs, {
  type BreadcrumbsItem,
} from "./DashboardBreadcrumbs.vue";
import TripDetailsPage from "./TripDetailsPage.vue";

export default Vue.extend({
  name: "SavedTripsContent",
  components: {
    UnsavedTripCarousel,
    FrequentTripSavings,
    SavedTripCard,
    DashboardBreadcrumbs,
    TripDetailsPage,
  },
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
  },
  data() {
    return {
      showingTrip: undefined as Trip | undefined,
    };
  },
  methods: {
    showTripDetails(trip: Trip) {
      this.showingTrip = trip;
    },
    breadcrumbNavigation(item: BreadcrumbsItem) {
      if (item.disabled) return;
      if (item.text === "Home") {
        this.$store.commit(MutationTypes.setDashboardDialogContent, "home");
      } else if (item.text === "Saved trips") {
        this.showingTrip = undefined;
      }
    },
    close() {
      this.showingTrip = undefined;
      this.$emit("close");
    },
  },
});
</script>
