<template>
  <v-btn
    :color="powerTripDarkBlue"
    text
    :disabled="disabled"
    :loading="loading"
    class="text-none rounded-pill px-5 text-decoration-underline"
    @click="emitClick"
    style="text-underline-offset: 4px !important"
  >
    <slot></slot>
  </v-btn>
</template>
<script lang="ts">
import Vue from "vue";
import { powerTripDarkBlue } from "@/logic/data/const";

export default Vue.extend({
  name: "TextBtn",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
});
</script>
