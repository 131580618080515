import { fetchNearbyChargers } from "../api/calls/ev_nav_calls";
import {
  EVNavNearbyChargersRes,
  EVNavNearbyParams,
} from "../types/ev_nav_types";

export default class NearbyController {
  /** EV Nav near by call parameters. */
  parameters: EVNavNearbyParams;

  /** EV Nav near by call response data. */
  nearbyData?: EVNavNearbyChargersRes[];

  constructor(parameters: EVNavNearbyParams) {
    this.parameters = parameters;
  }

  /**
   * Fetch data from EV Nav for the nearby call.
   */
  public async fetchData(): Promise<"FAILED" | "SUCCESS"> {
    // Fetch data from EV Nav.
    const data = await fetchNearbyChargers(this.parameters);
    if (data) {
      this.nearbyData = data;
      return "SUCCESS";
    }
    return "FAILED";
  }
}
