import Vue from "vue";
// import Vuetify from "vuetify/lib/framework";
import UnknownNetworkIcon from "../../ui/components/custom-icons/UnknownNetworkIcon.vue";
import ItineraryIcon from "../../ui/components/custom-icons/ItineraryIcon.vue";
import StationsIcon from "../../ui/components/custom-icons/StationsIcon.vue";
import TripsIcon from "../../ui/components/custom-icons/TripsIcon.vue";
import TripStatsIcon from "../../ui/components/custom-icons/TripStatsIcon.vue";
import VehiclesIcon from "../../ui/components/custom-icons/VehiclesIcon.vue";
import Vuetify from "vuetify/lib";
import {
  powerTripBlue,
  powerTripDarkGrey,
  powerTripLightGrey,
  powerTripRed,
  powerTripWhite,
} from "../data/const";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    values: {
      // custom icons
      unKnownNetworkIcon: {
        component: UnknownNetworkIcon,
      },
      itineraryIcon: {
        component: ItineraryIcon,
      },
      stationsIcon: {
        component: StationsIcon,
      },
      tripsIcon: {
        component: TripsIcon,
      },
      tripStatsIcon: {
        component: TripStatsIcon,
      },
      vehiclesIcon: {
        component: VehiclesIcon,
      },
    },
  },
  theme: {
    options: {
      customProperties: true,
    },
    themes: {
      dark: {
        primary: powerTripBlue,
        error: powerTripRed,
      },
      light: {
        primary: powerTripBlue,
        error: powerTripRed,
        secondary: powerTripLightGrey,
        background: powerTripWhite,
        tertiary: powerTripDarkGrey,
      },
    },
  },
});
