<template>
  <v-card flat>
    <v-card-text>
      Batteries age over time reducing their overall capacity and the effective
      range of your EV. This means 100% charge (SoC) of a brand new battery is
      not the same as a 100% charge (SoC) for a battery a few years old. Battery
      health (SoH) is a % of how effective your battery is compared to when you
      first brought it.
      <v-img :src="getSrc('soh.png')" class="mt-4 mb-6" />
      Our slider sets the state of health percentage for your EV and shows an
      estimated range for a full charge on average conditions. This range can
      change depending on a lot of other factors such hilly terrain, windy
      roads, strong wind resistance etc.
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import { MutationTypes } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue from "vue";

export default Vue.extend({
  name: "BatteryHealthHelpContent",
  methods: {
    handleClose() {
      this.$store.commit(MutationTypes.setSecondaryDialogContent, undefined);
    },
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
});
</script>
