// import levenshtein from "js-levenshtein";
import { Coordinates } from "../types/trip_specific_types";

export interface AutoCompleteReturnObject {
  geometry: {
    coordinates: [
      number, //Longitude
      number, //Latitude
    ];
    type: "Point";
  };
  type: "Feature";
  properties: {
    osm_id: number;
    osm_type: string; // TODO: make union when we have all data variants. Currently: "R", "N" , "W"
    extent?: [
      number, //Longitude
      number, //Latitude
      number, //Longitude
      number, //Latitude
    ];
    osm_key: string; // TODO: make union when we have all data variants. Currently: "boundary", "place"
    osm_value: string; // TODO: make union when we have all data variants. Currently: "administrative", "house"
    name?: string;
    type: string; // TODO: make union when we have all data variants. Currently: "region", "house", "city", "locality",
    country: string;
    housenumber?: string;
    city?: string;
    district?: string;
    street?: string;
    postcode?: string;
    state?: string;
  };
}

export interface processedAddressObj {
  address: string;
  coordinates: Coordinates;
  name?: string;
  localId?: string;
}

export default function processAddressSearchResults(
  searchResults: AutoCompleteReturnObject[]
  // searchQuery: string
): processedAddressObj[] {
  const filteredArray = filterOutUnits(searchResults);

  // create empty array to be populated later.
  const tempArray: processedAddressObj[] = [];

  // iterate through `searchResults`.
  filteredArray.forEach((result) => {
    // create temp variable to be pushed to temp array.
    let tempString = "";

    // check if optional property `name` is present and add to temp variable if so.
    if (result.properties.name) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.name;
      } else {
        tempString = tempString + result.properties.name;
      }
    }

    // check if optional property `housenumber` is present and add to temp variable if so.
    if (result.properties.housenumber) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.housenumber;
      } else {
        tempString = tempString + result.properties.housenumber;
      }
    }

    // check if optional property `street` is present and add to temp variable if so.
    if (result.properties.street) {
      // check if extra space is needed
      if (tempString.length) {
        // check if `housenumber` is the item prior to this.
        if (result.properties.housenumber) {
          tempString = tempString + " " + result.properties.street;
        } else {
          tempString = tempString + " " + result.properties.street;
        }
      } else {
        tempString = tempString + result.properties.street;
      }
    }

    // check if optional property `district` is present and add to temp variable if so.
    if (result.properties.district) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.district;
      } else {
        tempString = tempString + result.properties.district;
      }
    }

    // check if optional property `city` is present and add to temp variable if so.
    if (result.properties.city) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.city;
      } else {
        tempString = tempString + result.properties.city;
      }
    }

    // check if optional property `state` is present and add to temp variable if so.
    if (result.properties.state) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.state;
      } else {
        tempString = tempString + result.properties.state;
      }
    }

    // check if optional property `postcode` is present and add to temp variable if so.
    if (result.properties.postcode) {
      // check if extra space is needed
      if (tempString.length) {
        tempString = tempString + " " + result.properties.postcode;
      } else {
        tempString = tempString + result.properties.postcode;
      }
    }

    // check if extra space is needed
    if (tempString.length) {
      // add `country`to temp variable if so.
      tempString = tempString + " " + result.properties.country;
    } else {
      // add `country`to temp variable if so.
      tempString = tempString + result.properties.country;
    }

    // push populate `tempString` to `tempArray`.

    const addressObj: processedAddressObj = {
      address: tempString,
      name: result.properties.name ?? result.properties.street,
      coordinates: {
        Latitude: result.geometry.coordinates[1],
        Longitude: result.geometry.coordinates[0],
      },
    };

    tempArray.push(addressObj);
  });

  // return populated `tempArray`.
  return tempArray;
}

function filterOutUnits(
  searchResults: AutoCompleteReturnObject[]
): AutoCompleteReturnObject[] {
  const captureArray: AutoCompleteReturnObject[] = [];
  const reg = new RegExp("^[0-9]+$");
  // iterate through results to see if needs to be excluded or not.
  searchResults.forEach((result: AutoCompleteReturnObject, index: number) => {
    // find index of base address if there is one.
    const baseAddressIndex = searchResults.findIndex(
      (baseAddress: AutoCompleteReturnObject) =>
        baseAddress.properties.street === result.properties.street &&
        baseAddress.properties.district === result.properties.district &&
        baseAddress.properties.city === result.properties.city &&
        (baseAddress.properties.housenumber
          ? result.properties.housenumber?.includes(
              baseAddress.properties.housenumber
            )
          : false) &&
        (baseAddress.properties.housenumber
          ? reg.test(baseAddress.properties.housenumber)
          : false)
    );
    // exit if a base address was found and dose not match this address.
    if (baseAddressIndex !== -1 && baseAddressIndex !== index) return;
    // add to capture array
    captureArray.push(result);
  });
  return captureArray;
}
