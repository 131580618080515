<template>
  <v-row no-gutters>
    <!-- mobile size avatar and menu -->
    <AvatarIconMenu />
    <v-card-subtitle class="d-flex flex-column grey--text pt-0">
      <span class="font-weight-medium text-h6">
        {{ user.fullName }}
      </span>
      <span>
        {{ user.email }}
      </span>
    </v-card-subtitle>
    <v-spacer />
  </v-row>
</template>

<script lang="ts">
import User from "@/logic/classes/user_classes/user";
import Vue from "vue";
import AvatarIconMenu from "./AvatarIconMenu.vue";
export default Vue.extend({
  name: "AvatarRow",
  props: {
    user: User,
  },
  components: { AvatarIconMenu },
});
</script>
