<template>
  <div>
    <v-text-field
      autofocus
      :prefix="prefix"
      :value="inputValue"
      dense
      style="color: rgba(0, 0, 0, 0.6)"
      append-icon="mdi-clock-edit-outline"
      @click:append="
        () => {
          openDialog = true;
        }
      "
      @input="timeRegExCheck"
      :error-messages="errors"
    />
    <!-- booking start time picker modal -->
    <v-dialog
      :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
      style="z-index: 1500"
      :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
      max-width="300px"
      min-width="200px"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      v-model="openDialog"
    >
      <v-card>
        <v-time-picker
          ampm-in-title
          :value="pickerValue"
          @change="processTimeToEpoch"
          @update:period="processAmPmChange"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import { capitalizeFirstLetter } from "@/logic/utils/stringUtils";
import {
  epochToNiceTime,
  convert24hrTimeToEpoch,
  convertEpochTo24hrTime,
} from "@/logic/utils/timeUtils";

export interface BookingTimeInputUpdateObj {
  timeName: string;
  newTime: number;
}

export default Vue.extend({
  name: "BookingTimeInput",
  props: {
    initialValue: {
      type: Number,
      required: true,
    },
    timeName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      openDialog: false,
      errors: null as string | string[] | null,
    };
  },
  computed: {
    prefix(): string {
      return capitalizeFirstLetter(this.timeName) + " Time:";
    },
    inputValue(): string {
      return epochToNiceTime(this.initialValue);
    },
    pickerValue(): string {
      return convertEpochTo24hrTime(this.initialValue);
    },
  },
  methods: {
    emitUpdateTime(newTime: number): void {
      const emitObj: BookingTimeInputUpdateObj = {
        newTime,
        timeName: this.timeName,
      };
      this.$emit("update", emitObj);
    },
    emitError(): void {
      this.$emit("error");
    },
    emitClearError(): void {
      this.$emit("clearError");
    },
    timeRegExCheck(str: string): void {
      const regex24hours =
        /\s*(?<hour>[0-2][0-9])\s*:?\s*(?<minute>[0-5][0-9])\s*/;
      const regex12hours =
        /\s*(?<hour>1[0-9]|0?[1-9])\s*:\s*(?<minute>[0-5][0-9])\s*(?<period>[AaPp][Mm])\s*/;
      const errorMsg = "Please try 24 hour time format or specify am/pm";

      // Check if fits 12hr format string
      if (regex12hours.test(str)) {
        // clear any pervious error message
        this.errors = null;
        this.emitClearError();

        // gather named capture groups
        const groups = regex12hours.exec(str)?.groups;
        if (!groups) {
          // notify user of error. Note this should have been picked up by the regex test.
          this.errors = errorMsg;
          this.emitError();
          return;
        }

        // change time into HH:MM format string
        const convertedTime = `${
          groups.period.toLowerCase() === "pm"
            ? (parseInt(groups.hour) + 12).toString()
            : groups.hour
        }:${groups.minute}`;
        // convert time to epoch and save to state
        this.emitUpdateTime(
          convert24hrTimeToEpoch(convertedTime, this.initialValue)
        );
        return;
      }

      // Check if fits 24hr format string
      if (regex24hours.test(str)) {
        // clear any pervious error message
        this.errors = null;
        this.emitClearError();

        // gather named capture groups
        const groups = regex24hours.exec(str)?.groups;
        // exit if groups where not successfully captured
        if (!groups) {
          // notify user of error. Note this should have been picked up by the regex test.
          this.errors = errorMsg;
          this.emitError();
          return;
        }
        // convert time to epoch and save to state
        this.emitUpdateTime(
          convert24hrTimeToEpoch(
            groups.hour + ":" + groups.minute,
            this.initialValue
          )
        );
        return;
      }

      // error if no matching was successful
      this.errors = errorMsg;
      this.emitError();
    },
    /**
     * Converts `v-calendar` required epochTime to `v-time-picker` required HH:MM  time string.
     *
     * @param epochTime v-calendar event provided epochTime(milliseconds since epoch).
     * @returns time string in "HH:MM" format
     */
    processEpochToTime(epochTime: number) {
      return convertEpochTo24hrTime(epochTime);
    },
    /**
     * Converts `v-time-picker` required HH:MM  time string to `v-calendar` required epochTime.
     *
     * @param val v-time-picker event provided time string in "HH:MM" format.
     * @returns epochTime (milliseconds since epoch).
     */
    processTimeToEpoch(val: string | null) {
      if (val) {
        // convert time to epoch and save to state
        this.emitUpdateTime(convert24hrTimeToEpoch(val, this.initialValue));
      }
    },
    /**
     * Handles `v-time-picker` period change event altering time by 12hrs either way.
     *
     * @param val `v-time-picker` event provided period string.
     */
    processAmPmChange(val: "am" | "pm") {
      if (val) {
        // ASSUMES: behavior for vuetify time pickers is to only fire this event if it is a change so it can be assumed that if val is "am" then old val was "pm"
        if (val === "am") {
          // subtract 12hrs
          this.emitUpdateTime(this.initialValue - 43200000);
        }
        if (val === "pm") {
          // ad 12hrs
          this.emitUpdateTime(this.initialValue + 43200000);
        }
      }
    },
  },
});
</script>
