import Vue from "vue";
import {
  MapsGeoIPRes,
  MapsReverseGeocodeRes,
} from "@/logic/types/maps_little_monkey_types";
import {
  addressAutocompleteEndpoint,
  geoIpEndpoint,
  mapsLittleMonkeyBaseUrl,
} from "../clients/mapsLittleMonkeyClient";
import Coordinate from "@/logic/classes/common_classes/coordinate";

const apiKey = process.env.VUE_APP_LITTLE_MONKEY_API_KEY;

/** Notify analytics of a passed error. */
function notifyAnalytics(error: Error, functionName: string) {
  if (process.env.NODE_ENV === "development")
    console.log(
      "#ERROR DEV NOTIFICATION: `" + functionName + "` errored with",
      error
    );
  Vue.prototype.$Countly.q.push([
    "recordError",
    { stack: error.message },
    true,
    {
      type: "api call",
      filePath: "src\\logic\\api\\calls\\maps_little_monkey_calls.ts",
      functionName,
    },
  ]);
}

/**
 * Locates the coordinates for the users IP address.
 *
 * NOTE: this is not precise as it will result in the ISPs IP provisioning server
 * in most instances however is useful for assigning basic country defaults etc.
 *
 * @returns a `MapsGeoIPRes` object if successful, undefined if unsuccessful.
 */
export async function fetchGeoIPDetails(): Promise<MapsGeoIPRes | undefined> {
  try {
    const response = await fetch(
      `${mapsLittleMonkeyBaseUrl}${geoIpEndpoint}/?api_key=${apiKey}`
    );
    const convertedResponse: MapsGeoIPRes = await response.json();
    return convertedResponse;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchGeoIPDetails");
    return;
  }
}

/**
 * Reverse geocode a given coordinate and provide the closest address to that location.
 *
 * @param latitude the latitude of the coordinate to reverse geocode.
 * @param longitude the longitude of the coordinate to reverse geocode.
 * @returns a `MapsReverseGeocodeRes` object if successful undefined if unsuccessful.
 */
export async function reverseGeocode(
  latitude: number,
  longitude: number
): Promise<MapsReverseGeocodeRes | undefined> {
  try {
    const response = await fetch(
      `https://maps.littlemonkey.co.nz/reverse?api_key=${apiKey}&lat=${latitude}&lon=${longitude}&format=json`
    );
    const convertedResponse: MapsReverseGeocodeRes = await response.json();
    return convertedResponse;
  } catch (error) {
    notifyAnalytics(error as Error, "reverseGeocodeLastKnown");
    return;
  }
}

/**
 * Fetches a list of location objects for use in auto complete inputs and in calling routing calculations
 * based on objects latitude and longitude values.
 *
 * This list is prioritized by the passed latitude and longitude.
 *
 * @param searchValue string value of user typed search input.
 * @param geoLocation the users current latitude and longitude.
 * @returns an array of objects containing location details.
 */
export async function fetchLocationAutoCompleteDetails(
  searchValue: string,
  geoLocation?: Coordinate | null,
  bias = 0.1
) {
  const urlEncodedVal = encodeURI(searchValue);
  const geoLocationStr =
    geoLocation && geoLocation.isValid
      ? `&lat=${geoLocation.latitude}&lon=${geoLocation.longitude}`
      : "";

  try {
    const response = await fetch(
      `${mapsLittleMonkeyBaseUrl}${addressAutocompleteEndpoint}/?q=${urlEncodedVal}` +
        // auth
        `&api_key=${apiKey}` +
        // location bias
        geoLocationStr +
        `&location_bias_scale=${bias}` +
        "&zoom=7" +
        // exclusion tags
        "&osm_tag=!boundary" +
        "&osm_tag=!natural" +
        "&osm_tag=!waterway" +
        "&osm_tag=!highway:path" +
        "&osm_tag=!highway:footpath" +
        "&osm_tag=!highway:footway" +
        "&osm_tag=!highway:footway;crossing" +
        "&osm_tag=!highway:foot" +
        "&osm_tag=!highway:service" +
        "&osm_tag=!highway:street_lamp" +
        "&osm_tag=!highway:crossing" +
        "&osm_tag=!tunnel" +
        "&osm_tag=!bridge" +
        // formatting
        "&layer=city" +
        "&layer=locality" +
        "&layer=street" +
        "&layer=house" +
        "&layer=district" +
        "&layer=county" +
        // limit
        "&limit=200"
    );
    const convertedResponse = await response.json();
    return convertedResponse.features;
  } catch (error) {
    notifyAnalytics(error as Error, "fetchLocationAutoCompleteDetails");
  }
}
