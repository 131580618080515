<template>
  <v-list style="min-width: 250px" class="background rounded-lg pa-3">
    <v-list-item>
      <v-row no-gutters>
        <v-avatar color="primary" class="mr-3 mt-1">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card-subtitle class="d-flex flex-column grey--text pt-0">
          <span class="font-weight-medium text-h6">
            {{ user ? user.fullName : "" }}
          </span>
          <span>
            {{ user ? user.email : "" }}
          </span>
        </v-card-subtitle>
        <v-spacer />
      </v-row>
    </v-list-item>
    <v-divider class="mb-3" />
    <v-list-item @click="handleChangeDashboardTab('account')">
      <v-list-item-title> Manage account </v-list-item-title>
    </v-list-item>
    <v-list-item @click="handleChangeDashboardTab('fleet-vehicles')">
      <v-list-item-title> My fleet </v-list-item-title>
    </v-list-item>
    <v-list-item @click="handleChangeDashboardTab('vehicle-details')">
      <v-list-item-title> Vehicle settings </v-list-item-title>
    </v-list-item>
    <v-list-item @click="handleChangeDashboardTab('defaults')">
      <v-list-item-title> Trip planning defaults </v-list-item-title>
    </v-list-item>
    <v-divider class="mb-3" />
    <v-list-item @click="handleLogout">
      <v-list-item-title
        class="text-decoration-underline"
        :style="`text-underline-offset: 4px; color: ${powerTripDarkBlue}`"
      >
        Sign out
      </v-list-item-title>
    </v-list-item>
  </v-list>
</template>

<script lang="ts">
import Driver from "@/logic/classes/user_classes/driver";
import { powerTripDarkBlue } from "@/logic/data/const";
import {
  ActionTypes,
  DashboardDialogContent,
  GettersTypes,
  MutationTypes,
  State,
} from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "AvatarMenuListContent",
  data() {
    return {
      powerTripDarkBlue: powerTripDarkBlue,
    };
  },
  methods: {
    /**
     * Closes the profile card and logs the user out.
     */
    handleLogout() {
      this.$emit("close");
      this.$store.dispatch(ActionTypes.logoutUser);
    },
    handleChangeDashboardTab(tab: DashboardDialogContent) {
      this.$store.commit(MutationTypes.setDashboardDialogContent, tab);
    },
  },
  computed: {
    /**
     * Returns the src path for the users profile picture if they have one.
     *
     * @return {string | undefined} The src path for the users profile picture if they have one, undefined otherwise.
     */
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    ...mapGetters({
      driver: GettersTypes.currentDriver,
    }),
    ...mapState({
      user: (state: unknown) => (state as State).user,
    }),
  },
});
</script>
