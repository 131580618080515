var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-btn',{class:_vm.$vuetify.breakpoint.smAndUp
      ? 'rounded-pill text-none px-4'
      : _vm.hasFilteredNetworks
        ? 'primary'
        : 'background',staticStyle:{"z-index":"1002","position":"absolute","top":"4px","right":"4px"},attrs:{"small":_vm.$vuetify.breakpoint.smAndUp,"color":_vm.hasFilteredNetworks
      ? _vm.$vuetify.breakpoint.smAndUp
        ? 'primary'
        : 'white'
      : undefined,"icon":_vm.$vuetify.breakpoint.xsOnly ? true : false},on:{"click":_vm.openNetworkSettings}},[_c('v-icon',[_vm._v("mdi-filter-variant")]),(_vm.$vuetify.breakpoint.smAndUp)?_c('span',[_vm._v("Networks ")]):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }