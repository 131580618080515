<template>
  <v-card class="pa-2 mt-5 mb-5 rounded-lg" v-if="chargingStopDetails">
    <v-row justify="space-between" class="pa-3">
      <!-- <p class="grey--text lighten2--text">
        {{ details.Current }} charger - {{ details.Rating }}kW
      </p> -->
      <p class="grey--text lighten2--text">
        {{ chargingStopDetails.percentageAfterCharging }}%
      </p>
    </v-row>
    <v-range-slider
      readonly
      :value="[
        chargingStopDetails.percentageBeforeCharging,
        chargingStopDetails.percentageAfterCharging,
      ]"
      min="0"
      max="100"
      color="primary"
      height="10"
      track-color="grey lighten-2"
    ></v-range-slider>
    <v-row class="mb-2">
      <v-col cols="4">
        <p class="grey--text lighten-2--text">Charge time</p>
        <v-row justify="start">
          <v-icon color="primary" class="ml-3 mr-1"> mdi-clock-outline </v-icon>
          <p class="primary--text mb-0">
            {{ chargingStopDetails.chargingTime }}
          </p>
        </v-row>
      </v-col>
      <v-col cols="4">
        <p class="grey--text lighten2--text">Charge cost</p>
        <v-row>
          <v-icon color="primary" class="ml-3 mr-1"> mdi-currency-usd </v-icon>
          <p class="primary--text mb-0">
            around ${{ chargingStopDetails.chargingCost }}
          </p>
        </v-row>
      </v-col>
      <v-col cols="4">
        <p class="grey--text lighten2--text">Energy Added</p>
        <v-row>
          <v-icon color="primary" class="ml-3 mr-1">
            mdi-lightning-bolt
          </v-icon>
          <p class="primary--text mb-0">
            {{
              `${chargingStopDetails.energyAdded}kWh (+${chargingStopDetails.percentageCharged}%)`
            }}
          </p>
        </v-row>
      </v-col>
    </v-row>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import Charger from "@/logic/classes/charger_classes/charger";
import type { ChargingDetails } from "@/logic/types/sheared_local_types";
import { GettersTypes } from "@/logic/store/store_types";

export default Vue.extend({
  name: "ChargingStopDetailsCard",
  computed: {
    chargingStopDetails(): ChargingDetails | undefined {
      const trip = this.$store.getters[GettersTypes.selectedTripData];
      const charger = this.$store.getters[GettersTypes.selectedChargerData];
      if (trip && charger) {
        return (charger as Charger).getTripChargingStopData(trip);
      }
      return undefined;
    },
  },
});
</script>
