<template>
  <v-row v-if="connectors.length">
    <!-- conditionally rendered plug images -->
    <v-col
      cols="auto"
      v-for="(connector, index) in connectors"
      :key="'vehicle-connector-img-' + index"
    >
      <v-img
        :src="connector.imageSrc"
        width="50"
        height="50"
        contain
        class="mx-auto"
      />
      <v-card-text>{{ connector.displayName }}</v-card-text>
    </v-col>
  </v-row>
  <!-- default if no connectors -->
  <v-card-text v-else class="text-subtitle-2"> None selected </v-card-text>
</template>
<script lang="ts">
// frameworks
import Vue, { PropType } from "vue";
// types/class definitions
import type { VehicleConnectorsDisplayData } from "@/logic/types/vehicle_specific_types";
// helper functions
import getAssetSrc from "@/logic/utils/getAssetSrc";

export default Vue.extend({
  name: "ConnectorDisplayRow",
  props: {
    connectors: {
      type: Array as PropType<VehicleConnectorsDisplayData[]>,
      required: true,
      default: () => [],
    },
  },
  computed: {
    fallbackImgSrc(): string {
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
  },
});
</script>
