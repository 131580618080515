<template>
  <InfoPanelCardWrapper>
    <v-card flat>
      <v-card-title class="tertiary--text pt-5">
        <BackBtn :emit="true" @click="back" />
        Trip History
      </v-card-title>
      <v-card-text>
        <v-card
          flat
          v-if="fetchingTrips"
          class="d-flex justify-center align-center"
        >
          <LoadingCard />
        </v-card>
        <template v-else>
          <StoredTripsGroupCard
            v-for="(group, groupIndex) in activeTripsGroupedByEV"
            :key="`active-trip-group-${groupIndex}`"
            :groupedTrips="group"
          />
          <v-card v-if="!activeTripsGroupedByEV.length" flat>
            <v-card-text class="pt-0">
              You currently have no trip unsaved trips.
              <a @click="openNewTripForm">Add a new trip</a>.
            </v-card-text>
          </v-card>
        </template>
      </v-card-text>
    </v-card>
  </InfoPanelCardWrapper>
</template>

<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { MutationTypes, State } from "@/logic/store/store_types";
import { mapState } from "vuex";
import { SortedTripsGroup } from "@/logic/types/sheared_local_types";
import StoredTripsGroupCard from "@/ui/components/trips/history/StoredTripsGroupCard.vue";
import BackBtn from "@/ui/components/ui-elements/buttons/BackBtn.vue";

export default Vue.extend({
  name: "HistoryUnsavedView",
  components: { InfoPanelCardWrapper, StoredTripsGroupCard, BackBtn },
  methods: {
    // Routing
    back(): void {
      this.$router.back();
    },
    pushRoute(routeName: RouteNames): void {
      this.$router.push({ name: routeName });
    },
    openNewTripForm(): void {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
      this.pushRoute(RouteNames.trips);
    },
  },
  computed: {
    ...mapState({
      activeTripsGroupedByEV: (state: unknown): SortedTripsGroup[] => {
        const typedState = state as State;
        const trips = typedState.trips
          .filter((trip) => !trip.directusId)
          .reverse();
        const returnArray: SortedTripsGroup[] = [];
        const usedEVs: string[] = [];
        const unsortedGroup: SortedTripsGroup = {
          evModel: "unknown",
          trips: [],
        };
        trips.forEach((trip) => {
          let modelID: string | undefined = trip.vehicle?.eVModelId;
          if (!modelID) unsortedGroup.trips.push(trip);
          if (modelID && usedEVs.includes(modelID)) {
            const index = returnArray.findIndex(
              (group) => group.evModel === (modelID as string)
            );
            if (index !== -1) {
              returnArray[index].trips.push(trip);
            } else {
              returnArray.push({
                evModel: modelID,
                trips: [trip],
              });
            }
          } else if (modelID) {
            returnArray.push({
              evModel: modelID,
              trips: [trip],
            });
            usedEVs.push(modelID);
          }
        });
        if (unsortedGroup.trips.length) returnArray.push(unsortedGroup);
        return returnArray;
      },
      fetchingTrips: (state: unknown): boolean =>
        (state as State).tripFetchingFlag,
    }),
  },
});
</script>
