<template>
  <v-card class="rounded-lg my-5 pb-5" elevation="4" v-if="station">
    <v-card-text
      class="d-flex justify-space-between px-5 grey--text lighten2--text font-weight-medium"
    >
      <span>{{ station.simpleDisplayRating }} </span>
      <span>{{ `${range[0]}% - ${range[1]}%` }}</span>
    </v-card-text>
    <!-- display data if compatible with this charging station -->
    <template v-if="isCompatibleWithStation !== 'incompatible'">
      <v-card-text class="pb-0">
        <v-range-slider
          v-model="range"
          min="1"
          max="100"
          track-color="grey lighten-2"
          @end="calcDetails"
          hid-details
          dense
        />
      </v-card-text>
      <v-card-text class="pt-1">
        <v-row>
          <v-col cols="4">
            <p class="grey--text lighten-2--text text-center">Charge time</p>
            <v-row class="justify-center">
              <v-icon color="primary" class="mr-1"> mdi-clock-outline </v-icon>
              <p class="primary--text mb-0">{{ chargingTime }}</p>
            </v-row>
          </v-col>
          <v-col cols="4">
            <p class="grey--text lighten2--text text-center">Charge cost</p>
            <v-row class="justify-center">
              <v-icon color="primary" class="mr-1"> mdi-currency-usd </v-icon>
              <p class="primary--text mb-0">
                {{ `around $${chargingCost}` }}
              </p>
            </v-row>
          </v-col>
          <v-col cols="4">
            <p class="grey--text lighten2--text text-center">Energy Added</p>
            <v-row class="justify-center">
              <v-icon color="primary" class="mr-1"> mdi-lightning-bolt </v-icon>
              <p class="primary--text mb-0">
                {{ `${energyAdded}kWh (+${percentageCharged}%)` }}
              </p>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </template>
    <!-- display message if not compatible with this charging station -->
    <v-card-text v-else class="pl-5">
      Your selected EV is not compatible with this charging station
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState, mapGetters } from "vuex";
import { GettersTypes, type State } from "@/logic/store/store_types";
import type { isCompatibleReturn } from "@/logic/types/sheared_local_types";
import Charger from "@/logic/classes/charger_classes/charger";
import Connector from "@/logic/classes/charger_classes/connector";

export default Vue.extend({
  name: "ChargingCalculatorCard",
  props: {
    connector: {
      required: false,
      type: Object as PropType<Connector>,
    },
  },
  data() {
    return {
      range: [10, 90],
      energyAdded: 0,
      percentageCharged: 0,
      chargingTime: "",
      chargingCost: 0,
      isCompatibleWithStation: "incompatible" as isCompatibleReturn,
    };
  },
  computed: {
    ...mapGetters({
      station: GettersTypes.selectedChargerData,
      trip: GettersTypes.selectedTripData,
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      min: (state: unknown) => (state as State).SOCMin,
      max: (state: unknown) => (state as State).SOCMax,
      defaultPublicCostPerKWh: (state: unknown) =>
        (state as State).defaultPublicCostPerKWh,
      defaultCostPerMinDC: (state: unknown) =>
        (state as State).defaultCostPerMinDC,
    }),
  },
  methods: {
    calcDetails() {
      if (!this.station || !this.vehicle) {
        this.isCompatibleWithStation = "incompatible";
        return;
      }

      const isCompatible = (this.station as Charger).isCompatible(this.vehicle);
      this.isCompatibleWithStation = isCompatible;

      if (isCompatible === "incompatible") {
        return;
      }

      const details = (
        this.connector as Connector | undefined
      )?.getChargingEstimateDetails(
        this.vehicle,
        {
          min: this.range[0],
          max: this.range[1],
        },
        this.defaultPublicCostPerKWh,
        this.defaultCostPerMinDC
      );

      if (!details) {
        this.isCompatibleWithStation = "incompatible";
        return;
      }

      this.energyAdded = details.energyAdded;
      this.chargingCost = details.chargingCost;
      this.chargingTime = details.chargingTime;
      this.percentageCharged = details.percentageCharged;
    },
  },
  mounted() {
    this.$nextTick(() => {
      if (this.min && this.max) {
        this.range = [this.min * 100, this.max * 100];
      }
      this.$nextTick(() => {
        this.calcDetails();
      });
    });
  },
  watch: {
    connector() {
      this.calcDetails();
    },
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
