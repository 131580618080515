<template>
  <!-- sliding tab section -->
  <v-card flat>
    <v-tabs
      grow
      color="white"
      class="pwt-tabs-border"
      slider-size="47"
      active-class="pwt-custom-active-tab-text"
      v-model="tab"
      background-color="background"
    >
      <v-tabs-slider id="pwt-custom-active-tab"> </v-tabs-slider>
      <v-tab :ripple="false">Instructions </v-tab>
      <v-tab :ripple="false">Availability </v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab">
      <!-- Instructions tab content -->
      <v-tab-item>
        <v-card flat>
          <v-card-title class="primary--text">
            Instructions and details from the owner
          </v-card-title>
          <v-card-text class="pb-0">
            Operated by - {{ networkDisplayName }}
          </v-card-text>
          <v-card-text v-if="instructions" v-html="instructions"></v-card-text>
        </v-card>
      </v-tab-item>

      <!-- Availability tab content -->
      <v-tab-item class="pt-3 pb-5">
        <v-card flat>
          <BookingCalendar
            :assetId="changingStationId"
            :assetType="'charger'"
          ></BookingCalendar>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import BookingCalendar from "../../../booking/BookingCalendar.vue";
import { mapState } from "vuex";
import { State } from "@/logic/store/store_types";

export default Vue.extend({
  name: "ChargingStationsAnimatedTabsCard",
  props: {
    isBookable: {
      type: Boolean,
      default: false,
    },
    instructions: {
      type: String,
    },
    networkDisplayName: {
      type: String,
      default: "Unknown Network",
    },
  },
  computed: {
    ...mapState({
      changingStationId: (state: unknown) => (state as State).selectedCharger,
    }),
  },
  data() {
    return {
      tab: 0,
    };
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.calendar as any).checkChange(); // note ts has trouble typing refs hence the explicit any.
  },
  components: {
    BookingCalendar,
  },
});
</script>
<style scoped>
#pwt-custom-active-tab {
  border-radius: 10px 10px 0 0;
  background-color: var(--v-primary-base);
}

.pwt-tabs-border {
  border-bottom: 2px solid var(--v-primary-base);
}

.pwt-custom-active-tab-text {
  z-index: 1500;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
