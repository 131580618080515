<template>
  <svg
    width="673"
    height="673"
    viewBox="0 0 673 673"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_567_16237)">
      <path
        d="M554.431 202.651L554.711 202.371L450.528 98.187L420.841 127.874L479.934 186.967C453.609 197.049 434.844 222.255 434.844 252.222C434.844 290.871 466.211 322.238 504.86 322.238C514.942 322.238 524.184 319.997 532.866 316.356V518.282C532.866 533.685 520.264 546.288 504.86 546.288C489.457 546.288 476.854 533.685 476.854 518.282V392.253C476.854 361.446 451.648 336.241 420.841 336.241H392.835V140.196C392.835 109.39 367.629 84.1838 336.822 84.1838H168.784C137.977 84.1838 112.771 109.39 112.771 140.196V588.298H392.835V378.25H434.844V518.282C434.844 556.931 466.211 588.298 504.86 588.298C543.509 588.298 574.876 556.931 574.876 518.282V252.222C574.876 232.897 567.034 215.253 554.431 202.651ZM504.86 280.228C489.457 280.228 476.854 267.625 476.854 252.222C476.854 236.818 489.457 224.215 504.86 224.215C520.264 224.215 532.866 236.818 532.866 252.222C532.866 267.625 520.264 280.228 504.86 280.228ZM224.797 504.279V378.25H168.784L280.809 168.203V308.234H336.822L224.797 504.279Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_567_16237"
        x="0.74707"
        y="0.164551"
        width="702.026"
        height="702.025"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="29.8734" dy="29.8734" />
        <feGaussianBlur stdDeviation="29.8734" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_567_16237"
        />
      </filter>
    </defs>
  </svg>
</template>
<script lang="ts">
/** Custom icon wrapped in a template to be used by vuetify to gain access to the vuetify component properties*/
export default {
  name: "StationsIcon",
};
</script>
