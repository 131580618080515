import {
  calcChargingTimeAC,
  timeWithDCCurve,
} from "@/logic/utils/calcChargingTime";
import { connectorDetailsDataMap } from "../../data/connectorDetailsData";
import type {
  ConnectorExtensionData,
  ConnectorFormat,
  ConnectorType,
  PowerType,
} from "../../types/charger_Db_types";
import type { Connector as apiConnector } from "../../types/charger_Db_types";
import {
  ChargingDetails,
  isCompatibleReturn,
} from "../../types/sheared_local_types";

import generateUniqueLocalID from "../../utils/generateUniqueLocalID";
import getAssetSrc from "../../utils/getAssetSrc";
import Vehicle from "../vehicle_classes/vehicle";
import { Duration } from "luxon";

export default class Connector {
  // -------------------------------------------------------------------- //
  // ------------------------- Global class state ----------------------- //
  // -------------------------------------------------------------------- //

  /** global record of class instance ids this session. */
  static usedIds: string[] = [];

  // -------------------------------------------------------------------- //
  // ------------------------------- State ------------------------------ //
  // -------------------------------------------------------------------- //

  /** Identifier of the Connector within the EVSE. Two Connectors may have the same id as long as they do not belong to the same EVSE object. */
  id: string;

  /** The standard of the installed connector. */
  standard: ConnectorType;

  /** The format (socket/cable) of the installed connector. */
  format: ConnectorFormat;

  /** The current type of the connector. */
  powerType: PowerType;

  /** Maximum voltage of the connector (line to neutral for AC_3_PHASE), in volt [V]. For example: DC Chargers might vary the voltage during charging when battery almost full. */
  maxVoltage: number;

  /** Maximum amperage of the connector, in ampere [A]. */
  maxAmperage: number;

  /**
   * Maximum electric power that can be delivered by this connector, in Watts (W). When the maximum electric power is lower than the calculated value from voltage and amperage, this value should be set.
   * For example: A DC Charge Point which can delivers up to 920V and up to 400A can be limited to a maximum of 150kW (max_electric_power = 150000). Depending on the car, it may supply max voltage or current, but not both at the same time.
   * For AC Charge Points, the amount of phases used can also have influence on the maximum power.
   * */
  maxElectricPower?: number;

  /**
   * Identifiers of the currently valid charging tariffs. Multiple tariffs are possible, but only one of each Tariff.type can be active at the same time. Tariffs with the same type are only allowed if they are not active at the same time: start_date_time and end_date_time period not overlapping
   * When preference-based smart charging is supported, one tariff for every possible ProfileType should be provided. These tell the user about the options they have at this Connector, and what the tariff is for every option.
   * For a "free of charge" tariff, this field should be set and point to a defined "free of charge" tariff.
   */
  tariffIds?: string;

  /** Data source information for this connector. */
  extensionData: ConnectorExtensionData;

  // -------------------------------------------------------------------- //
  // --------------------------- Constructor ---------------------------- //
  // -------------------------------------------------------------------- //

  constructor({
    id = undefined,
    standard,
    format,
    powerType,
    maxVoltage,
    maxAmperage,
    maxElectricPower = undefined,
    tariffIds = undefined,
    extensionData = undefined,
  }: {
    id?: string;
    standard: ConnectorType;
    format: ConnectorFormat;
    powerType: PowerType;
    maxVoltage: number;
    maxAmperage: number;
    maxElectricPower?: number;
    tariffIds?: string;
    extensionData?: ConnectorExtensionData;
  }) {
    // required to be set on creation
    this.standard = standard;
    this.format = format;
    this.maxAmperage = maxAmperage;
    this.maxVoltage = maxVoltage;
    this.powerType = powerType;

    // require extra computation for defaults
    this.id = id ?? generateUniqueLocalID(Connector.usedIds, "connector");
    this.extensionData = extensionData ?? {};

    // optional/with basic default values
    this.maxElectricPower = maxElectricPower;
    this.tariffIds = tariffIds;

    // add id to list of used unique ids
    Connector.usedIds.push(this.id);
  }

  /**
   * Creates a new `Connector` object from the expected OCPI compliant returned
   * data form the charger DB api.
   *
   * @param data the whole data object for the `Connector` returned by the charger DB api.
   * @returns new `Connector` class object.
   */
  static fromChargerDbData(data: apiConnector): Connector | undefined {
    if (
      data.standard === "ConnectorTypeEnum(0)" ||
      data.format === "ConnectorFormatEnum(0)" ||
      data.power_type === "PowerTypeEnum(0)"
    )
      return;

    return new Connector({
      id: data.id,
      standard: data.standard,
      format: data.format,
      powerType: data.power_type,
      maxVoltage: data.max_voltage,
      maxAmperage: data.max_amperage,
      maxElectricPower: data.max_electric_power,
      tariffIds: data.tariff_ids,
      extensionData: data.extension_data,
    });
  }

  // -------------------------------------------------------------------- //
  // ------------------------------ Getters ----------------------------- //
  // -------------------------------------------------------------------- //

  /** Returns a user friendly display name for this connector */
  public get displayName(): string {
    const baseDisplayName = connectorDetailsDataMap.get(
      this.standard
    )?.displayName;

    return (
      (baseDisplayName ? baseDisplayName + " " : "") +
      (this.format === "CABLE" ? "Tethered" : "Socketed")
    );
  }

  public get ratingDisplayStr(): string {
    const rating = this.maxElectricPower ? this.maxElectricPower / 1000 : 0;
    const current = ACPowerTypeList.includes(this.powerType) ? "AC" : "DC";
    return rating + "kW " + current + " charger";
  }

  /**
   * Finds and returns the icon src url if there is one.
   *
   * @returns the icon src url if there is one.
   */
  public get getPlugIconURL(): string | undefined {
    const detailsData = connectorDetailsDataMap.get(this.standard);
    if (!detailsData) return;
    if (typeof detailsData.iconURL === "object") {
      if (this.format === "CABLE") return detailsData.iconURL.cable;
      if (this.format === "SOCKET") return detailsData.iconURL.socket;
      return;
    }
    return detailsData.iconURL;
  }

  /** Returns either an actual src for this connectors plug icon or the default img. */
  public get getPlugIconSrc(): string {
    return getAssetSrc(
      this.getPlugIconURL ?? "/car_images/No_Image_Powersell.png"
    );
  }

  // -------------------------------------------------------------------- //
  // --------------------------- Public Methods ------------------------- //
  // -------------------------------------------------------------------- //

  /**
   * Checks if a passed id relates to this `Connector`.
   *
   * @param id the id to be checked against
   * @returns true if id matches this connector or one of the ids it is known by in the known data sources.
   */
  public isThisConnector(id: string | number): boolean {
    // check if matches local id.
    if (id === this.id) return true;

    // check if matches source ids.
    if (this.extensionData) {
      const listOfValues = Object.values(this.extensionData);
      if (listOfValues.includes(id)) return true;
    }

    // default return if no conditions are fulfilled.
    return false;
  }

  /**
   * Returns weather or not an EV is compatible with this connector.
   *
   * @param vehicle the whole `Vehicle` class object for the selected vehicle.
   * @returns weather the EV is compatible with this connector or not as one of the following:
   * - "compatible" - can use this connectors tethered connector to charge.
   * - "compatible with cable only" - no hard point connectors are compatible however can charge here with one or more of the cabled adapters the driver has access to.
   * - "incompatible"- this EV can not use this connector.
   */
  public isCompatible(vehicle: Vehicle): isCompatibleReturn {
    // guard clauses.
    if (!this.format) return "incompatible";
    if (!this.standard) return "incompatible";

    const vehicleConnectors: ConnectorType[] = [];
    const vehicleCabledConnectors: ConnectorType[] = [];

    // Collate vehicle connectors.
    // ASSUMES: format is still from the perspective of the charger not the vehicle.
    // e.g a "CABLE" connector is where the connector has the capability of
    // extending out to the vehicle to interface with one charing port on the
    // vehicle its self. Where as for a "SOCKET", the vehicle/driver has a cable with
    // the appropriate attachments to extend from the vehicle to the connector.
    vehicle.evNavPlugs.forEach((plug) => {
      if (plug.Format === "CABLE" && !vehicleConnectors.includes(plug.Standard))
        vehicleConnectors.push(plug.Standard);
      if (
        plug.Format === "SOCKET" &&
        !vehicleCabledConnectors.includes(plug.Standard)
      )
        vehicleCabledConnectors.push(plug.Standard);
    });

    // check for tethered compatibility.
    if (this.format === "CABLE" && vehicleConnectors.includes(this.standard))
      return "compatible";

    // check for socketed compatibility.
    if (
      this.format === "SOCKET" &&
      vehicleCabledConnectors.includes(this.standard)
    )
      return "compatible with cable only";

    return "incompatible";
  }

  /**
   * calculates and returns an estimate in the form of a `ChargingDetails` object.
   *
   * @param vehicle the `Vehicle` class object for the target vehicle.
   * @param range the percentage range for this charging session as whole numbers not decimal representations.
   * @param defaultCostPerKWh the cost per kWh for public charging.
   * @param defaultCostPerMinDC the cost per min for public fast charging.
   * @returns a populated `ChargingDetails` object.
   */
  public getChargingEstimateDetails(
    vehicle: Vehicle,
    range: { min: number; max: number },
    defaultCostPerKWh: number,
    defaultCostPerMinDC: number
  ): ChargingDetails | undefined {
    // cant find model guard clauses.
    if (
      !vehicle.evModel ||
      !this.powerType ||
      !this.standard ||
      this.isCompatible(vehicle) === "incompatible" ||
      !this.maxElectricPower
    )
      return;

    // extrapolate calculation data from pass data.
    const minChargeInKWH =
      vehicle.totalBatteryKWh() * (range.min !== 0 ? range.min / 100 : 0);
    const maxChargeInKWH =
      vehicle.totalBatteryKWh() * (range.max !== 0 ? range.max / 100 : 0);
    const EVModelDCRating = vehicle.evModel.maxElectricPowerDC;
    const EVModelACRating = vehicle.evModel.maxElectricPowerAC;

    // Calculate charging time in hours.
    let chargingTimeInHours = 0;

    if (ACPowerTypeList.includes(this.powerType)) {
      // get smallest of either charger output or vehicles accepted input.
      const minCurrentRating = Math.min(
        this.maxElectricPower / 1000,
        EVModelACRating
      );
      // calculate charging time in hours.
      chargingTimeInHours =
        calcChargingTimeAC(minChargeInKWH, maxChargeInKWH, minCurrentRating) ??
        0;
    }

    if (this.powerType === "DC") {
      // get smallest of either charger output or vehicles accepted input.
      const minCurrentRating = Math.min(
        this.maxElectricPower / 1000,
        EVModelDCRating
      );
      // calculate charging time in hours.
      chargingTimeInHours =
        timeWithDCCurve(
          minChargeInKWH,
          maxChargeInKWH,
          vehicle.totalBatteryKWh(),
          minCurrentRating
        ) ?? 0;
    }

    // calculate time dividend based on per min charging.
    // NOTE this was formerly dictated by the `TimeCostPeriod`.
    // This property has now been deprecated and tariffs are to
    // take this place in a later iteration.
    // TODO: Add new logic when this is available to support tariffs.
    const timeMultiplier = 60;

    // calculate charging time cost (NOTE: still needs `kWCost` to get full cost).
    // ASSUMES: that there is no cost per min for AC charging is still true.
    const timeCost =
      chargingTimeInHours *
      timeMultiplier *
      (this.powerType == "DC" ? defaultCostPerMinDC : 0);

    // calculate kW Cost (NOTE: still needs `timeCost` to get full cost).
    const kWCost = (maxChargeInKWH - minChargeInKWH) * defaultCostPerKWh;

    // create `chargingDetails` object and return it
    return {
      chargingTime: Duration.fromObject({
        hours: 0,
        minutes: Math.round(chargingTimeInHours * 60),
      })
        .normalize()
        .toHuman({ unitDisplay: "narrow" })
        .replace(",", ""),
      chargingTimeInSeconds: Math.round(chargingTimeInHours * 3600),
      chargingCost: Math.round(timeCost + kWCost),
      energyAdded: Math.round(maxChargeInKWH - minChargeInKWH),
      percentageBeforeCharging: Math.round(range.min),
      percentageCharged: Math.round(range.max - range.min),
      percentageAfterCharging: Math.round(range.max),
    };
  }
}

/** Supported list on AC power types. */
export const ACPowerTypeList: PowerType[] = [
  "AC_1_PHASE",
  "AC_2_PHASE",
  "AC_2_PHASE_SPLIT",
  "AC_3_PHASE",
];
