import parseIntOrFloat from "./parseNumOrFloat";
import queryValueToString from "./queryValueToString";

/**
 * Converts a query value to a number.
 *
 * @param {string | (string | null)[]} value - The query value to convert.
 * @return {number | undefined} - The converted number, or undefined if the conversion fails.
 */
export default function queryValueToNumber(
  value: string | (string | null)[]
): number | undefined {
  const stringValue = queryValueToString(value);
  if (stringValue) return parseIntOrFloat(stringValue);
}
