// get base path
const elementData = document?.querySelector("powertrip-optimiser");
const passedBackendPath =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elementData?.attributes?.["backend" as any]?.nodeValue; // this version of ts has issue typing dom objects correctly hence the any.
const backendParts = passedBackendPath?.split("."); // splits "https://sandbox.gameplan.powertrip.earth" to ["https://sandbox","gameplan","powertrip", "earth"]
const extrapolatedFrontendPath = backendParts
  ? `${backendParts[0]}.optimiser.powertrip.earth/img/`
  : undefined;

// asset base urls for use with getting images while an embedded web component.
const devAssetUrl = "http://localhost:8000/img/";
const prodAssetUrl =
  extrapolatedFrontendPath ?? "https://sandbox.optimiser.powertrip.earth/img/";
const baseAssetUrl =
  process.env.NODE_ENV === "development" ? devAssetUrl : prodAssetUrl;

export default function getAssetSrc(assetFileName: string): string {
  return baseAssetUrl + assetFileName;
}
