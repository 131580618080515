<template>
  <!-- collapsed view -->
  <div v-if="!expanded" id="pwt-info-panel-expand-btn" style="z-index: 1100">
    <v-row class="pt-2">
      <v-btn icon @click="expand" class="background mx-2">
        <v-icon color="grey">mdi-menu </v-icon>
      </v-btn>
      <v-btn
        v-if="!trip"
        class="rounded-pill text-none text-caption mr-2 px-4"
        small
        @click="toggleDC(!showingDC)"
        :color="showingDC ? 'primary' : undefined"
      >
        {{ $vuetify.breakpoint.smAndUp ? "Fast chargers" : "DC" }}
      </v-btn>
      <v-btn
        v-if="!trip"
        class="rounded-pill text-none text-caption mr-2 px-4"
        small
        @click="toggleAC(!showingAC)"
        :color="showingAC ? 'primary' : undefined"
      >
        {{ $vuetify.breakpoint.smAndUp ? "Slow chargers" : "AC" }}
      </v-btn>
      <v-btn
        v-if="trip"
        small
        class="rounded-pill text-none text-caption mr-2 px-4"
        @click="toggleChargers"
        :color="
          showingChargersAlongRoute || fetchingChargers ? 'primary' : undefined
        "
        :loading="fetchingChargers"
      >
        <span v-if="$vuetify.breakpoint.smAndUp"> All chargers on route </span>
        <v-icon
          v-else
          :color="showingChargersAlongRoute ? 'white' : 'grey'"
          small
        >
          mdi-map-marker-radius
        </v-icon>
      </v-btn>
    </v-row>
  </div>
  <!-- expanded view -->
  <div v-else>
    <!-- floating controls -->
    <div id="pwt-info-panel-minimize-btn" :class="classes">
      <v-row class="pt-2">
        <v-btn
          v-if="!trip"
          class="rounded-pill text-none text-caption mr-2 px-4"
          small
          @click="toggleDC(!showingDC)"
          :color="showingDC ? 'primary' : undefined"
        >
          Fast chargers
        </v-btn>
        <v-btn
          v-if="!trip"
          class="rounded-pill text-none text-caption mr-2 px-4"
          small
          @click="toggleAC(!showingAC)"
          :color="showingAC ? 'primary' : undefined"
        >
          Slow chargers
        </v-btn>
        <v-btn
          v-if="trip"
          class="rounded-pill text-none text-caption mr-2 px-4"
          @click="toggleChargers"
          :color="
            showingChargersAlongRoute || fetchingChargers
              ? 'primary'
              : undefined
          "
          :loading="fetchingChargers"
          small
        >
          All chargers on route
        </v-btn>
      </v-row>
    </div>
    <!-- main card -->
    <InfoPanel
      @minimize="minimize"
      :animation="animation"
      @showDashboard="emitShowDashboard"
    />
  </div>
</template>
<script lang="ts">
import Vue from "vue";
import InfoPanel from "./InfoPanel.vue";
import { GettersTypes, MutationTypes, State } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip_classes/trip";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "InfoPanelControls",
  data() {
    return {
      expanded: true,
      animation: null as "slide-left" | "slide-right" | null,
      fetchingChargers: false,
    };
  },
  methods: {
    expand() {
      this.$store.commit(MutationTypes.setInfoPanelAnimation, "slide-right");
      this.animation = "slide-right";
      this.expanded = true;
      this.clearAnimation();
    },
    minimize() {
      this.$store.commit(MutationTypes.setInfoPanelAnimation, "slide-left");
      this.animation = "slide-left";
      setTimeout(() => {
        this.expanded = false;
      }, 500);
      this.clearAnimation();
    },
    clearAnimation() {
      setTimeout(() => {
        this.$store.commit(MutationTypes.setInfoPanelAnimation, undefined);
        this.animation = null;
      }, 701);
    },
    emitShowDashboard() {
      this.$emit("showDashboard");
    },
    async toggleChargers() {
      this.fetchingChargers = true;
      if (!(this.trip as Trip).chargersAlongRouteCDBIDs.length) {
        await (this.trip as Trip).getRadarData();
      }
      this.$store.commit(
        MutationTypes.setDisplayAllChargersAlongRoute,
        !this.showingChargersAlongRoute
      );
      this.fetchingChargers = false;
    },
    toggleAC(changeTo: boolean) {
      this.$store.commit(MutationTypes.setShowPrivateACChargers, changeTo);
      this.$store.commit(MutationTypes.setShowPublicACChargers, changeTo);
    },
    toggleDC(changeTo: boolean) {
      this.$store.commit(MutationTypes.setShowPrivateDCChargers, changeTo);
      this.$store.commit(MutationTypes.setShowPublicDCChargers, changeTo);
    },
  },
  components: {
    InfoPanel,
  },
  computed: {
    ...mapGetters({
      trip: GettersTypes.selectedTripData,
    }),
    ...mapState({
      showingChargersAlongRoute: (state: unknown): boolean =>
        (state as State).displayAllChargersAlongRoute,
      showingAC: (state: unknown): boolean =>
        (state as State).showPrivateACChargers &&
        (state as State).showPublicACChargers,
      showingDC: (state: unknown): boolean =>
        (state as State).showPrivateDCChargers &&
        (state as State).showPublicDCChargers,
    }),
    classes(): string {
      if (this.animation == "slide-left") {
        return this.$vuetify.breakpoint.xs
          ? "ma-sm-2"
          : "ma-sm-2 pwt-info-panel-btn-right-to-left-animation";
      }
      if (this.animation == "slide-right") {
        return this.$vuetify.breakpoint.xs
          ? "ma-sm-2"
          : "ma-sm-2 pwt-info-panel-btn-left-to-right-animation";
      }

      return "ma-sm-2";
    },
  },
});
</script>
<style scoped>
#pwt-info-panel-expand-btn {
  z-index: 1102;
  position: absolute;
  top: 8px;
  left: 8px;
}

#pwt-info-panel-minimize-btn {
  z-index: 1102;
  position: absolute;
  top: 0;
  left: clamp(410px, calc(40% + 10px), 610px);
}

#pwt-info-panel-floating-controls-overlay {
  z-index: 1102;
  position: relative;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: calc(100% - 30px) !important;
  background-color: transparent;
}

.pwt-info-panel-btn-left-to-right-animation {
  left: -56%;
  -webkit-animation: btn-left-to-right 700ms linear forwards;
  animation: btn-left-to-right 700ms linear forwards;
}

.pwt-info-panel-btn-right-to-left-animation {
  left: 0;
  -webkit-animation: btn-right-to-left 1s linear forwards;
  animation: btn-right-to-left 1s linear forwards;
}

@-webkit-keyframes btn-left-to-right {
  from {
    left: 0;
  }
  to {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
}
@-webkit-keyframes btn-right-to-left {
  from {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
  to {
    left: -56%;
  }
}

@keyframes btn-left-to-right {
  from {
    left: 0;
  }
  to {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
}
@keyframes btn-right-to-left {
  from {
    left: clamp(410px, calc(40% + 10px), 610px);
  }
  to {
    left: -56%;
  }
}
</style>
