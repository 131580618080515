/**
 * Capitalizes the first letter of a given string.
 * @param string target string to alter.
 * @returns a new string with the adjusted capitalization
 */
export function capitalizeFirstLetter(string: string) {
  // empty string guard clause.
  if (string === "") return "";
  // capitalize First character and attach remaining characters if there are any then return.
  return (
    string.charAt(0).toUpperCase() + (string.length >= 2 ? string.slice(1) : "")
  );
}
