<template>
  <v-card flat v-if="locationData" class="px-8 transparent">
    <v-card-title class="pt-0" style="word-break: normal">
      {{ locationData.address }}
    </v-card-title>
    <v-card-text>
      <ul style="list-style: none" class="pl-0">
        <li v-if="isOrigin && isDestination">
          This location is your origin and your destination
        </li>
        <li v-else-if="isOrigin">This location is your origin</li>
        <li v-else-if="isDestination">This location is your destination</li>

        <li v-if="locationData.time">
          {{ getTimeDisplayMessage(locationData.id, locationData.time) }}
        </li>
        <li v-if="tripData && isTrip && isOrigin" class="pt-2">
          Departing charge {{ Math.round(tripData.SOCAct * 100) }}%
        </li>
        <li v-else-if="tripData && isTrip && isDestination" class="pt-2">
          Arriving charge {{ Math.round(tripData.SOCEnd * 100) }}%
        </li>
        <li v-if="locationData.stay" class="pt-2">
          You are scheduled to be at this location for
          {{ calcStayDuration(locationData.stay) }}
        </li>
        <li
          v-if="
            locationData.chargeHere && locationData.stateOfChargeAfterCharging
          "
          class="pt-2"
        >
          You have indicated that you will charge to
          {{ locationData.stateOfChargeAfterCharging * 100 }}% while at this
          location.
        </li>
      </ul>
    </v-card-text>
    <v-expansion-panels class="mb-5" v-if="!isOrigin && !isDestination">
      <v-expansion-panel>
        <v-expansion-panel-header class="d-flex text-body-2 font-weight-medium">
          <span>
            <v-icon color="primary" class="mr-1"> $stationsIcon </v-icon>
            <span>
              {{ closeByChargers.length || "No" }} charging station{{
                closeByChargers.length > 1 ? "s" : ""
              }}
              near by</span
            >
          </span>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-card
            v-for="(station, index) in closeByChargers"
            :key="`close-by-station-${index}`"
            @click="openChargingStationDetails(station.id)"
          >
            <v-card-title class="font-weight-bold text-body-2">
              {{ station.name }}
            </v-card-title>
            <v-card-subtitle class="grey--text text--darken-2">
              {{ station.simpleDisplayRating }}
            </v-card-subtitle>
          </v-card>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
  <v-card flat v-else>
    <v-card-text>
      Whoops! there was a problem in loading this locations details, please
      close this popup and try again.
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters } from "vuex";
import { Duration } from "luxon";
import Charger from "@/logic/classes/charger_classes/charger";
import { GettersTypes, type State } from "@/logic/store/store_types";
import { MutationTypes } from "@/logic/store/store_types";
import { MainDialogContent } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip_classes/trip";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";

export default Vue.extend({
  name: "LocationDetailsContent",
  computed: {
    closeByChargers(): Charger[] {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      const location: TripLocation | undefined =
        this.$store.getters[GettersTypes.selectedLocationData];
      const chargers: Charger[] = (this.$store.state as State).chargers;
      if (trip && location) {
        return location.getCloseChargers(
          chargers,
          trip.chargersAlongRouteCDBIDs
        );
      }
      return [];
    },
    isOrigin(): boolean {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      const location: TripLocation | undefined =
        this.$store.getters[GettersTypes.selectedLocationData];
      if (trip && location) {
        return location.local_id === trip.locations[0].local_id;
      }
      return false;
    },
    isDestination(): boolean {
      const trip: Trip | undefined =
        this.$store.getters[GettersTypes.selectedTripData];
      const location: TripLocation | undefined =
        this.$store.getters[GettersTypes.selectedLocationData];
      if (trip instanceof Trip && location instanceof TripLocation) {
        if (trip.roundTripFlag) {
          return location.local_id === trip.locations[0].local_id; // note is also the origin as is a round trip.
        }
        return (
          location.local_id ===
          trip.locations[trip.locations.length - 1].local_id
        );
      }
      return false;
    },
    isTrip(): boolean {
      return (
        (this.$store.getters[GettersTypes.selectedTripData] as
          | Trip
          | undefined) instanceof Trip
      );
    },
    ...mapGetters({
      locationData: GettersTypes.selectedLocationData,
      tripData: GettersTypes.selectedTripData,
    }),
  },
  methods: {
    calcStayDuration(staySeconds: number): string {
      return Duration.fromObject({
        hours: 0,
        minutes: Math.round(staySeconds / 60),
      })
        .normalize()
        .toHuman({ unitDisplay: "narrow" })
        .replace(",", "");
    },
    getTimeDisplayMessage(id: string, time: string): string {
      switch (id) {
        case "location-start":
          return `Departure time ${time}`;
        case "location-end":
        default:
          return `Arrival time ${time}`;
      }
    },
    openChargingStationDetails(id: string): void {
      this.$store.commit(MutationTypes.setSelectedCharger, id);
      this.$store.commit(MutationTypes.setTertiaryDialogContent, undefined);
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.CHARGING_STATION_DETAILS
      );
    },
  },
});
</script>
