<template>
  <v-card flat>
    <v-card-text class="px-0 px-sm-3 pb-5">
      <v-toolbar flat class="px-0 pt-0" v-if="$vuetify.breakpoint.xsOnly">
        <v-toolbar-title v-if="$refs.calendar">
          {{ titleDisplayString() }}
        </v-toolbar-title>
      </v-toolbar>
      <v-toolbar flat class="px-0">
        <ElevatedBtn @click="setToday" class="mr-4"> Today </ElevatedBtn>
        <v-btn fab text small color="grey darken-2" @click="prev">
          <v-icon small> mdi-chevron-left </v-icon>
        </v-btn>
        <v-btn fab text small color="grey darken-2" @click="next">
          <v-icon small> mdi-chevron-right </v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <v-toolbar-title v-if="$refs.calendar && $vuetify.breakpoint.smAndUp">
          {{ titleDisplayString() }}
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu bottom right>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              class="text-none rounded-lg"
              color="primary"
              v-bind="attrs"
              v-on="on"
            >
              <span>{{ typeDisplayString(type) }}</span>
              <v-icon right> mdi-menu-down </v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item @click="type = 'day'">
              <v-list-item-title>Day</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'week'">
              <v-list-item-title>Week</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = 'month'">
              <v-list-item-title>Month</v-list-item-title>
            </v-list-item>
            <v-list-item @click="type = '4day'">
              <v-list-item-title>4 days</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
      <v-sheet height="600">
        <v-calendar
          ref="calendar"
          :type="type"
          color="primary"
          v-model="focus"
          :events="events"
          :event-color="getEventColor"
          :event-ripple="false"
          @change="getEvents"
          @mousedown:event="startDrag"
          @mousedown:time="startTime"
          @mousemove:time="mouseMove"
          @mouseup:time="endDrag"
          @mouseleave.native="cancelDrag"
          @mousedown:day="skipToDay"
          @click:event="showDetails"
          style="border: 1px solid lightgray"
          class="rounded"
        >
          <template v-slot:event="{ event, timed, eventSummary }">
            <div
              class="v-event-draggable"
              :class="
                event.localId === collideEventId ? 'animate-collision' : ''
              "
              @mousedown.stop="showEventDetails(event)"
            >
              <component :is="{ render: eventSummary }"></component>
            </div>
            <div
              v-if="timed && isThisUsers(event)"
              class="v-event-drag-bottom"
              @mousedown.stop="extendBottom(event)"
            ></div>
          </template>
        </v-calendar>
      </v-sheet>
    </v-card-text>
    <!-- Details modal -->
    <v-dialog
      v-model="showDetailsModal"
      :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
      style="z-index: 1400"
      :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
      max-width="300px"
      min-width="200px"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      @click:outside="closeDetails"
    >
      <v-card v-if="shownEventDetails">
        <v-card-title class="primary white--text d-flex justify-space-between">
          <span>
            <v-icon color="white">mdi-calendar</v-icon>
          </span>
          <span>
            {{ shownEventDetails.name }}
          </span>
          <span>
            <v-btn icon @click="closeDetails">
              <v-icon color="white">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-card-title>
        <v-card-text v-if="shownEventEditing"> editing </v-card-text>
        <v-card-text class="d-flex flex-column pt-3" v-else>
          <span> Date: {{ niceDate(shownEventDetails.start) }}</span>
          <span> Start: {{ niceTime(shownEventDetails.start) }} </span>
          <span> End: {{ niceTime(shownEventDetails.end) }} </span>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-col>
            <ElevatedBlockBtn
              class="mb-3"
              @click="
                () => {
                  shownEventEditing = true;
                }
              "
            >
              Edit Booking
            </ElevatedBlockBtn>
            <OutlinedBlockBtn @click="deleteEvent">
              Delete Booking
            </OutlinedBlockBtn>
          </v-col>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Editing modal -->
    <BookingEditModal
      @update="
        ($event) => {
          shownEventDetails = $event;
        }
      "
      @edit="attemptEditEvent"
      @close="closeDetails"
      @clearError="
        () => {
          bookingCreationErrorMsg = null;
        }
      "
      :event="shownEventDetails"
      :open="shownEventEditing"
      :error="bookingCreationErrorMsg"
    />
    <!-- Confirmation modal -->
    <BookingConfirmationModal
      @update="
        ($event) => {
          createEvent = $event;
        }
      "
      @create="attemptCreateEvent"
      @close="closeConfirmation"
      @clearError="
        () => {
          bookingCreationErrorMsg = null;
        }
      "
      :event="createEvent"
      :open="showConfirmationModal"
      :error="bookingCreationErrorMsg"
    />
  </v-card>
</template>
<script lang="ts">
import { State } from "@/logic/store/store_types";
import generateUniqueLocalID from "@/logic/utils/generateUniqueLocalID";
import { DateTime } from "luxon";
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import {
  CalEvent,
  ExtendedCalendarTimestamp,
  CalendarClickEvent,
} from "@/logic/types/booking_types";
import {
  convert24hrTimeToEpoch,
  convertEpochTo24hrTime,
  convertEpochToISODateString,
  convertISODateStringToEpoch,
} from "@/logic/utils/timeUtils";
import {
  createAssetBooking,
  deleteAssetBooking,
  DirectusAssetBooking,
  DirectusAssetBookingCreationData,
  fetchAssetBookingsByAssetID,
  updatedAssetBooking,
} from "@/logic/api/calls/directus_calls/assetBookingsCalls";
import BookingConfirmationModal from "./BookingConfirmationModal.vue";
import BookingEditModal from "./BookingEditModal.vue";
import { PartialObj } from "@/logic/types/generic_types";
import ElevatedBtn from "../ui-elements/buttons/ElevatedBtn.vue";
import ElevatedBlockBtn from "../ui-elements/buttons/ElevatedBlockBtn.vue";
import OutlinedBlockBtn from "../ui-elements/buttons/OutlinedBlockBtn.vue";

export default Vue.extend({
  name: "BookingCalendar",
  props: {
    assetId: {
      type: [String, Number] as PropType<string | number>,
      required: true,
    },
    assetType: {
      type: String as PropType<"vehicle" | "charger">,
      required: true,
    },
  },
  computed: {
    ...mapState({
      userId: (state: unknown): string | undefined =>
        (state as State).user?.directusId,
    }),
  },
  data() {
    return {
      focus: "",
      type: "4day",
      typeToLabel: {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      },
      events: [] as CalEvent[],
      names: ["Booked", "My Booking"],
      dragEvent: null as CalEvent | null,
      dragTime: null as number | null,
      createEvent: null as CalEvent | null,
      createStart: null as number | null,
      extendOriginal: null as number | null,
      usedLocalIds: [] as string[],
      collideEventId: null as string | null,
      showDetailsModal: false,
      shownEventDetails: null as CalEvent | null,
      showConfirmationModal: false,
      bookingCreationLoading: false,
      bookingCreationErrorMsg: null as string | null,
      shownEventEditing: false,
    };
  },
  methods: {
    // --------------------------------------------------------------------------------------------- //
    // -------------------------------- Calendar navigation methods -------------------------------- //
    // --------------------------------------------------------------------------------------------- //
    /**
     * Moves to focus to the previous calendar plane.
     *
     * days moved depends on calendar view e.g. day view will move to
     * the previous day, month view will move to the previous month
     * etc.
     */
    prev(): void {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.calendar as any)?.prev(); // note ts has trouble typing refs hence the explicit any.
    },
    /**
     * Moves to focus to the next calendar plane.
     *
     * days moved depends on calendar view e.g. day view will move to
     * the next day, month view will move to the next month
     * etc.
     */
    next() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (this.$refs.calendar as any)?.next(); // note ts has trouble typing refs hence the explicit any.
    },
    /** removes the focus forcing the default of today to take over. */
    setToday() {
      this.focus = "";
    },
    /** Skip to day. */
    skipToDay(tms: ExtendedCalendarTimestamp) {
      this.focus = tms.date;
      this.type = "day";
    },
    // --------------------------------------------------------------------------------------------- //
    // ---------------------------------- Calendar action methods ---------------------------------- //
    // --------------------------------------------------------------------------------------------- //
    /**
     * Sets the drag event.
     *
     * the drag event dose double duty as the starting event for a drag action on a users own events
     * or preventing clicking through this event to set a new event.
     */
    startDrag({ event, timed }: { event: CalEvent; timed: boolean }) {
      if (event && timed) {
        this.dragEvent = event;
        this.dragTime = null;
        this.extendOriginal = null;
      }
    },
    /** Adds a start time to create an event or to move an existing event that belongs to the user. */
    startTime(tms: ExtendedCalendarTimestamp) {
      // convert time object to ms
      const mouse = this.toTime(tms);
      // checks if there is a drag event or to create a new one
      if (this.dragEvent && this.dragTime === null) {
        // check if this belongs to the user. Prevent further action if not.
        if (this.isThisUsers(this.dragEvent)) {
          const start = this.dragEvent.start;
          this.dragTime = mouse - start;
        }
      } else {
        // creates a new event
        this.createStart = this.roundTime(mouse);
        this.createEvent = {
          name: `My Booking`,
          color: this.$vuetify.theme.themes.light.primary as string,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          start: this.createStart!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          end: this.createStart!,
          timed: true,
          userId: this.userId,
          localId: generateUniqueLocalID(this.usedLocalIds, "calendar-event"),
          assetId: this.assetId,
          assetType: this.assetType,
        };
        this.showConfirmationModal = true;
      }
    },
    addEvent() {
      if (this.createEvent) {
        // Add new event to events list.
        this.events.push(this.createEvent);
        // clear creation data
        this.closeConfirmation();
      }
    },
    /** Increases the duration of selected event. */
    extendBottom(event: CalEvent) {
      this.createEvent = event;
      this.createStart = event.start;
      this.extendOriginal = event.end;
    },
    /**
     * Captures movement over the calendar during drag and creation actions.
     *
     * Note: this fires repeatedly during the mouse movement and may be a point of optimization
     * in future iterations.
     */
    mouseMove(tms: ExtendedCalendarTimestamp) {
      const mouse = this.toTime(tms);
      if (this.dragEvent && this.dragTime !== null) {
        const start = this.dragEvent.start;
        const end = this.dragEvent.end;
        const duration = end - start;
        const newStartTime = mouse - this.dragTime;
        const newStart = this.roundTime(newStartTime);
        const newEnd = newStart + duration;
        // check if over laps another event.
        if (
          this.overlaps(
            { ...this.dragEvent, start: newStart, end: newEnd },
            this.events
          )
        ) {
          this.startAnimation(this.dragEvent.localId ?? null);
          return;
        }
        this.dragEvent.start = newStart;
        this.dragEvent.end = newEnd;
      } else if (this.createEvent && this.createStart !== null) {
        const mouseRounded = this.roundTime(mouse, false);
        const min = Math.min(mouseRounded, this.createStart);
        const max = Math.max(mouseRounded, this.createStart);
        // check if over laps another event.
        if (
          this.overlaps(
            { ...this.createEvent, start: min, end: max },
            this.events
          )
        ) {
          this.startAnimation(this.createEvent.localId ?? null);
          this.endDrag();
          return;
        }
        this.createEvent.start = min;
        this.createEvent.end = max;
      }
    },
    /** Cleans up drag event data. */
    endDrag() {
      this.dragTime = null;
      this.dragEvent = null;
      // this.createEvent = null;
      // this.createStart = null;
      this.extendOriginal = null;
    },
    /** Terminates drag action. */
    cancelDrag() {
      if (this.createEvent) {
        if (this.extendOriginal) {
          this.createEvent.end = this.extendOriginal;
        } else {
          const i = this.events.indexOf(this.createEvent);
          if (i !== -1) {
            this.events.splice(i, 1);
          }
        }
      }
      this.dragTime = null;
      this.dragEvent = null;
    },
    /** Queues and clears collision animation. */
    startAnimation(eventLocalId: string | null) {
      this.collideEventId = eventLocalId;
      setTimeout(() => {
        if (this.collideEventId === eventLocalId) this.collideEventId = null;
      }, 1000);
    },
    /** displays target event in a details modal from an event click event (v-calendar native event)*/
    showDetails(event: CalendarClickEvent): void {
      if (this.isThisUsers(event.event)) {
        this.shownEventDetails = event.event;
        this.showDetailsModal = true;
      }
    },
    /** displays target event in a details modal from a normal div click event*/
    showEventDetails(event: CalEvent) {
      if (this.isThisUsers(event)) {
        this.shownEventDetails = event;
        this.showDetailsModal = true;
      }
    },
    /** closes the details modal. */
    closeDetails(): void {
      this.showDetailsModal = false;
      this.shownEventDetails = null;
      this.shownEventEditing = false;
      this.bookingCreationErrorMsg = null;
    },
    /** closes the details modal. */
    closeConfirmation(): void {
      this.showConfirmationModal = false;
      this.createStart = null;
      this.createEvent = null;
      this.bookingCreationErrorMsg = null;
    },
    /** Deletes the target event. */
    async deleteEvent() {
      // remove from local state
      this.events = this.events.filter(
        (event) => event.localId !== this.shownEventDetails?.localId
      );
      if (this.shownEventDetails?.externalId) {
        await deleteAssetBooking(this.shownEventDetails.externalId);
      }
      this.shownEventDetails = null;
      this.showDetailsModal = false;
    },
    // --------------------------------------------------------------------------------------------- //
    // -------------------------------------- Helper methods --------------------------------------- //
    // --------------------------------------------------------------------------------------------- //
    processEpochToDate(epochTime: number) {
      return convertEpochToISODateString(epochTime);
    },
    handleDateChange(val: string | null) {
      if (val && this.shownEventDetails) {
        this.shownEventDetails.start = convertISODateStringToEpoch(
          val,
          this.shownEventDetails.start
        );
        this.shownEventDetails.end = convertISODateStringToEpoch(
          val,
          this.shownEventDetails.end
        );
      }
    },
    /**
     * Converts `v-calendar` required epochTime to `v-time-picker` required HH:MM  time string.
     *
     * @param epochTime v-calendar event provided epochTime(milliseconds since epoch).
     * @returns time string in "HH:MM" format
     */
    processEpochToTime(epochTime: number) {
      return convertEpochTo24hrTime(epochTime);
    },
    /**
     * Converts `v-time-picker` required HH:MM  time string to `v-calendar` required epochTime.
     *
     * @param epochTime v-time-picker event provided time string in "HH:MM" format.
     * @returns epochTime (milliseconds since epoch).
     */
    processTimeToEpoch(val: string, type: "start" | "end") {
      if (this.createEvent && val) {
        if (type === "start") {
          this.createEvent.start = convert24hrTimeToEpoch(
            val,
            this.createEvent.start
          );
        }
        if (type === "end") {
          this.createEvent.end = convert24hrTimeToEpoch(
            val,
            this.createEvent.start
          );
        }
      }
    },
    /**
     * Handles `v-time-picker` period change event altering time by 12hrs either way.
     *
     * @param val `v-time-picker` event provided period string.
     * @param type which time needs to be edited.
     */
    processAmPmChange(val: "am" | "pm", type: "start" | "end") {
      if (this.createEvent && val) {
        // ASSUMES: behavior for vuetify time pickers is to only fire this event if it is a change so it can be assumed that if val is "am" then old val was "pm"
        if (val === "am") {
          // subtract 12hrs
          if (type === "start") {
            this.createEvent.start = this.createEvent.start - 43200000;
          }
          if (type === "end") {
            this.createEvent.end = this.createEvent.end - 43200000;
          }
        }
        if (val === "pm") {
          // ad 12hrs
          if (type === "start") {
            this.createEvent.start = this.createEvent.start + 43200000;
          }
          if (type === "end") {
            this.createEvent.end = this.createEvent.end + 43200000;
          }
        }
      }
    },
    /** Rounds time to the nearest 1 min. */
    roundTime(time: number, down = true) {
      const roundTo = 1; // minutes
      const roundDownTime = roundTo * 60 * 1000;
      return down
        ? time - (time % roundDownTime)
        : time + (roundDownTime - (time % roundDownTime));
    },
    /** Converts calendar `Tms` object to milliseconds. */
    toTime(tms: ExtendedCalendarTimestamp) {
      return new Date(
        tms.year,
        tms.month - 1,
        tms.day,
        tms.hour,
        tms.minute
      ).getTime();
    },
    /**
     * Gets slightly transparent color from event color.
     *
     * Note: only works for hex colors if using theme colors please get the
     * hex value from them first.
     */
    getEventColor(event: CalEvent) {
      const rgb = parseInt(event.color.substring(1), 16);
      const r = (rgb >> 16) & 0xff;
      const g = (rgb >> 8) & 0xff;
      const b = (rgb >> 0) & 0xff;
      return event === this.dragEvent
        ? `rgba(${r}, ${g}, ${b}, 0.7)`
        : event === this.createEvent
          ? `rgba(${r}, ${g}, ${b}, 0.7)`
          : event.color;
    },
    /** Randomly generates events for demo purposes. */
    async getEvents() {
      const events: CalEvent[] = [];
      const usedLocalIds: string[] = [];
      const directusFormatEvent = await fetchAssetBookingsByAssetID(
        this.assetId
      );
      if (directusFormatEvent) {
        events.push(
          ...directusFormatEvent.map((directusEvent) => ({
            name:
              directusEvent.user_id === this.userId ? "My Booking" : "Booked",
            color:
              directusEvent.user_id === this.userId
                ? (this.$vuetify.theme.themes.light.primary as string)
                : (this.$vuetify.theme.themes.light.secondary as string),
            start: DateTime.fromISO(directusEvent.start).toMillis(),
            end: DateTime.fromISO(directusEvent.end).toMillis(),
            timed: true,
            localId: generateUniqueLocalID(
              [...usedLocalIds, ...this.usedLocalIds],
              "calendar-event"
            ),
            assetId: this.assetId,
            assetType: this.assetType,
            externalId: directusEvent.id,
            userId:
              directusEvent.user_id === this.userId ? this.userId : undefined,
          }))
        );
      }
      this.events = events;
      this.usedLocalIds = usedLocalIds;
    },
    /** Returns a true if passed event relates to the current user. */
    isThisUsers(event: CalEvent): boolean {
      return this.userId === event.userId;
    },
    /** Returns true if passed event overlaps any of the events in the passed event list. */
    overlaps(event: CalEvent, eventsList: CalEvent[]): boolean {
      // filter out target event to compare with other events.
      const filteredEvents = eventsList.filter(
        (listEvent) => listEvent.localId !== event.localId
      );
      let overlaps = false;
      // iterate through passed eventsList.
      for (let index = 0; index < filteredEvents.length; index++) {
        // check if start is within iterated events time frame.
        if (
          event.start <= filteredEvents[index].end &&
          event.start >= filteredEvents[index].start
        ) {
          overlaps = true;
          break; // fail fast at first overlapping event.
        }
        // check if end is within iterated events time frame.
        if (
          event.end >= filteredEvents[index].start &&
          event.end <= filteredEvents[index].end
        ) {
          overlaps = true;
          break; // fail fast at first overlapping event.
        }
        // check if iterated events start is within target events time frame.
        if (
          filteredEvents[index].start <= event.end &&
          filteredEvents[index].start >= event.start
        ) {
          overlaps = true;
          break; // fail fast at first overlapping event.
        }
        // check if iterated events end is within target events time frame.
        if (
          filteredEvents[index].end >= event.start &&
          filteredEvents[index].end <= event.end
        ) {
          overlaps = true;
          break; // fail fast at first overlapping event.
        }
      }
      // most likely not overlapping if it reaches this point in the code block.
      return overlaps;
    },
    /**
     * Returns the title display string.
     *
     * Note: done as method as current version of typescript displays false error if done in template.
     */
    titleDisplayString(): string {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      if ((this.$refs.calendar as any)?.title)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        return (this.$refs.calendar as any)?.title; // note ts has trouble typing refs hence the explicit any.
      const today = DateTime.now();
      return today.monthLong + " " + today.year;
    },
    /**
     * Returns the Type display string.
     *
     * Note: done as method as current version of typescript displays false error if done in template.
     */
    typeDisplayString(type: string) {
      const typeToLabel = {
        month: "Month",
        week: "Week",
        day: "Day",
        "4day": "4 Days",
      };
      return typeToLabel[type as "month" | "week" | "day" | "4day"];
    },
    /** Converts a epoch time stamp to a human readable display string. */
    niceTime(epochTime: number): string {
      return DateTime.fromMillis(epochTime).toLocaleString(
        DateTime.TIME_SIMPLE
      );
    },
    niceDate(epochTime: number): string {
      return DateTime.fromMillis(epochTime).toLocaleString(DateTime.DATE_HUGE);
    },
    async attemptCreateEvent() {
      const errorMsg = "This slot is taken, please select another time";
      this.bookingCreationLoading = true;
      if (this.createEvent) {
        // check if end is before start
        if (this.createEvent.start > this.createEvent.end) {
          // error out
          this.bookingCreationErrorMsg = "End time is before start time";
          this.bookingCreationLoading = false;
          return;
        }

        if (this.overlaps(this.createEvent, this.events)) {
          // error out
          this.bookingCreationErrorMsg = errorMsg;
          this.bookingCreationLoading = false;
          return;
        }
        // compile event to directus format
        const startISO = DateTime.fromMillis(this.createEvent.start)
          .toUTC()
          .setZone("local")
          .toISO();
        const endISO = DateTime.fromMillis(this.createEvent.end)
          .toUTC()
          .setZone("local")
          .toISO();
        if (!startISO || !endISO || !this.userId) {
          // error out
          this.bookingCreationErrorMsg =
            "Whoops something went wrong! Please refresh and try again";
          this.bookingCreationLoading = false;
          return;
        }
        const formattedEvent: DirectusAssetBookingCreationData = {
          asset_id:
            typeof this.assetId === "number"
              ? this.assetId.toString()
              : this.assetId,
          asset_type: this.assetType,
          start: startISO,
          end: endISO,
          user_id: this.userId,
        };
        // create new event
        const res = await createAssetBooking(formattedEvent);
        if (!res) {
          // error out
          this.bookingCreationErrorMsg =
            "Whoops something went wrong! Please refresh and try again";
          this.bookingCreationLoading = false;
          return;
        }
        this.createEvent.externalId = res.id;
        this.addEvent();
        this.bookingCreationLoading = false;
        return;
      }
      // error out
      this.bookingCreationErrorMsg = errorMsg;
      this.bookingCreationLoading = false;
    },
    async attemptEditEvent() {
      const overLapsErrorMsg = "This slot is taken, please select another time";
      const cantUpdateErrorMsg = "This booking can't be updated";
      if (this.shownEventDetails) {
        if (!this.shownEventDetails.externalId) {
          // error out
          this.bookingCreationErrorMsg = cantUpdateErrorMsg;
          this.bookingCreationLoading = false;
          return;
        }
        // check if end is before start
        if (this.shownEventDetails.start > this.shownEventDetails.end) {
          // error out
          this.bookingCreationErrorMsg = "End time is before start time";
          this.bookingCreationLoading = false;
          return;
        }
        if (this.overlaps(this.shownEventDetails, this.events)) {
          // error out
          this.bookingCreationErrorMsg = overLapsErrorMsg;
          this.bookingCreationLoading = false;
          return;
        }
        // compare events and capture changes
        const detailsToUpdated: PartialObj<DirectusAssetBooking> = {};
        const oldBooking = this.events.find(
          (booking) => booking.localId === this.shownEventDetails?.localId
        );
        // compile event to directus format
        const startISO = DateTime.fromMillis(this.shownEventDetails.start)
          .toUTC()
          .setZone("local")
          .toISO();
        const endISO = DateTime.fromMillis(this.shownEventDetails.end)
          .toUTC()
          .setZone("local")
          .toISO();
        if (!startISO || !endISO) {
          // error out
          this.bookingCreationErrorMsg = cantUpdateErrorMsg;
          this.bookingCreationLoading = false;
          return;
        }
        if (!oldBooking) {
          // ASSUME: is all different.
          detailsToUpdated.start = startISO;
          detailsToUpdated.end = endISO;
        }
        if (oldBooking?.start !== this.shownEventDetails.start) {
          // Start time is different add it to details to be updated.
          detailsToUpdated.start = startISO;
        }
        if (oldBooking?.end !== this.shownEventDetails.end) {
          // End time is different add it to details to be updated.
          detailsToUpdated.end = startISO;
        }
        // check if there is anything to update
        if (!Object.keys(detailsToUpdated).length) {
          // error out
          this.bookingCreationErrorMsg = "Nothing to update";
          this.bookingCreationLoading = false;
          return;
        }
        // update directus
        const res = await updatedAssetBooking(
          typeof this.shownEventDetails.externalId === "number"
            ? this.shownEventDetails.externalId.toString()
            : this.shownEventDetails.externalId,
          detailsToUpdated
        );
        // check if update failed and indicate this to the user
        if (!res) {
          // error out
          this.bookingCreationErrorMsg =
            "Failed to update server, please refresh and try again";
          this.bookingCreationLoading = false;
          return;
        }
        // update local state
        this.events = [
          ...this.events.filter(
            (event) => event.localId !== this.shownEventDetails?.localId
          ),
          this.shownEventDetails,
        ];
        this.closeDetails();
      }
    },
  },
  mounted() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (this.$refs.calendar as any).checkChange();
  },
  components: {
    BookingConfirmationModal,
    BookingEditModal,
    ElevatedBtn,
    ElevatedBlockBtn,
    OutlinedBlockBtn,
  },
});
</script>
<style scoped lang="scss">
.v-event-draggable {
  padding-left: 6px;
}

.v-event-timed {
  user-select: none;
  -webkit-user-select: none;
}

.v-event-drag-bottom {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 4px;
  height: 4px;
  cursor: ns-resize;

  &::after {
    display: none;
    position: absolute;
    left: 50%;
    height: 4px;
    border-top: 1px solid white;
    border-bottom: 1px solid white;
    width: 16px;
    margin-left: -8px;
    opacity: 0.8;
    content: "";
  }

  &:hover::after {
    display: block;
  }
}

@-webkit-keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    -webkit-transform: translateY(0);
  }
  40% {
    -webkit-transform: translateY(-30px);
  }
  60% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}
.animate-collision {
  -webkit-animation-duration: 800ms;
  animation-duration: 800ms;
  -webkit-animation-name: bounce;
  animation-name: bounce;
}
</style>
