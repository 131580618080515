<template>
  <div class="pb-3">
    <v-card flat class="background rounded-lg d-flex align-center pa-2 mb-2">
      <v-icon color="primary"> mdi-map-marker-radius-outline </v-icon>
      <span class="pl-2">
        {{ Math.round(drivingDistance / 1000) }}km
        {{ ferryTime > 0 ? "driving " : "" }}to next stop
      </span>
    </v-card>
    <v-card flat class="background rounded-lg d-flex align-center pa-2 mb-2">
      <v-icon color="primary"> mdi-progress-clock </v-icon>
      <span class="pl-2"> Drive for {{ niceDuration(travelTime) }} </span>
    </v-card>
    <v-card
      flat
      class="background rounded-lg d-flex align-center pa-2 mb-2"
      v-if="ferryTime > 0"
    >
      <v-icon color="primary"> mdi-ferry </v-icon>
      <span class="pl-2"> Ferry for {{ niceDuration(ferryTime) }} </span>
    </v-card>

    <v-card flat class="background rounded-lg d-flex align-center pa-2 mb-5">
      <v-icon color="primary"> mdi-battery-30 </v-icon>
      <span class="pl-2">
        Use
        {{
          chargeUsedTraveling * 100 <= 1
            ? "less than 1"
            : Math.round(chargeUsedTraveling * 100)
        }}% of charge
      </span>
    </v-card>

    <div
      class="grey lighten-2 rounded-pill"
      style="position: relative; width: 100%; height: 12px; overflow: hidden"
    >
      <div
        class="rounded-pill"
        :style="`position: absolute; left: 0; top: 0; width: ${startingSoC * 100}%; height: 12px; background-color: ${darkBlue}`"
      ></div>
      <div
        class="primary rounded-pill"
        :style="`position: absolute; left: 0; top: 0; width: ${endingSoC * 100}%; height: 12px`"
      ></div>
    </div>
  </div>
</template>

<script lang="ts">
import Vue from "vue";
import { powerTripDarkBlue } from "@/logic/data/const";
import { getNiceDuration } from "@/logic/utils/timeUtils";
export default Vue.extend({
  name: "ItineraryStepTravelDisplay",
  props: {
    startingSoC: Number,
    endingSoC: Number,
    chargeUsedTraveling: Number,
    drivingDistance: Number,
    ferryTime: Number,
    travelTime: Number,
  },
  data() {
    return {
      darkBlue: powerTripDarkBlue,
    };
  },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
  },
});
</script>
