<template>
  <v-btn icon @click="back">
    <v-icon> mdi-chevron-left </v-icon>
  </v-btn>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "BackBtn",
  props: {
    emit: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    back(): void {
      if (this.emit) {
        this.$emit("click");
      } else {
        this.$router.back();
      }
    },
  },
});
</script>
