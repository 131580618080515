import Charger from "../classes/charger_classes/charger";
import EVModel from "../classes/vehicle_classes/evModel";
import Fleet from "../classes/fleet";
import NearbyController from "../classes/nearbyController";
import NetworkBranding from "../classes/charger_classes/networkBranding";
import User from "../classes/user_classes/user";
import Vehicle from "../classes/vehicle_classes/vehicle";
import { ManagedContent } from "../types/directus_types";
import Driver from "../classes/user_classes/driver";
import Coordinate from "../classes/common_classes/coordinate";
import FavouriteLocation from "../classes/favouriteLocation";
import Trip from "../classes/trip_classes/trip";

/** Data shape for the global state stores state. */
export interface State {
  // Class object data.

  /** The `User` class object for the current user. */
  user?: User;

  /** The users `GeoLocation` data. */
  userGeoIPData?: Coordinate;

  /** The `Fleet` class object the current user is apart of. */
  fleet?: Fleet;

  /** The users `Driver` class object. */
  driver?: Driver;

  /** List of `Charger` class object the current user has access to. */
  chargers: Charger[];

  /** List of `Trip` class objects the user has saved or created this session. */
  trips: Trip[];

  /** List of EV models currently supported by EV Nav. */
  evModels: EVModel[];

  /** full list of charging station network branding. */
  networkBranding: NetworkBranding[];

  /** List of `Vehicle` class objects. */
  vehicles: Vehicle[];

  /** Nearby Charger Data. */
  nearbyChargersData?: NearbyController;

  /** Optimiser managed content. */
  managedContent: ManagedContent[];

  /** List of `favouriteLocation` class objects. */
  favLocations: FavouriteLocation[];

  // Ui viewing object data selections.

  /** the local id of the currently selected trip if there is one. */
  selectedTrip?: string;

  /** the local id of the currently selected vehicle if there is one. */
  selectedVehicle?: string;

  /** the local id of the currently fleet vehicle if being viewed if there is one. */
  viewedFleetVehicle?: string;

  /** the CDBID of the currently selected charger if there is one. */
  selectedCharger?: string;

  /** the local id of the currently selected trip location if there is one. */
  selectedLocation?: string;

  /** the local id of the trip currently queued for deletion if there is one. */
  queuedTripForDelete?: string;

  // async statuses.

  /** The status for any uploading an image to directus async function. */
  updateImageStatus?: AsyncStatus;

  /** The status for deleting a trip locally or in directus or both. */
  deleteTripStatus?: AsyncStatus;

  /** The status of the authorisation process. */
  authStatus?: AsyncStatus;

  /** The status of the route planning operation for the current trip. */
  routePlanningStatus?: AsyncStatus;

  /** The status of the current async trip saving operation. */
  tripSavingStatus?: AsyncStatus;

  /** The status of the current updating user/driver data async operation. */
  updatingUserStatus?: AsyncStatus;

  /** Ui flag representing if the nearby api call async operation is in process. */
  nearbyFetchingFlag: boolean;

  /** Ui flag representing if the radar api call async operation is in process. */
  radarFetchingFlag: boolean;

  /** The status of the current async vehicle saving operation. */
  vehicleSavingStatus?: AsyncStatus;

  /** The status of the current async settings saving operation. */
  settingsSavingStatus?: "PROCESSING" | "SUCCESS" | "FAILED";

  // Ui flags.

  /** Ui flag representing if the users JWT is being checked or not. */
  checkingToken: boolean;

  /** Ui flag representing if the user object is currently being fetched or not. */
  userFetchingFlag: boolean;

  /** Ui flag representing if the fleet object is currently being fetched or not. */
  fleetFetchingFlag: boolean;

  /** Ui flag representing if the charger objects are currently being fetched or not. */
  chargerFetchingFlag: boolean;

  /** Ui flag representing if the trip objects are currently being fetched or not. */
  tripFetchingFlag: boolean;

  /** Ui flag representing if the settings object is currently being fetched or not. */
  settingsFetchingFlag: boolean;

  /** Ui flag representing if the user has completed authorization or not. */
  authorizedFlag: boolean;

  /** Ui flag representing if the currently selected trip object is in a planning state or not. */
  routePlanningFlag: boolean;

  /** Ui flag representing if login model should be displayed or not. */
  showLogin: boolean;

  /** global state used for conditionally rendering content pages in the `MainDialog` component. */
  mainDialogContent?: MainDialogContent;

  /** global state used for conditionally rendering content pages in the `SecondaryDialog` component. */
  secondaryDialogContent?: SecondaryDialogContent;

  /** global state used for conditionally rendering content pages in the `SecondaryDialog` component. */
  tertiaryDialogContent?: TertiaryDialogContent;

  /** global state used for conditionally rendering content pages in the `Dashboard` component. */
  dashboardDialogContent?: DashboardDialogContent;

  /** Ui flag representing if to display all chargers along a route not just the planned stops. */
  displayAllChargersAlongRoute: boolean;

  /** Ui flag representing if to display the longest leap on a route. */
  displayLongestStep: boolean;

  /** Charging stations search criteria. */
  chargersSearchCriteria?: string;

  /** Vehicles search criteria. */
  vehiclesSearchCriteria?: string;

  /** UI flag representing the show public AC chargers filter. */
  showNearbyChargersOnly: boolean;

  /** UI flag representing the show private AC chargers filter. */
  showPrivateACChargers: boolean;

  /** UI flag representing the show private DC chargers filter. */
  showPrivateDCChargers: boolean;

  /** UI flag representing the show public AC chargers filter. */
  showPublicACChargers: boolean;

  /** UI flag representing the show public AC chargers filter. */
  showPublicDCChargers: boolean;

  /** Ui flag representing if to display all fleet vehicles on the map. */
  displayConnectedVehicles: boolean;

  /** UI flag for other components to know central point of where the user has panned the map to. */
  pannedCenter?: { lat: number; lng: number };

  /** UI flag for other components to know when the info panel is animating. */
  infoPanelAnimation?: "slide-left" | "slide-right";

  // Settings.

  /** The Users related `Optimiser_Settings` directus collection record id. */
  settingsTableID?: number;

  /** Users default minimum charge an EV can reach during a trip.
   *
   * NOTE: can be changed per trip.
   */
  SOCMin: number;

  /** Users default maximum charge an EV can reach during a trip.
   *
   * NOTE: can be changed per trip.
   */
  SOCMax: number;

  /** Users default starting charge for beginning an EV trip.
   *
   * NOTE: can be changed per trip.
   */
  SOCAct: number;

  /** Users default arriving charge an EV should have at the end of a trip.
   *
   * NOTE: can be changed per trip.
   */
  SOCEnd: number;

  /** The cost of electricity for public charging in NZD per kWh. */
  defaultPublicCostPerKWh: number;

  /** Additional cost for public fast charging above `defaultPublicCostPerKWh` in NZD per min. */
  defaultCostPerMinDC: number;

  /** Electricity charge for private access in NZD per kW per hour. */
  defaultHomeCostPerKWh: number;

  /** User selected networks override. */
  networkSelectionOverride?: string[];

  /** The number of additional kgs or carry weight. */
  extraWeight?: number;

  /** The % slower or faster a user drives on average. */
  relativeSpeed: number;

  /** cost of petrol per liter in NZD. */
  petrolCostPerLitre: number;

  /** cost of petrol per KM traveled in NZD. */
  petrolKmPerLitre: number;

  /** cost of diesel per liter in NZD. */
  dieselCostPerLitre: number;

  /** cost of diesel per KM traveled in NZD. */
  dieselKmPerLitre: number;

  /** Road user chargers in NZD per thousand km for diesel vehicles. */
  dieselRoadUserCharges: number;

  /** Road user chargers in NZD per thousand km for petrol vehicles. */
  petrolRoadUserCharges: number;

  /** Road user chargers in NZD per thousand km for electric vehicles. */
  electricRoadUserCharges: number;

  /** Estimated cost per year of maintenance on the users current vehicle in NZD. */
  perAnnumMaintenanceCurrentV: number;

  /** Estimated cost per year of maintenance if the user had an EV in NZD. */
  perAnnumMaintenanceEV: number;

  /** EV savings calculations to be calculated vs type of vehicle. */
  calcVs: CalcVsType;

  /** Drivers acceleration adjustment. */
  acceleration: number;

  /** The UUID for the linked directus instances `Saved_Trips` folder. */
  savedTripsFolderUUID?: string;

  /** The directus ID of the user selected vehicle. */
  selectedVehicleDirectusID?: string | number;

  /** the number of passengers to be included by default in trip planning.*/
  passengers: number;
}

/** enum representing the mutations available on the store. */
export enum MutationTypes {
  // Class object data mutators.
  setUser = "setUser",
  setFleet = "setFleet",
  setChargers = "setChargers",
  updateIndividualCharger = "updateIndividualCharger",
  setTrips = "setTrips",
  updateIndividualTrip = "updateIndividualTrip",
  setNetworkBranding = "setNetworkBranding",
  setEvModels = "setEvModels",
  setUserGeoIPData = "setUserGeoIPData",
  updateIndividualDriver = "updateIndividualDriver",
  setVehicles = "setVehicles",
  updateIndividualVehicle = "updateIndividualVehicle",
  addIndividualVehicle = "addIndividualVehicle",
  setNearbyChargersData = "setNearbyChargersData",
  setManagedContent = "setManagedContent",
  setDriver = "setDriver",
  setFavLocations = "setFavLocations",
  updateIndividualFavLocation = "updateIndividualFavLocation",
  deleteFavLocation = "deleteFavLocation",
  // Ui viewing object data selection mutators.
  setSelectedCharger = "setSelectedCharger",
  setSelectedTrip = "setSelectedTrip",
  setSelectedVehicle = "setSelectedVehicle",
  setSelectedLocation = "setSelectedLocation",
  setQueuedTripForDelete = "setQueuedTripForDelete",
  setViewedFleetVehicle = "setViewedFleetVehicle",
  // async statuses.
  setTripSavingStatus = "setTripSavingStatus",
  setUpdateImageStatus = "setUpdateImageStatus",
  setDeleteTripStatus = "setDeleteTripStatus",
  setAuthStatus = "setAuthStatus",
  setRoutePlanningStatus = "setRoutePlanningStatus",
  setUpdatingUserStatus = "setUpdatingUserStatus",
  setNearbyFetchingFlag = "setNearbyFetchingFlag",
  setRadarFetchingFlag = "setRadarFetchingFlag",
  setVehicleSavingStatus = "setVehicleSavingStatus",
  setSettingsSavingStatus = "setSettingsSavingStatus",
  // Ui flag mutators.
  setSettingsFetchingFlag = "setSettingsFetchingFlag",
  setFleetFetchingFlag = "setFleetFetchingFlag",
  setChargerFetchingFlag = "setChargerFetchingFlag",
  setTripFetchingFlag = "setTripFetchingFlag",
  setCheckingToken = "setCheckingToken",
  setShowLogin = "setShowLogin",
  setUserFetchingFlag = "setUserFetchingFlag",
  setAuthorizedFlag = "setAuthorizedFlag",
  setRoutePlanningFlag = "setRoutePlanningFlag",
  setMainDialogContent = "setMainDialogContent",
  setSecondaryDialogContent = "setSecondaryDialogContent",
  setTertiaryDialogContent = "setTertiaryDialogContent",
  setDashboardDialogContent = "setDashboardDialogContent",
  setDisplayAllChargersAlongRoute = "setDisplayAllChargersAlongRoute",
  setChargersSearchCriteria = "setChargersSearchCriteria",
  setVehiclesSearchCriteria = "setVehiclesSearchCriteria",
  setShowNearbyChargersOnly = "setShowNearbyChargersOnly",
  setShowPrivateACChargers = "setShowPrivateACChargers",
  setShowPrivateDCChargers = "setShowPrivateDCChargers",
  setShowPublicACChargers = "setShowPublicACChargers",
  setShowPublicDCChargers = "setShowPublicDCChargers",
  setPannedCenter = "setPannedCenter",
  setInfoPanelAnimation = "setInfoPanelAnimation",
  // Settings mutators.
  setSettingsTableID = "setSettingsTableID",
  setSOCMax = "setSOCMax",
  setSOCMin = "setSOCMin",
  setSOCAct = "setSOCAct",
  setSOCEnd = "setSOCEnd",
  setDefaultPublicCostPerKWh = "setDefaultPublicCostPerKWh",
  setDefaultHomeCostPerKWh = "setDefaultHomeCostPerKWh",
  setDefaultCostPerMinDC = "setDefaultCostPerMinDC",
  setDisplayLongestStep = "setDisplayLongestStep",
  setDisplayConnectedVehicles = "setDisplayConnectedVehicles",
  setNetworkSelectionOverride = "setNetworkSelectionOverride",
  setPetrolCostPerLitre = "setPetrolCostPerLitre",
  setPetrolKmPerLitre = "setPetrolKmPerLitre",
  setDieselCostPerLitre = "setDieselCostPerLitre",
  setDieselKmPerLitre = "setDieselKmPerLitre",
  setDieselRoadUserCharges = "setDieselRoadUserCharges",
  setPetrolRoadUserCharges = "setPetrolRoadUserCharges",
  setElectricRoadUserCharges = "setElectricRoadUserCharges",
  setPerAnnumMaintenanceCurrentV = "setPerAnnumMaintenanceCurrentV",
  setPerAnnumMaintenanceEV = "setPerAnnumMaintenanceEV",
  setCalcVs = "setCalcVs",
  setExtraWeight = "setExtraWeight",
  setRelativeSpeed = "setRelativeSpeed",
  setAcceleration = "setAcceleration",
  setSavedTripsFolderUUID = "setSavedTripsFolderUUID",
  setSelectedVehicleDirectusID = "setSelectedVehicleDirectusID",
  setPassengers = "setPassengers",
}

/** enum representing the actions available on the store. */
export enum ActionTypes {
  fetchData = "fetchData",
  getFleetData = "getFleetData",
  getDriverData = "getDriverData",
  getChargersAndNetworkData = "getChargersAndNetworkData",
  getTripsData = "getTripsData",
  getFavLocations = "getFavLocations",
  addModelsToFleetVehicles = "addModelsToFleetVehicles",
  addVehiclesToTrips = "addVehiclesToTrips",
  clearData = "clearData",
  // createNewTrip = "createNewTrip",
  refreshData = "refreshData",
  updateVehiclesData = "updateVehiclesData",
  getSettingsData = "getSettingsData",
  updatedSettingsData = "updatedSettingsData",
  deleteTrip = "deleteTrip",
  showPlannedTrip = "showPlannedTrip",
  login = "login",
  logoutUser = "logoutUser",
  forgotPassword = "forgotPassword",
  updateUser = "updateUser",
  saveVehicle = "saveVehicle",
  createGenericEVsFromModels = "createGenericEVsFromModels",
  // recalculateTrip = "recalculateTrip",
  selectVehicle = "selectVehicle",
  saveTrip = "saveTrip",
  uploadStationImage = "uploadStationImage",
  clearStatus = "clearStatus",
  nameAndSaveTrip = "nameAndSaveTrip",
  uploadVehicleImage = "uploadVehicleImage",
  showAllChargersOnRoute = "showAllChargersOnRoute",
  fetchNearbyChargersData = "fetchNearbyChargersData",
  getManagedContent = "getManagedContent",
  checkSelectedVehicle = "checkSelectedVehicle",
  fetchDirectusSettingsData = "fetchDirectusSettingsData",
  showTrip = "showTrip",
}

/** enum representing the getters available on the store. */
export enum GettersTypes {
  selectedTripData = "selectedTripData",
  selectedVehicleData = "selectedVehicleData",
  selectedChargerData = "selectedChargerData",
  selectedChargerBranding = "selectedChargerBranding",
  selectedLocationData = "selectedLocationData",
  filterChargingStations = "filterChargingStations",
  currentDriver = "currentDriver",
  queuedForDeleteTripData = "queuedForDeleteTripData",
  connectedVehicles = "fleetVehicles",
  genericVehicles = "genericVehicles",
  customVehicles = "customVehicles",
  selectedTripStats = "selectedTripStats",
  getOneYearProjections = "getOneYearProjections",
  viewedFleetVehicleData = "viewedFleetVehicleData",
  displayedVehicle = "displayedVehicle",
  savedTrips = "savedTrips",
  unsavedTrips = "unsavedTrips",
  fetching = "fetching",
  getVehicleById = "getVehicleById",
}

/** List of commonly used const for pages that are available to be viewed in the `MainDialog` component. */
export enum MainDialogContent {
  CHARGING_STATION_DETAILS = "charging station details",
  VEHICLE_GALLERY = "vehicle gallery",
  VEHICLE_GALLERY_CUSTOM = "vehicle gallery custom",
  VEHICLE_GALLERY_CONNECTED = "vehicle gallery connected",
  VEHICLE_GALLERY_GENERIC = "vehicle gallery generic",
  FLEET_VEHICLE_DETAILS = "fleetVehicleDetails",
  ADD_VEHICLE = "add vehicle",
  VEHICLE_SETTINGS = "vehicle settings",
  VEHICLE_BOOKING = "vehicle booking",
}

/** List of commonly used const for pages that are available to be viewed in the `SecondaryDialog` component. */
export enum SecondaryDialogContent {
  STORED_TRIP_DELETE_CONFIRMATION = "storedTripDeleteConfirmation",
  NETWORK_SETTINGS = "networkSettings",
  BATTERY_HEALTH_HELP = "batteryHealthHelp",
}

/** List of supported charger filter options */
export enum ChargerFilters {
  NO_PRIVATE_AC = "noPrivateAC",
  NO_PRIVATE_DC = "noPrivateDC",
  NO_PUBLIC_AC = "noPublicAC",
  NO_PUBLIC_DC = "noPublicDC",
}

/** List of commonly used const for pages that are available to be viewed in the `TertiaryDialog` component. */
export enum TertiaryDialogContent {
  LOCATION_DETAILS = "location details",
}

export type DashboardDialogContent =
  | "home"
  | "account"
  | "vehicle-details"
  | "fleet-vehicles"
  | "locations-manager"
  | "defaults";

/** List of supported calculate savings vs X supported types. */
export enum CalcVsType {
  PETROL = "PETROL",
  DIESEL = "DIESEL",
}

/** List of async operation statuses. */
export enum AsyncStatus {
  PROCESSING = "PROCESSING",
  FAILED = "FAILED",
  SUCCESS = "SUCCESS",
}

/** Expected payload for the upload vehicle image action. */
export interface UploadVehicleImagePayload {
  vehicle: Vehicle;
  imageFile: File;
}

export enum ManagedContentNames {
  SoHToolTip = "Range adjustment and battery health",
}

export enum VehicleGalleryType {
  CONNECTED_VEHICLES = "CONNECTED_VEHICLES",
  CUSTOM_VEHICLES = "CUSTOM_VEHICLES",
  GENERIC_VEHICLES = "GENERIC_VEHICLES",
}

export interface BatchUpdatableSettings {
  passengers?: number;
  SOCMax?: number;
  SOCMin?: number;
  SOCAct?: number;
  SOCEnd?: number;
  relativeSpeed?: number;
  acceleration?: number;
  extraWeight?: number;
  petrolCostPerLitre?: number;
  dieselCostPerLitre?: number;
  petrolKmPerLitre?: number;
  dieselKmPerLitre?: number;
  petrolRoadUserCharges?: number;
  dieselRoadUserCharges?: number;
  defaultHomeCostPerKWh?: number;
  defaultPublicCostPerKWh?: number;
  defaultCostPerMinDC?: number;
  electricRoadUserCharges?: number;
  calcVs?: CalcVsType;
  perAnnumMaintenanceCurrentV?: number;
  perAnnumMaintenanceEV?: number;
}
