<template>
  <v-card flat class="background">
    <AvatarRow
      v-if="user"
      :avatarSrc="avatarSrc"
      :emitViewAccount="emitViewAccount"
      :emitViewAllVehicles="emitViewAllVehicles"
      :emitViewVehicle="emitViewVehicle"
      :emitViewDefaults="emitViewDefaults"
      :handleLogout="handleLogout"
      :user="user"
      class="my-5"
    />
    <v-row no-gutters align="center" v-else class="my-5">
      <v-skeleton-loader type="avatar" class="mr-3" />
      <v-skeleton-loader type="text, text" width="30%" />
      <v-spacer />
    </v-row>
    <v-card class="mb-3 rounded-lg background pa-5" elevation="1">
      <v-row
        no-gutters
        v-if="company"
        class="flex-column align-start align-md-center flex-md-row"
      >
        <v-row
          v-if="$vuetify.breakpoint.smAndDown"
          no-gutters
          align="center"
          style="width: 100%"
        >
          <img v-if="logoSrc" :src="logoSrc" width="auto" height="50px" />
          <v-spacer />
          <v-btn icon @click="emitViewAllVehicles">
            <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
          </v-btn>
        </v-row>
        <img
          v-if="$vuetify.breakpoint.mdAndUp && logoSrc"
          :src="logoSrc"
          width="auto"
          height="50px"
        />
        <v-card-title v-if="company">
          {{ company.name ?? "Unnamed fleet" }}
        </v-card-title>
        <v-spacer />
        <ElevatedBtn
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="emitViewAllVehicles"
        >
          View fleet details
        </ElevatedBtn>
      </v-row>
      <v-row
        v-else
        no-gutters
        class="flex-column align-start align-md-center flex-md-row"
      >
        <v-skeleton-loader
          type="image"
          width="150px"
          height="50px"
          class="mr-3 mb-3 mb-md-0"
        />
        <v-skeleton-loader type="text, text" width="30%" />
        <v-spacer />
        <v-skeleton-loader v-if="$vuetify.breakpoint.mdAndUp" type="button" />
      </v-row>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Driver from "@/logic/classes/user_classes/driver";
import Fleet from "@/logic/classes/fleet";
import { ActionTypes, GettersTypes, State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";
import AvatarRow from "./AvatarRow.vue";

/** Vue Component: renders the user profile. To be used in the 'TripsContent' component. */
export default Vue.extend({
  name: "ProfileCard",
  components: { ElevatedBtn, AvatarRow },
  methods: {
    /**
     * Closes the profile card and logs the user out.
     */
    handleLogout() {
      this.$emit("logout");
      this.$store.dispatch(ActionTypes.logoutUser);
    },
    /**
     * Emits the 'view-account' event to the parent component.
     * Used to navigate to the user account page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAccount(): void {
      this.$emit("view-account");
    },
    /**
     * Emits the 'view-vehicle' event to the parent component.
     * Used to navigate to the user vehicle page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewVehicle(): void {
      this.$emit("view-vehicle");
    },
    /**
     * Emits the 'view-vehicles' event to the parent component.
     * Used to navigate to the user fleet page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAllVehicles(): void {
      this.$emit("view-vehicles");
    },
    /**
     * Emits the 'view-defaults' event to the parent component.
     * Used to navigate to the user trip planning defaults page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewDefaults(): void {
      this.$emit("view-defaults");
    },
  },
  computed: {
    /**
     * Returns the src path for the users profile picture if they have one.
     *
     * @return {string | undefined} The src path for the users profile picture if they have one, undefined otherwise.
     */
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    /**
     * Returns the src path for this fleets logo pic if they have one.
     *
     * @return {string | undefined} The src path for this fleets logo pic if they have one, undefined otherwise.
     */
    logoSrc(): string | undefined {
      const fleet: Fleet | undefined = (this.$store.state as State).fleet;
      return fleet?.logoSrc;
    },
    ...mapGetters({
      driver: GettersTypes.currentDriver,
      vehicle: GettersTypes.selectedVehicleData,
    }),
    ...mapState({
      authenticated: (state: unknown): boolean =>
        (state as State).authorizedFlag,
      user: (state: unknown) => (state as State).user,
      company: (state: unknown): Fleet | undefined => (state as State).fleet,
    }),
  },
  data() {
    return {
      showMenu: false,
    };
  },
});
</script>
