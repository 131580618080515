<template>
  <InfoPanelCardWrapper
    style="position: relative; padding-bottom: 100px !important"
  >
    <!-- breadcrumb section -->
    <v-row class="flex-nowrap" align="center" no-gutters>
      <TripsHomeBtn />
      <v-breadcrumbs
        :items="items"
        class="flex-md-nowrap pl-2"
        style="max-width: 90%"
      >
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item
            :to="item.to"
            :disabled="item.disabled"
            class="text-truncate"
          >
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
      </v-breadcrumbs>
    </v-row>
    <!-- locations list section -->
    <LocationsListRow
      :addressData="originAddressData"
      id="origin"
      @update="updateAddress"
      type="origin"
      readonly
    />
    <LocationsListRow
      :addressData="destinationAddressData"
      id="destination"
      @update="updateAddress"
      type="destination"
      readonly
    />
    <!-- location details section -->
    <v-card class="rounded-lg px-4 mb-3 mt-10">
      <v-card-title> Departing charge</v-card-title>
      <v-card-text>
        <v-slider
          :label="Math.round(startingCharge * 100) + '%'"
          :value="Math.round(startingCharge * 100)"
          min="1"
          max="100"
          track-color="grey lighten-2"
          @input="updateStartingSOC"
          @end="updateRouteQuery"
        />
      </v-card-text>
    </v-card>
    <v-card class="rounded-lg px-4 mb-3">
      <v-card-title> Arrival charge</v-card-title>
      <v-card-text>
        <v-slider
          :label="Math.round(endingCharge * 100) + '%'"
          :value="Math.round(endingCharge * 100)"
          min="1"
          max="100"
          track-color="grey lighten-2"
          @input="updateEndingSOC"
          @end="updateRouteQuery"
        />
      </v-card-text>
    </v-card>

    <v-card
      color="background"
      class="mb-10 pa-4 pa-md-2 rounded-lg"
      flat
      style="box-shadow: inset 3px 3px 3px 0px rgba(0, 0, 0, 0.05) !important"
    >
      <v-row
        no-gutters
        justify="space-between"
        :class="$vuetify.breakpoint.smAndDown ? 'flex-column' : ''"
      >
        <v-card
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'width: 100%'
              : 'width: calc(50% - 5px)'
          "
          class="rounded px-4"
          :class="$vuetify.breakpoint.smAndDown ? 'mb-3' : ''"
          :disabled="!!(endingDate || endingTime)"
          :flat="!!(endingDate || endingTime)"
          :color="!!(endingDate || endingTime) ? 'background' : undefined"
        >
          <v-card-title> Departure </v-card-title>
          <v-card-text>
            <DatePickerInput
              :initialValue="startingDate"
              identifier="Departure"
              label="Date"
              @update="updateStartingDate"
            />
            <TimePickerInput
              :initialValue="startingTime"
              identifier="Departure"
              label="Time"
              @update="updateStartingTime"
            />
          </v-card-text>
        </v-card>
        <v-card
          :style="
            $vuetify.breakpoint.smAndDown
              ? 'width: 100%'
              : 'width: calc(50% - 5px)'
          "
          class="rounded px-4"
          :disabled="!!(startingDate || startingTime)"
          :flat="!!(startingDate || startingTime)"
          :color="!!(startingDate || startingTime) ? 'background' : undefined"
        >
          <v-card-title> Arrival </v-card-title>
          <v-card-text>
            <DatePickerInput
              :initialValue="endingDate"
              identifier="Arrival"
              label="Date"
              @update="updateEndingDate"
            />
            <TimePickerInput
              :initialValue="endingTime"
              identifier="Arrival"
              label="Time"
              @update="updateEndingTime"
            />
          </v-card-text>
        </v-card>
      </v-row>
    </v-card>

    <!-- extra details section -->
    <v-card-title class="pl-0"> Extra Details </v-card-title>
    <ExtraDetailsCard
      @updateWeight="updateWeight"
      @updatePassengers="updatePassengers"
      @updateRange="updateRange"
    />
    <!-- buttons section -->
    <div
      class="px-7"
      :class="classes"
      style="
        position: fixed;
        top: calc(100dvh - 50px);
        left: 0;
        width: 40%;
        max-width: 600px;
      "
      :style="$vuetify.breakpoint.smAndDown ? 'width: 100%;' : ''"
    >
      <v-row no-gutters>
        <v-col cols="5" md="4" class="pr-1">
          <div class="background rounded-lg">
            <ElevatedBlockBtn color="#dadada" darkText @click="toItinerary">
              Skip to itinerary
            </ElevatedBlockBtn>
          </div>
        </v-col>
        <v-col cols="7" md="8" class="pl-1">
          <div class="background rounded-lg">
            <ElevatedBlockBtn @click="toAddStops" class="mb-3">
              Add Stops
            </ElevatedBlockBtn>
          </div>
        </v-col>
      </v-row>
    </div>
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import ElevatedBlockBtn from "../components/ui-elements/buttons/ElevatedBlockBtn.vue";
import InfoPanelCardWrapper from "../components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { AddressAutocompleteInputUpdateObj } from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import queryValueToString from "@/logic/utils/queryValueToString";
import queryValueToNumber from "@/logic/utils/queryValueToNumber";
import { Dictionary, RawLocation } from "vue-router/types/router";
import LocationsListRow from "../components/trips/planning/LocationsListRow.vue";
import ExtraDetailsCard from "@/ui/components/trips/planning/ExtraDetailsCard.vue";
import { State } from "@/logic/store/store_types";
import to2DP from "@/logic/utils/to2DP";
import DatePickerInput, {
  DatePickerInputUpdateObj,
} from "../components/ui-elements/inputs/DatePickerInput.vue";
import TimePickerInput, {
  TimePickerInputUpdateObj,
} from "../components/ui-elements/inputs/TimePickerInput.vue";
import TripsHomeBtn from "../components/ui-elements/buttons/TripsHomeBtn.vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "PlanningAddDetailsView",
  components: {
    ElevatedBlockBtn,
    InfoPanelCardWrapper,
    LocationsListRow,
    ExtraDetailsCard,
    DatePickerInput,
    TimePickerInput,
    TripsHomeBtn,
  },
  data() {
    return {
      // address list section data
      originAddressData: undefined as processedAddressObj | undefined,
      destinationAddressData: undefined as processedAddressObj | undefined,
      // address details section data
      startingCharge: 100 as number,
      startingDate: undefined as string | undefined,
      startingTime: undefined as string | undefined,
      endingCharge: 20 as number,
      endingDate: undefined as string | undefined,
      endingTime: undefined as string | undefined,
      // extra details section data
      socMax: undefined as number | undefined,
      socMin: undefined as number | undefined,
      passengerCount: undefined as number | undefined,
      extraWeight: undefined as number | undefined,
    };
  },
  computed: {
    items(): { text: string; to: RawLocation }[] {
      const useSmall = this.$vuetify.breakpoint.smAndDown;

      if ((this.$store.state as State).selectedTrip)
        return [
          {
            text: useSmall ? "Destination" : "Add Destination",
            to: { name: RouteNames.tripAddDestination },
          },
          {
            text: useSmall ? "Origin" : "Add Origin",
            to: { name: RouteNames.tripAddOrigin },
          },
          {
            text: useSmall ? "Details" : "Add Details",
            to: { name: RouteNames.tripAddDetails },
          },
          {
            text: useSmall ? "Stops" : "Add Stops",
            to: { name: RouteNames.tripAddStops },
          },
          {
            text: useSmall ? "Vehicle" : "Select Vehicle",
            to: { name: RouteNames.tripSelectVehicle },
          },
          {
            text: "Itinerary",
            to: { name: RouteNames.tripItinerary },
          },
        ];
      return [
        {
          text: useSmall ? "Destination" : "Add Destination",
          to: { name: RouteNames.tripAddDestination },
        },
        {
          text: useSmall ? "Origin" : "Add Origin",
          to: { name: RouteNames.tripAddOrigin },
        },
        {
          text: useSmall ? "Details" : "Add Details",
          to: { name: RouteNames.tripAddDetails },
        },
      ];
    },
    ...mapState({
      animation: (state: unknown) => {
        return (state as State).infoPanelAnimation;
      },
    }),
    classes() {
      if (this.animation == "slide-left")
        return "pwt-info-panel-right-to-left-animation";
      if (this.animation == "slide-right")
        return "pwt-info-panel-left-to-right-animation";
      return "";
    },
  },
  methods: {
    // Routing
    pushRoute(
      routeName: RouteNames,
      query:
        | Dictionary<string | (string | null)[] | null | undefined>
        | undefined = undefined
    ) {
      this.$router.push({ name: routeName, query: query });
    },
    toAddStops() {
      this.pushRoute(RouteNames.tripAddStops, this.$route.query);
    },
    toItinerary() {
      this.pushRoute(RouteNames.tripItinerary, this.$route.query);
    },
    updateAddress(address: AddressAutocompleteInputUpdateObj) {
      if (address.id === "origin") {
        this.originAddressData = address.addressData;
        this.updateRouteQuery();
      } else if (address.id === "destination") {
        this.destinationAddressData = address.addressData;
        this.updateRouteQuery();
      }
    },
    updateStartingSOC(val: number) {
      this.startingCharge = to2DP(val / 100);
    },
    updateStartingDate(val: DatePickerInputUpdateObj) {
      this.startingDate = val.date ?? undefined; // prevent empty strings being set as state
      this.updateRouteQuery();
    },
    updateStartingTime(val: TimePickerInputUpdateObj) {
      this.startingTime = val.time ?? undefined; // prevent empty strings being set as state
      this.updateRouteQuery();
    },
    updateEndingSOC(val: number) {
      this.endingCharge = to2DP(val / 100);
    },
    updateEndingDate(val: DatePickerInputUpdateObj) {
      this.endingDate = val.date ?? undefined; // prevent empty strings being set as state
      this.updateRouteQuery();
    },
    updateEndingTime(val: TimePickerInputUpdateObj) {
      this.endingTime = val.time ?? undefined; // prevent empty strings being set as state
      this.updateRouteQuery();
    },
    updateWeight(val: number) {
      this.extraWeight = val;
      this.updateRouteQuery();
    },
    updatePassengers(val: number) {
      this.passengerCount = val;
      this.updateRouteQuery();
    },
    updateRange(val: { min: number; max: number }) {
      this.socMin = val.min;
      this.socMax = val.max;
      this.updateRouteQuery();
    },
    updateRouteQuery() {
      const newQuery = {
        ...this.$route.query,
      };

      // address list section
      if (this.destinationAddressData) {
        newQuery.destAddress = encodeURI(this.destinationAddressData.address);
        newQuery.destLat =
          this.destinationAddressData.coordinates.Latitude.toString();
        newQuery.destLon =
          this.destinationAddressData.coordinates.Longitude.toString();
        if (this.destinationAddressData.name) {
          newQuery.destName = this.destinationAddressData.name;
        } else {
          delete newQuery.destName;
        }
      } else {
        delete newQuery.destAddress;
        delete newQuery.destLat;
        delete newQuery.destLon;
        delete newQuery.destName;
      }

      if (this.originAddressData) {
        newQuery.origAddress = encodeURI(this.originAddressData.address);
        newQuery.origLat =
          this.originAddressData.coordinates.Latitude.toString();
        newQuery.origLon =
          this.originAddressData.coordinates.Longitude.toString();
        if (this.originAddressData.name) {
          newQuery.origName = this.originAddressData.name;
        } else {
          delete newQuery.origName;
        }
      } else {
        delete newQuery.origAddress;
        delete newQuery.origLat;
        delete newQuery.origLon;
        delete newQuery.origName;
      }

      // address details section
      if (this.startingCharge) {
        newQuery.SOCAct = this.startingCharge.toString();
      } else {
        delete newQuery.SOCAct;
      }

      if (this.startingDate) {
        newQuery.originDate = this.startingDate;
      } else {
        delete newQuery.originDate;
      }

      if (this.startingTime) {
        newQuery.originTime = this.startingTime;
      } else {
        delete newQuery.originTime;
      }

      if (this.endingCharge) {
        newQuery.SOCEnd = this.endingCharge.toString();
      } else {
        delete newQuery.SOCEnd;
      }

      if (this.endingDate) {
        newQuery.destDate = this.endingDate;
      } else {
        delete newQuery.destDate;
      }

      if (this.endingTime) {
        newQuery.destTime = this.endingTime;
      } else {
        delete newQuery.destTime;
      }

      // extra details section
      if (this.socMax) {
        newQuery.SOCMax = this.socMax.toString();
      } else {
        delete newQuery.SOCMax;
      }

      if (this.socMin) {
        newQuery.SOCMin = this.socMin.toString();
      } else {
        delete newQuery.SOCMin;
      }

      if (this.passengerCount) {
        newQuery.passengers = this.passengerCount.toString();
      } else {
        delete newQuery.passengers;
      }

      if (this.extraWeight) {
        newQuery.extraWeight = this.extraWeight.toString();
      } else {
        delete newQuery.extraWeight;
      }

      this.$router.replace({
        query: newQuery,
      });
    },
  },
  mounted() {
    if (this.$route.query.destAddress) {
      const address = queryValueToString(this.$route.query.destAddress);
      const lat = queryValueToNumber(this.$route.query.destLat);
      const lon = queryValueToNumber(this.$route.query.destLon);
      const name = queryValueToString(this.$route.query.destName);
      if (address && lat && lon) {
        this.destinationAddressData = {
          address: address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name: name,
        };
      }
    }

    if (this.$route.query.origAddress) {
      const address = queryValueToString(this.$route.query.origAddress);
      const lat = queryValueToNumber(this.$route.query.origLat);
      const lon = queryValueToNumber(this.$route.query.origLon);
      const name = queryValueToString(this.$route.query.origName);
      if (address && lat && lon) {
        this.originAddressData = {
          address: address,
          coordinates: {
            Latitude: lat,
            Longitude: lon,
          },
          name: name,
        };
      }
    }
    this.startingCharge =
      queryValueToNumber(this.$route.query.SOCAct) ??
      (this.$store.state as State).SOCAct;
    this.endingCharge =
      queryValueToNumber(this.$route.query.SOCEnd) ??
      (this.$store.state as State).SOCEnd;
    this.startingDate = queryValueToString(this.$route.query.originDate);
    this.startingTime = queryValueToString(this.$route.query.originTime);
    this.endingDate = queryValueToString(this.$route.query.destDate);
    this.endingTime = queryValueToString(this.$route.query.destTime);
    this.passengerCount =
      queryValueToNumber(this.$route.query.passengers) ??
      (this.$store.state as State).passengers;
  },
  beforeRouteLeave(to, from, next) {
    // keep query params in the URL if navigating to other views in this multi
    // view form.
    if (
      (to.name === RouteNames.tripAddStops ||
        to.name === RouteNames.tripAddDetails ||
        to.name === RouteNames.tripAddOrigin ||
        to.name === RouteNames.tripAddDestination ||
        to.name === RouteNames.tripSelectVehicle ||
        to.name === RouteNames.tripStats) &&
      !Object.keys(from.query).every((key) =>
        Object.keys(to.query).includes(key)
      )
    ) {
      const toWithQuery = Object.assign({}, to, { query: from.query });
      next(toWithQuery as RawLocation);
    } else next();
  },
});
</script>

<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}

.pwt-info-panel-left-to-right-animation {
  left: -41%;
  -webkit-animation: left-to-right 700ms linear forwards;
  animation: left-to-right 700ms linear forwards;
}

.pwt-info-panel-right-to-left-animation {
  left: 0;
  -webkit-animation: right-to-left 1s linear forwards;
  animation: right-to-left 1s linear forwards;
}

@-webkit-keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}
</style>
