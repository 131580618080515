<template>
  <v-slide-group class="background">
    <v-slide-item v-for="(trip, index) in unsavedTrips" :key="index">
      <div style="max-width: 300px" class="pa-2 mt-2 rounded-lg background">
        <UnsavedTripCard :trip="trip" />
      </div>
    </v-slide-item>
  </v-slide-group>
</template>

<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import { GettersTypes } from "@/logic/store/store_types";
import Vue from "vue";
import UnsavedTripCard from "./UnsavedTripCard.vue";
export default Vue.extend({
  name: "UnsavedTripCarousel",
  components: { UnsavedTripCard },
  computed: {
    unsavedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.unsavedTrips];
    },
  },
});
</script>
