<template>
  <v-btn icon @click="clearTripAndReturnToTrips">
    <v-icon color="primary">mdi-home-circle-outline</v-icon>
  </v-btn>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import { MutationTypes } from "@/logic/store/store_types";
import Vue from "vue";
export default Vue.extend({
  name: "TripsHomeBtn",
  methods: {
    clearTripAndReturnToTrips() {
      this.$store.commit(MutationTypes.setSelectedTrip, undefined);
      this.$store.commit(MutationTypes.setDisplayAllChargersAlongRoute, false);
      this.$router.push({ name: RouteNames.trips });
    },
  },
});
</script>
