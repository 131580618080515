<template>
  <InfoPanelCardWrapper>
    <v-card flat>
      <!-- address search section -->
      <v-card flat class="mb-10">
        <v-card-text>
          <AddressAutocompleteInput
            placeholder="Search Optimiser for a location..."
            :initialValue="addressData"
            :allowFavLocations="true"
            @update="updateAddress"
          />
          <v-row no-gutters>
            <v-spacer />
            <ElevatedBtn @click="toPlanning"> Continue </ElevatedBtn>
          </v-row>
        </v-card-text>
      </v-card>
      <!-- history preview section -->
      <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
        <span>Unsaved trips</span>
        <OutlinedBtn @click="toUnsaved">View All</OutlinedBtn>
      </v-card-title>
      <v-card-text>
        <UnsavedTripCarousel />
      </v-card-text>
      <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
        <span>Saved trips</span>
        <OutlinedBtn @click="toSaved">View All</OutlinedBtn>
      </v-card-title>
      <v-card-text>
        <FrequentTripSavingsOverview class="mb-5" />
        <SavedTripCard v-if="savedTrips.length" :trip="savedTrips[0]" />
        <SavedTripCard v-if="savedTrips.length >= 2" :trip="savedTrips[1]" />
        <SavedTripCard v-if="savedTrips.length >= 3" :trip="savedTrips[2]" />
      </v-card-text>
    </v-card>
  </InfoPanelCardWrapper>
</template>
<script lang="ts">
import { RouteNames } from "@/logic/router";
import Vue from "vue";
import InfoPanelCardWrapper from "@/ui/components/ui-elements/wrappers/InfoPanelCardWrapper.vue";
import { GettersTypes } from "@/logic/store/store_types";
import OutlinedBtn from "@/ui/components/ui-elements/buttons/OutlinedBtn.vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../components/ui-elements/inputs/AddressAutocompleteInput.vue";
import ElevatedBtn from "../components/ui-elements/buttons/ElevatedBtn.vue";
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import { Dictionary } from "vue-router/types/router";
import Trip from "@/logic/classes/trip_classes/trip";
import FrequentTripSavingsOverview from "../components/trips/statistics/info-panel-content/FrequentTripSavingsOverview.vue";
import SavedTripCard from "../components/trips/history/SavedTripCard.vue";
import UnsavedTripCarousel from "../components/trips/history/UnsavedTripCarousel.vue";

export default Vue.extend({
  name: "HistoryMainView",
  components: {
    InfoPanelCardWrapper,
    OutlinedBtn,
    ElevatedBtn,
    AddressAutocompleteInput,
    FrequentTripSavingsOverview,
    SavedTripCard,
    UnsavedTripCarousel,
  },
  computed: {
    savedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.savedTrips];
    },
    unsavedTrips(): Trip[] {
      return this.$store.getters[GettersTypes.unsavedTrips];
    },
  },
  methods: {
    // Routing
    pushRoute(
      routeName: RouteNames,
      query:
        | Dictionary<string | (string | null)[] | null | undefined>
        | undefined = undefined
    ) {
      this.$router.push({ name: routeName, query: query });
    },
    toPlanning() {
      const address = this.addressData?.address;
      const lat = this.addressData?.coordinates.Latitude;
      const lon = this.addressData?.coordinates.Longitude;
      const name = this.addressData?.name;
      const newQuery = {
        ...this.$route.query,
      };

      if (address) {
        newQuery.destAddress = encodeURI(address);
      }

      if (lat) {
        newQuery.destLat = lat.toString();
      }

      if (lon) {
        newQuery.destLon = lon.toString();
      }

      if (name) {
        newQuery.destName = name;
      }

      this.pushRoute(RouteNames.tripAddDestination, newQuery);
    },
    toUnsaved() {
      this.pushRoute(RouteNames.unsaved);
    },
    toSaved() {
      this.pushRoute(RouteNames.saved);
    },
    updateAddress(val: AddressAutocompleteInputUpdateObj) {
      this.addressData = val.addressData;
    },
  },
  data() {
    return {
      addressData: undefined as processedAddressObj | undefined,
    };
  },
});
</script>
