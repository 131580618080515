<template>
  <v-card flat>
    <v-card-title> Select a vehicle to compare </v-card-title>
    <v-text-field
      v-model="searchQuery"
      prepend-inner-icon="mdi-magnify"
      rounded
      filled
      clearable
      hide-details
      dense
      class="mx-5 mb-3"
    />

    <v-card-title> Custom vehicles </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in customVehicles"
          :vehicle="vehicle"
          :key="`vehicle-card-${index}`"
          @select="emitSelectedVehicle"
          @showDetails="emitSelectedVehicle"
        />
      </v-row>
    </v-card-text>

    <v-card-title> Connected vehicles </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in connectedVehicles"
          :vehicle="vehicle"
          :key="`vehicle-card-${index}`"
          @select="emitSelectedVehicle"
          @showDetails="emitSelectedVehicle"
        />
      </v-row>
    </v-card-text>

    <v-card-title> Generic vehicles </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-for="(vehicle, index) in genericVehicles"
          :vehicle="vehicle"
          :key="`generic-vehicle-card-${index}`"
          @select="emitSelectedVehicle"
          @showDetails="emitSelectedVehicle"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { GettersTypes } from "@/logic/store/store_types";
import VehicleGalleryCard from "../../vehicles/dialog-content/VehicleGalleryCard.vue";

export default Vue.extend({
  name: "VehicleSearchContent",
  components: {
    VehicleGalleryCard,
  },
  data() {
    return {
      searchQuery: "",
    };
  },
  computed: {
    connectedVehicles() {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.connectedVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
    genericVehicles() {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.genericVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
    customVehicles() {
      const unfilteredData: Vehicle[] =
        this.$store.getters[GettersTypes.customVehicles];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        return unfilteredData.filter((vehicle) => {
          if (vehicle.name && vehicle.name.toLowerCase().includes(query))
            return vehicle;
          if (vehicle.VIN && vehicle.VIN.toLowerCase().includes(query))
            return vehicle;
          if (
            vehicle.licensePlate &&
            vehicle.licensePlate.toLowerCase().includes(query)
          )
            return vehicle;
          if (vehicle.evModel) {
            if (vehicle.evModel.name.toLowerCase().includes(query))
              return vehicle;
          }
        });
      }
      return unfilteredData;
    },
  },
  methods: {
    emitSelectedVehicle(vehicle: Vehicle) {
      this.$emit("vehicle-select", vehicle);
    },
  },
});
</script>
