var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{attrs:{"flat":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('DashboardBreadcrumbs',{attrs:{"items":_vm.showingTrip
        ? [
            { text: 'Home' },
            { text: 'Saved trips' },
            { text: 'Trip details', disabled: true },
          ]
        : [{ text: 'Home' }, { text: 'Saved trips', disabled: true }]},on:{"navigation":_vm.breadcrumbNavigation,"back":function($event){return _vm.breadcrumbNavigation({ text: 'Home' })}}}):_vm._e(),(_vm.showingTrip)?_c('TripDetailsPage',{attrs:{"trip":_vm.showingTrip},on:{"back":function($event){_vm.showingTrip = undefined},"view-trip":_vm.close,"view-stats":_vm.close}}):_c('v-card',{staticClass:"p7-5 pa-md-5 background",attrs:{"flat":""}},[_c('v-card-title',[_vm._v(" Unsaved trips ")]),_c('v-card-text',[_c('UnsavedTripCarousel')],1),_c('v-card-title',[_vm._v(" Saved trips ")]),_c('FrequentTripSavings'),_c('v-card-text',[_c('v-row',{attrs:{"no-gutters":""}},_vm._l((_vm.savedTrips),function(trip,i){return _c('v-col',{key:'saved-trip-' + i,attrs:{"cols":"12","md":"6"}},[_c('div',{class:_vm.$vuetify.breakpoint.mdAndUp
                ? i % 2 === 0
                  ? 'pr-1'
                  : 'pl-1'
                : ''},[_c('SavedTripCard',{style:(_vm.$vuetify.breakpoint.mdAndUp ? 'height: 100%' : ''),attrs:{"trip":trip,"emitOnly":""},on:{"view-trip":function($event){return _vm.showTripDetails(trip)}}})],1)])}),1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }