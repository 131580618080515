<template>
  <v-card-text>
    <v-card class="rounded-lg mb-3">
      <v-card-title class="my-5">
        <v-icon color="primary" class="mr-3"> mdi-piggy-bank-outline </v-icon>
        ${{
          (
            (oneYearProjections.totalEquivalentFuelCost +
              (calcVs === "PETROL"
                ? oneYearProjections.totalPetrolRoadUserCharges
                : oneYearProjections.totalDieselRoadUserCharges) +
              oneYearProjections.totalPetrolMaintenanceCost -
              oneYearProjections.totalPublicChargingCost -
              oneYearProjections.totalPrivateChargingCost -
              oneYearProjections.totalEVMaintenanceCost -
              oneYearProjections.totalElectricRoadUserCharges) *
            years
          ).toFixed(2)
        }}
        total savings
      </v-card-title>
      <v-card-text>
        <v-slider
          min="1"
          max="10"
          step="1"
          v-model="years"
          dense
          hide-details
          track-color="grey lighten-2"
          class="mb-5"
          :label="years + ' year' + (years > 1 ? 's' : '')"
        />
      </v-card-text>

      <v-row class="flex-column flex-md-row">
        <v-col md="6">
          <v-card flat>
            <v-card-title> Emissions avoided </v-card-title>
            <v-card-text>
              <v-card class="background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-fire
                </v-icon>
                <span>
                  {{
                    Math.round(
                      oneYearProjections.totalAvoidedEmissions * years
                    ).toString()
                  }}
                  kg CO₂ emissions avoided
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card flat>
            <v-card-title> Total trips </v-card-title>
            <v-card-text>
              <v-card class="background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-map-marker-outline
                </v-icon>
                <span>
                  {{
                    Math.round(
                      oneYearProjections.totalNumberOfTrips * years
                    ).toString()
                  }}
                  total trips
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="flex-column flex-md-row">
        <v-col md="6">
          <v-card flat>
            <v-card-title> Fuel savings </v-card-title>
            <v-card-text>
              <v-card class="background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-piggy-bank-outline
                </v-icon>
                <span>
                  ${{
                    (
                      (oneYearProjections.totalEquivalentFuelCost +
                        oneYearProjections.totalPublicChargingCost -
                        oneYearProjections.totalPrivateChargingCost) *
                      years
                    ).toFixed(2)
                  }}
                  fuel savings
                </span>
              </v-card>
              <v-card class="background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-water-outline
                </v-icon>
                <span>
                  ${{
                    (
                      oneYearProjections.totalEquivalentFuelCost * years
                    ).toFixed(2)
                  }}
                  equivalent fuel cost
                </span>
              </v-card>
              <v-card class="background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-currency-usd
                </v-icon>
                <span>
                  ${{
                    (
                      oneYearProjections.totalPublicChargingCost * years
                    ).toFixed(2)
                  }}
                  spent on public charging
                </span>
              </v-card>
              <v-card class="background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-currency-usd
                </v-icon>
                <span>
                  ${{
                    (
                      oneYearProjections.totalPrivateChargingCost * years
                    ).toFixed(2)
                  }}
                  spent on private charging
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col md="6">
          <v-card flat>
            <v-card-title> Maintenance savings </v-card-title>
            <v-card-text>
              <v-card class="background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-piggy-bank-outline
                </v-icon>
                <span>
                  ${{
                    (
                      (oneYearProjections.totalPetrolMaintenanceCost -
                        oneYearProjections.totalEVMaintenanceCost) *
                      years
                    ).toFixed(2)
                  }}
                  maintenance savings
                </span>
              </v-card>
              <v-card class="background mb-3" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-pliers
                </v-icon>
                <span>
                  ${{
                    (
                      oneYearProjections.totalPetrolMaintenanceCost * years
                    ).toFixed(2)
                  }}
                  petrol vehicle maintenance
                </span>
              </v-card>
              <v-card class="background" flat>
                <v-icon color="primary" class="mr-1 ml-2 py-2">
                  mdi-pliers
                </v-icon>
                <span>
                  ${{
                    (oneYearProjections.totalEVMaintenanceCost * years).toFixed(
                      2
                    )
                  }}
                  electric vehicle maintenance
                </span>
              </v-card>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-card flat>
        <v-card-title> Road user charges </v-card-title>
        <v-card-text>
          <v-card class="background mb-3" flat>
            <v-icon color="primary" class="mr-1 ml-2 py-2">
              mdi-currency-usd
            </v-icon>
            <span>
              ${{
                (
                  oneYearProjections.totalElectricRoadUserCharges * years
                ).toFixed(2)
              }}
              electric road user charges
            </span>
          </v-card>
          <v-card class="background mb-3" flat>
            <v-icon color="primary" class="mr-1 ml-2 py-2">
              mdi-currency-usd
            </v-icon>
            <span>
              ${{
                (oneYearProjections.totalPetrolRoadUserCharges * years).toFixed(
                  2
                )
              }}
              equivalent petrol road user charges
            </span>
          </v-card>
          <v-card class="background" flat>
            <v-icon color="primary" class="mr-1 ml-2 py-2">
              mdi-currency-usd
            </v-icon>
            <span>
              ${{
                (oneYearProjections.totalDieselRoadUserCharges * years).toFixed(
                  2
                )
              }}
              equivalent diesel road user charges
            </span>
          </v-card>
        </v-card-text>
      </v-card>
    </v-card>
  </v-card-text>
</template>
<script lang="ts">
import { GettersTypes, type State } from "@/logic/store/store_types";
import Vue from "vue";
import { mapGetters, mapState } from "vuex";

export default Vue.extend({
  name: "FrequentTripSavings",
  computed: {
    ...mapGetters({
      oneYearProjections: GettersTypes.getOneYearProjections,
    }),
    ...mapState({
      calcVs: (state: unknown) => (state as State).calcVs,
    }),
  },
  data() {
    return {
      years: 5,
    };
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
