<template>
  <svg
    width="673"
    height="673"
    viewBox="0 0 673 673"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_567_16243)" filter="url(#filter0_i_567_16243)">
      <path
        d="M532.146 84.1838H140.058C109.251 84.1838 84.0449 109.39 84.0449 140.196V532.285C84.0449 563.092 109.251 588.298 140.058 588.298H532.146C562.953 588.298 588.159 563.092 588.159 532.285V140.196C588.159 109.39 562.953 84.1838 532.146 84.1838ZM252.083 476.272H196.07V280.228H252.083V476.272ZM364.108 476.272H308.096V196.209H364.108V476.272ZM476.134 476.272H420.121V364.247H476.134V476.272Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_567_16243"
        x="0.0253906"
        y="0.164551"
        width="702.026"
        height="702.025"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="29.8734" dy="29.8734" />
        <feGaussianBlur stdDeviation="29.8734" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_567_16243"
        />
      </filter>
      <clipPath id="clip0_567_16243">
        <rect
          width="672.152"
          height="672.152"
          fill="white"
          transform="translate(0.0253906 0.164551)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts">
/** Custom icon wrapped in a template to be used by vuetify to gain access to the vuetify component properties*/
export default {
  name: "TripStatsIcon",
};
</script>
