<template>
  <v-app id="pwt-optimiser">
    <v-main id="pwt-main-container">
      <LoginOverlay v-if="showLogin" />
      <MapPanel />
      <InfoPanelControls @showDashboard="showDashboard = true" />
      <MainDialog />
      <SecondaryDialog />
      <TertiaryDialog />
      <DashboardDialog
        :activator="showDashboard"
        @close="showDashboard = false"
      />
      <NetworkFilter />
    </v-main>
  </v-app>
</template>

<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import { checkToken } from "./logic/api/calls/directus_calls";
import MapPanel from "./ui/components/map-panel/MapPanel.vue";
import MainDialog from "./ui/components/dialog-wrappers/MainDialog.vue";
import SecondaryDialog from "./ui/components/dialog-wrappers/SecondaryDialog.vue";
import LoginOverlay from "./ui/components/user/authorisation/LoginOverlay.vue";
import InfoPanelControls from "./ui/components/info-panel/InfoPanelControls.vue";
import TertiaryDialog from "./ui/components/dialog-wrappers/TertiaryDialog.vue";
import { ActionTypes } from "./logic/store/store_types";
import { MutationTypes } from "./logic/store/store_types";
import { State } from "./logic/store/store_types";
import DashboardDialog from "./ui/components/dashboard/DashboardDialog.vue";
import NetworkFilter from "./ui/components/settings/NetworkFilter.vue";

/** Vue component: main entry point to the vue app. */
export default Vue.extend({
  name: "App",
  mounted() {
    console.log("Displaying version:", process.env.VUE_APP_VERSION);
    // Track sessions automatically (recommended)
    Vue.prototype.$Countly.q.push(["track_sessions"]);
    // Track web page views automatically (recommended)
    Vue.prototype.$Countly.q.push(["track_pageview", "landing"]);
    // report unhandled errors to analytics.
    Vue.prototype.$Countly.q.push(["track_errors"]);
    this.$nextTick(() => {
      checkToken().then((res) => {
        this.$store.commit(MutationTypes.setCheckingToken, false);
        if (res) {
          this.$store.commit(MutationTypes.setShowLogin, false);
          this.$store.commit(MutationTypes.setAuthorizedFlag, true);
          this.$store.dispatch(ActionTypes.fetchData);
          this.startRefresh();
          this.showDashboard = true;
          return;
        }
        this.$store.commit(MutationTypes.setShowLogin, true);
      });
    });
  },
  components: {
    MapPanel,
    MainDialog,
    SecondaryDialog,
    LoginOverlay,
    InfoPanelControls,
    TertiaryDialog,
    DashboardDialog,
    NetworkFilter,
  },
  computed: {
    ...mapState({
      showLogin: (state: unknown) => (state as State).showLogin,
      authorizedFlag: (state: unknown) => (state as State).authorizedFlag,
    }),
  },
  data() {
    return {
      showDashboard: false,
    };
  },
  methods: {
    startRefresh() {
      setTimeout(() => {
        this.$store.dispatch(ActionTypes.refreshData);
        this.startRefresh();
      }, 300000);
    },
  },
  watch: {
    authorizedFlag(value: boolean) {
      this.showDashboard = value;
    },
  },
});
</script>

<style>
[data-vuetify] header {
  min-height: 0;
}
</style>
<style lang="scss" scoped>
@import url("https://cdn.jsdelivr.net/npm/@mdi/font@latest/css/materialdesignicons.min.css");
// @import url("../node_modules/vuetify/dist/vuetify.min.css");

:where(powertrip-optimiser) {
  all: initial;
  * {
    all: unset;
    margin: 0 auto;
    min-height: 0 !important;
  }
}

:where(*, *::before, *::after) {
  all: revert;
  box-sizing: border-box;
  max-width: 100vw;
  max-height: 100vh;
  min-height: 0 !important;
}

.v-application {
  font-family: "Public Sans", Roboto, sans-serif !important;
  background-color: var(--v-background-base) !important;
}

.v-card,
.v-sheet,
.v-toolbar,
.v-tabs-items,
.v-expansion-panel,
.v-skeleton-loader__article,
.v-skeleton-loader__list-item-avatar,
.container {
  background-color: var(--v-background-base) !important;
}

.v-card__title {
  word-break: normal !important;
}

.pwt-number-font {
  font-family: "Inter", sans-serif !important;
}

#pwt-main-container {
  position: relative;
  width: 100vw;
  height: 100vh;
  height: 100svh;
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}

.v-application--wrap {
  overflow: hidden;
  min-height: unset;
}
</style>
<style>
.v-application--wrap {
  min-height: auto !important;
  overflow: hidden;
}
</style>
