<template>
  <v-card class="mb-3 rounded-lg" @click="showTrip">
    <v-row no-gutters align="center">
      <v-col :cols="trip.frequency ? 8 : 12">
        <v-card-title v-if="trip.name">
          <span class="text-truncate"> {{ trip.name }} </span>
        </v-card-title>
        <v-card-title v-else>
          <span class="text-truncate">
            Trip to
            {{
              trip.locations[trip.locations.length - 1].name ??
              trip.locations[trip.locations.length - 1].address
            }}
          </span>
        </v-card-title>
      </v-col>
      <v-col v-if="trip.frequency" cols="4" class="text-right pr-3">
        <v-icon color="primary" v-if="trip.frequency">
          mdi-progress-clock
        </v-icon>
        <span class="pr-2 text-truncate" v-if="trip.frequency">
          {{ trip.frequency?.numberOfTimes }}x {{ trip.frequency?.timeFrame }}
        </span>
      </v-col>
    </v-row>
    <v-card-text>
      <ul style="list-style: none; padding-left: unset">
        <li>{{ niceDuration(trip.itinerary.totalTravelTime) }}</li>
        <li class="d-flex justify-space-between">
          {{ trip.chargingStopCDBIDs.length }} charging stops
          <OutlinedBtn @click="openDeleteConfirmation"> Delete </OutlinedBtn>
        </li>
      </ul>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Trip from "@/logic/classes/trip_classes/trip";
import Vue, { PropType } from "vue";
import { getNiceDuration } from "@/logic/utils/timeUtils";
import {
  ActionTypes,
  MutationTypes,
  SecondaryDialogContent,
} from "@/logic/store/store_types";
import OutlinedBtn from "../../ui-elements/buttons/OutlinedBtn.vue";
export default Vue.extend({
  name: "SavedTripCard",
  props: {
    trip: Object as PropType<Trip>,
    emitOnly: {
      default: false,
      type: Boolean,
    },
  },
  components: { OutlinedBtn },
  methods: {
    niceDuration(seconds: number): string {
      return getNiceDuration(seconds);
    },
    openDeleteConfirmation() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.STORED_TRIP_DELETE_CONFIRMATION
      );
      this.$store.commit(
        MutationTypes.setQueuedTripForDelete,
        this.trip.localId
      );
    },
    showTrip() {
      if (this.emitOnly) {
        this.$emit("view-trip", this.trip);
      } else {
        this.$store.dispatch(ActionTypes.showTrip, this.trip);
      }
    },
  },
});
</script>
