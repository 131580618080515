<template>
  <v-app-bar color="background" fixed elevation="1">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-top-transition"
      content-class="background"
      class="background"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-app-bar-nav-icon color="grey lighten-1" v-bind="attrs" v-on="on">
        </v-app-bar-nav-icon>
      </template>
      <v-card class="background">
        <v-toolbar flat class="background">
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon @click="dialog = false">
              <v-icon color="grey lighten-1">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <!-- account card -->
        <AvatarRow
          v-if="user"
          :avatarSrc="avatarSrc"
          :emitViewAccount="emitViewAccount"
          :emitViewAllVehicles="emitViewAllVehicles"
          :emitViewVehicle="emitViewVehicle"
          :emitViewDefaults="emitViewDefaults"
          :handleLogout="handleLogout"
          :user="user"
          class="mx-8"
        />
        <v-divider></v-divider>
        <!-- tabs section -->
        <v-card-text class="background">
          <v-tabs
            vertical
            :value="tab"
            hide-slider
            background-color="background"
            optional
          >
            <v-tab class="text-none" tab-value="home" @click="emitViewHome">
              Home <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none"
              tab-value="account"
              @click="emitViewAccount"
            >
              Account <v-spacer></v-spacer>
            </v-tab>
            <v-expansion-panels v-model="expand" accordion flat tile>
              <v-expansion-panel class="background">
                <v-expansion-panel-header class="v-tab text-none">
                  Vehicles
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-tab
                    class="text-none"
                    :class="tab === 'vehicle-details' ? 'primary--text' : ''"
                    tab-value="vehicle-details"
                    @click="emitViewVehicle"
                  >
                    Vehicle details <v-spacer></v-spacer>
                  </v-tab>
                  <v-tab
                    class="text-none"
                    :class="tab === 'fleet-vehicles' ? 'primary--text' : ''"
                    tab-value="fleet-vehicles"
                    @click="emitViewAllVehicles"
                  >
                    Fleet vehicles <v-spacer></v-spacer>
                  </v-tab>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-tab
              class="text-none background"
              tab-value="locations-manager"
              @click="emitViewLocations"
            >
              Locations manager
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none background"
              tab-value="defaults"
              @click="emitViewDefaults"
            >
              Trip planning defaults
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none background"
              tab-value="saved-trips"
              @click="emitViewSavedTrips"
            >
              Saved trips <v-spacer></v-spacer>
            </v-tab>
          </v-tabs>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-toolbar-title class="grey--text text--lighten-1">
      {{ title }}
    </v-toolbar-title>
    <v-spacer></v-spacer>
    <v-btn icon @click.stop="emitClose()">
      <v-icon color="grey lighten-1">mdi-close</v-icon>
    </v-btn>
  </v-app-bar>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import type { tabs } from "./DashboardDialog.vue";
import AvatarRow from "../user/profile/info-panel-content/AvatarRow.vue";
import { ActionTypes, GettersTypes, State } from "@/logic/store/store_types";
import Driver from "@/logic/classes/user_classes/driver";
import { mapState } from "vuex";

export default Vue.extend({
  name: "DashboardMobileAppBar",
  props: {
    tab: { type: String as PropType<tabs>, required: true },
  },
  components: {
    AvatarRow,
  },
  data() {
    return {
      dialog: false,
      expand: 0 as number | undefined,
    };
  },
  methods: {
    emitClose() {
      this.$emit("close");
      this.dialog = false;
    },
    /**
     * Closes the profile card and logs the user out.
     */
    handleLogout() {
      this.$emit("logout");
      this.$store.dispatch(ActionTypes.logoutUser);
    },
    /**
     * Emits the 'view-home' event to the parent component.
     * Used to navigate to the dashboard home page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewHome() {
      this.$emit("view-home");
      this.dialog = false;
    },
    /**
     * Emits the 'view-account' event to the parent component.
     * Used to navigate to the user account page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAccount(): void {
      this.$emit("view-account");
      this.dialog = false;
    },
    /**
     * Emits the 'view-vehicle' event to the parent component.
     * Used to navigate to the user vehicle page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewVehicle(): void {
      this.$emit("view-vehicle");
      this.dialog = false;
    },
    /**
     * Emits the 'view-vehicles' event to the parent component.
     * Used to navigate to the user fleet page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAllVehicles(): void {
      this.$emit("view-vehicles");
      this.dialog = false;
    },
    /**
     * Emits the 'view-defaults' event to the parent component.
     * Used to navigate to the user trip planning defaults page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewDefaults(): void {
      this.$emit("view-defaults");
      this.dialog = false;
    },
    /**
     * Emits the 'view-locations' event to the parent component.
     * Used to navigate to the user location page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewLocations(): void {
      this.$emit("view-locations");
      this.dialog = false;
    },
    emitViewSavedTrips(): void {
      this.$emit("view-saved-trips");
      this.dialog = false;
    },
  },
  computed: {
    /**
     * Returns the src path for the users profile picture if they have one.
     *
     * @return {string | undefined} The src path for the users profile picture if they have one, undefined otherwise.
     */
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    ...mapState({
      user: (state: unknown) => (state as State).user,
    }),
    title() {
      switch (this.tab) {
        case "home":
          return "Home";
        case "account":
          return "Account";
        case "vehicle-details":
          return "Vehicle details";
        case "fleet-vehicles":
          return "Fleet vehicles";
        case "locations-manager":
          return "Locations manager";
        case "defaults":
          return "Trip planning defaults";
        default:
          return "";
      }
    },
  },
  watch: {
    tab(value: tabs) {
      if (value === "vehicle-details" || value === "fleet-vehicles") {
        this.expand = 0;
      }
    },
  },
});
</script>
