<template>
  <v-card flat class="background">
    <v-card-title class="text-h5"> My fleet </v-card-title>
    <v-card flat class="background">
      <v-card-title>
        <span> Fleet vehicles </span>
        <v-spacer />
        <v-btn
          v-if="$vuetify.breakpoint.smAndDown"
          icon
          @click="emitViewAllVehicles"
        >
          <v-icon color="grey lighten-1">mdi-dots-vertical</v-icon>
        </v-btn>
        <TextBtn v-else @click="emitViewAllVehicles"> View all </TextBtn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-card
            flat
            v-for="(vehicle, index) in vehicleList"
            :key="'vehicle-' + index"
            :style="
              $vuetify.breakpoint.smAndDown ? 'width: 100%' : 'width: 50%'
            "
            class="pa-md-1 py-1"
          >
            <VehicleListCard
              :vehicle="vehicle"
              @click="emitViewVehicle(vehicle.localId)"
            />
          </v-card>
        </v-row>
        <OutlinedBlockBtn @click="showBookingDialog = true" class="mt-3">
          Book fleet vehicles
        </OutlinedBlockBtn>
        <GenericDialog
          :activator="showBookingDialog"
          @close="showBookingDialog = false"
          small
        >
          <v-card-title> Book fleet vehicles </v-card-title>
          <v-card-subtitle>Premium feature</v-card-subtitle>
          <v-card-text>
            Please contact PowerTrip to discuss unlocking premium features
          </v-card-text>
        </GenericDialog>
      </v-card-text>
    </v-card>
    <v-card flat class="background">
      <v-card-title>
        <span> Fleet locations </span>
        <v-spacer />
        <TextBtn v-if="$vuetify.breakpoint.mdAndUp" @click="emitViewLocations">
          View all
        </TextBtn>
      </v-card-title>
      <v-card-text>
        <v-row no-gutters>
          <v-card
            v-for="(location, index) in fleetLocationsList"
            :key="'location-' + index"
            style="width: calc(50% - 8px)"
            :style="$vuetify.breakpoint.smAndDown ? 'width: 100%' : ''"
            class="pa-5 ma-md-1 my-1 rounded-lg background"
          >
            <v-card-title> {{ location.name }} </v-card-title>
            <v-card-subtitle> {{ location.address }} </v-card-subtitle>
          </v-card>
        </v-row>
        <ElevatedBlockBtn
          v-if="$vuetify.breakpoint.smAndDown"
          class="mt-3"
          @click="emitViewLocations"
        >
          See all fleet locations
        </ElevatedBlockBtn>
      </v-card-text>
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import TextBtn from "../ui-elements/buttons/TextBtn.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { GettersTypes, State } from "@/logic/store/store_types";
import VehicleListCard from "../vehicles/VehicleListCard.vue";
import FavouriteLocation from "@/logic/classes/favouriteLocation";
import ElevatedBlockBtn from "../ui-elements/buttons/ElevatedBlockBtn.vue";
import OutlinedBlockBtn from "../ui-elements/buttons/OutlinedBlockBtn.vue";
import GenericDialog from "../dialog-wrappers/GenericDialog.vue";

export default Vue.extend({
  name: "MyFleetContent",
  components: {
    TextBtn,
    VehicleListCard,
    ElevatedBlockBtn,
    OutlinedBlockBtn,
    GenericDialog,
  },
  methods: {
    emitViewVehicle(id: string | undefined) {
      this.$emit("view-vehicle", id);
    },
    emitViewAllVehicles() {
      this.$emit("view-vehicles");
    },
    emitViewLocations() {
      this.$emit("view-locations");
    },
  },
  computed: {
    vehicleList(): Vehicle[] {
      const returnList: Vehicle[] = [];
      const fullList = this.$store.getters[
        GettersTypes.connectedVehicles
      ] as Vehicle[];

      fullList.forEach((vehicle, index) => {
        if (index < (this.$vuetify.breakpoint.smAndDown ? 3 : 4))
          returnList.push(vehicle);
      });

      return returnList;
    },
    fleetLocationsList(): FavouriteLocation[] {
      const unfilteredList = (this.$store.state as State).favLocations;
      return unfilteredList.filter(
        (location) => location.visibility !== "private"
      );
    },
    privateLocationsList(): FavouriteLocation[] {
      const unfilteredList = (this.$store.state as State).favLocations;
      return unfilteredList.filter(
        (location) => location.visibility === "private"
      );
    },
  },
  data() {
    return {
      showBookingDialog: false,
    };
  },
});
</script>
