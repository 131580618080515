<template>
  <v-btn
    style="z-index: 1002; position: absolute; top: 4px; right: 4px"
    :small="$vuetify.breakpoint.smAndUp"
    :class="
      $vuetify.breakpoint.smAndUp
        ? 'rounded-pill text-none px-4'
        : hasFilteredNetworks
          ? 'primary'
          : 'background'
    "
    :color="
      hasFilteredNetworks
        ? $vuetify.breakpoint.smAndUp
          ? 'primary'
          : 'white'
        : undefined
    "
    @click="openNetworkSettings"
    :icon="$vuetify.breakpoint.xsOnly ? true : false"
  >
    <v-icon>mdi-filter-variant</v-icon>
    <span v-if="$vuetify.breakpoint.smAndUp">Networks </span>
  </v-btn>
</template>
<script lang="ts">
import {
  MutationTypes,
  SecondaryDialogContent,
  State,
} from "@/logic/store/store_types";
import Vue from "vue";
import { mapState } from "vuex";

export default Vue.extend({
  name: "NetworkFilter",
  computed: {
    ...mapState({
      hasFilteredNetworks: (state: unknown) =>
        !!(state as State).networkSelectionOverride,
    }),
  },
  methods: {
    openNetworkSettings() {
      this.$store.commit(
        MutationTypes.setSecondaryDialogContent,
        SecondaryDialogContent.NETWORK_SETTINGS
      );
    },
  },
});
</script>
