<template>
  <GenericDialog :activator="activator" @close="emitClose">
    <v-card flat class="background px-5 px-md-10">
      <v-card-title class="my-10 d-flex justify-center flex-column flex-md-row">
        Add stops from saved locations
        <v-spacer v-if="$vuetify.breakpoint.mdAndUp" />
        <ElevatedBtn
          v-if="$vuetify.breakpoint.mdAndUp"
          @click="handleAddSelectedStops"
        >
          Add selected stops
        </ElevatedBtn>
        <ElevatedBlockBtn v-else class="mt-5" @click="handleAddSelectedStops">
          Add selected stops
        </ElevatedBlockBtn>
      </v-card-title>
      <v-text-field
        v-model="searchQuery"
        prepend-inner-icon="mdi-magnify"
        rounded
        filled
        clearable
        hide-details
        dense
        class="mx-5 mb-3"
      />
      <v-list three-line color="background">
        <v-list-item-group
          v-model="selection"
          multiple
          class="d-flex flex-wrap justify-start"
        >
          <v-list-item
            v-for="location in filteredSavedLocations"
            :key="location.localId"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 50%' : ''"
            :value="location.localId"
          >
            <template v-slot:default="{ active }">
              <v-list-item-icon>
                <v-icon v-if="active" color="success">
                  mdi-check-circle
                </v-icon>
                <v-icon v-else color="primary"> mdi-map-marker </v-icon>
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>{{ location.name }}</v-list-item-title>
                <v-list-item-subtitle>
                  {{ location.address }}
                </v-list-item-subtitle>
              </v-list-item-content>
            </template>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </v-card>
  </GenericDialog>
</template>
<script lang="ts">
import { mapState } from "vuex";
import GenericDialog from "../../dialog-wrappers/GenericDialog.vue";
import Vue from "vue";
import { State } from "@/logic/store/store_types";
import FavouriteLocation from "@/logic/classes/favouriteLocation";
import ElevatedBtn from "../../ui-elements/buttons/ElevatedBtn.vue";
import ElevatedBlockBtn from "../../ui-elements/buttons/ElevatedBlockBtn.vue";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";

export default Vue.extend({
  name: "AddSavedLocations",
  components: {
    GenericDialog,
    ElevatedBtn,
    ElevatedBlockBtn,
  },
  props: {
    activator: Boolean,
  },
  computed: {
    ...mapState({
      savedLocations: (state: unknown) => (state as State).favLocations,
    }),
    filteredSavedLocations(): FavouriteLocation[] {
      if (
        !this.searchQuery ||
        this.searchQuery === "" ||
        this.searchQuery === " "
      ) {
        return this.savedLocations;
      } else {
        return this.savedLocations.filter((location) => {
          if (
            location.name
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase().trim())
          )
            return true;
          if (
            location.address
              .toLowerCase()
              .includes(this.searchQuery.toLowerCase().trim())
          )
            return true;
          return false;
        });
      }
    },
  },
  data() {
    return { selection: [], searchQuery: "" };
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },

    handleAddSelectedStops() {
      const tripLocations: TripLocation[] = [];

      this.selection.forEach((localId) => {
        const location = this.savedLocations.find(
          (location) => location.localId === localId
        );
        if (location) {
          tripLocations.push(location.toTripLocation);
        }
      });
      this.$emit("addStops", tripLocations);
      this.emitClose();
    },
  },
});
</script>
