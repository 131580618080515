<template>
  <div>
    <!-- display -->
    <v-row no-gutters justify="space-between">
      <div class="text-h6">
        {{ date }}
      </div>
      <v-btn
        icon
        @click="
          () => {
            openDialog = true;
          }
        "
        class="mr-n1"
      >
        <v-icon>mdi-calendar-edit</v-icon>
      </v-btn>
    </v-row>
    <!-- booking date picker modal -->
    <v-dialog
      :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
      style="z-index: 1500"
      :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
      max-width="300px"
      min-width="200px"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
      v-model="openDialog"
    >
      <v-card>
        <v-date-picker
          :value="time ? processEpochToDate(time) : null"
          @change="handleDateChange"
        />
      </v-card>
    </v-dialog>
  </div>
</template>
<script lang="ts">
import {
  convertEpochToISODateString,
  convertISODateStringToEpoch,
  epochToNiceDate,
} from "@/logic/utils/timeUtils";
import Vue from "vue";

export interface BookingDateInputUpdateObj {
  newStartTime?: number;
  newEndTime?: number;
}

export default Vue.extend({
  name: "BookingDateInput",
  props: {
    time: {
      type: Number,
    },
    isStart: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      openDialog: false,
    };
  },
  computed: {
    date(): string {
      if (!this.time) return "";
      return epochToNiceDate(this.time);
    },
  },
  methods: {
    emitUpdate(newTimes: BookingDateInputUpdateObj) {
      this.$emit("update", newTimes);
    },
    processEpochToDate(epochTime: number) {
      return convertEpochToISODateString(epochTime);
    },
    handleDateChange(val: string | null) {
      if (val) {
        const newTimes: BookingDateInputUpdateObj = {};
        if (this.isStart) {
          newTimes.newStartTime = convertISODateStringToEpoch(val, this.time);
        } else {
          newTimes.newEndTime = convertISODateStringToEpoch(val, this.time);
        }
        this.emitUpdate(newTimes);
      }
    },
  },
});
</script>
