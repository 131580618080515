<template>
  <v-card flat>
    <v-card-title>Page Not Found</v-card-title>
    <v-card-text>
      The page you are looking for does not exist, Try going back to
      <router-link to="/">Home</router-link>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
export default Vue.extend({
  name: "PageNotFoundContent",
});
</script>
