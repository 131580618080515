<template>
  <l-marker
    v-if="location"
    :lat-lng="latLng()"
    @mouseover="pinHoverEnter"
    @mouseleave="pinHoverLeave"
    @click="showFleetVehicleDetails"
  >
    <l-icon
      :icon-url="icon"
      :icon-size="iconSize"
      className="pwt-car-shadow"
    ></l-icon>
  </l-marker>
</template>

<script lang="ts">
import Coordinate from "@/logic/classes/common_classes/coordinate";
import TripLocation from "@/logic/classes/trip_classes/tripLocation";
import { MainDialogContent, MutationTypes } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue, { PropType } from "vue";
import { LMarker, LIcon } from "vue2-leaflet";

/** Vue-Leaflet Component: renders the car pin. To be used in the `MapPanel` component. */
export default Vue.extend({
  name: "CarPin",
  props: {
    location: Object as PropType<Coordinate | TripLocation>,
    id: String as PropType<string | undefined>,
  },
  components: {
    LMarker,
    LIcon,
  },
  methods: {
    pinHoverEnter() {
      this.iconSize = [70, 70];
    },
    pinHoverLeave() {
      this.iconSize = [60, 60];
    },
    showFleetVehicleDetails() {
      if (this.id) {
        this.$store.commit(MutationTypes.setViewedFleetVehicle, this.id);
        this.$store.commit(
          MutationTypes.setMainDialogContent,
          MainDialogContent.FLEET_VEHICLE_DETAILS
        );
      }
    },
    latLng(): [number, number] {
      if (this.location && this.location instanceof TripLocation)
        return this.location.coordinates.asLatLng;
      if (this.location && this.location instanceof Coordinate)
        return this.location.asLatLng;
      return [0, 0];
    },
  },
  data() {
    return {
      iconSize: [60, 60],
      icon: getAssetSrc("car_images/luxury-car.svg"),
    };
  },
});
</script>
<style scoped>
.pwt-car-shadow {
  -webkit-filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
  filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.8));
}
</style>
