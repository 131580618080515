interface standardEfficiencyBackupData {
  id: string;
  legacyId?: number;
  standardEfficiency: number;
}

const hardcodedStandardEfficiencyBackup: standardEfficiencyBackupData[] = [
  {
    id: "a278e942-acbe-43f5-8d16-449ba83814dc",
    legacyId: 34,
    standardEfficiency: 6.194332424,
  },
  {
    id: "a1f110bf-1727-4f3b-99b9-052bc34ede45",
    legacyId: 36,
    standardEfficiency: 6.194332424,
  },
  {
    id: "ab1cec0f-13ec-469e-9c96-d101a5f3e310",
    legacyId: 37,
    standardEfficiency: 6.117676409,
  },
  {
    id: "57f19b7f-384a-403a-95ae-7d0bc1fc9bda",
    legacyId: 38,
    standardEfficiency: 6.058140917,
  },
  {
    id: "e127d586-52a9-4f98-8159-ae3342717a1e",
    legacyId: 39,
    standardEfficiency: 5.934087811,
  },
  {
    id: "e732abc1-6719-4dbd-9c53-b7b5d70bc855",
    legacyId: 40,
    standardEfficiency: 5.934087811,
  },
  {
    id: "c362eed6-6c58-4fac-9646-7c5134fab6c2",
    legacyId: 41,
    standardEfficiency: 5.69869065,
  },
  {
    id: "baada812-ec7e-4f96-9319-48bf56e755ff",
    legacyId: 42,
    standardEfficiency: 5.243861722,
  },
  {
    id: "dc93c783-a287-4576-bba3-ec89b64f4640",
    legacyId: 43,
    standardEfficiency: 5.243861722,
  },
  {
    id: "34a093f4-4e2f-41c2-8b65-a08d293abeea",
    legacyId: 44,
    standardEfficiency: 5.243861722,
  },
  {
    id: "42c2fabc-09ad-4808-a2b8-71a9eaa7f3cf",
    legacyId: 45,
    standardEfficiency: 5.243861722,
  },
  {
    id: "74172c54-28d4-4870-9fd4-73d9c09db45f",
    legacyId: 46,
    standardEfficiency: 5.433756012,
  },
  {
    id: "21327ef7-722f-44b5-9f86-2d927382e4d2",
    legacyId: 47,
    standardEfficiency: 5.433756012,
  },
  {
    id: "0e038b10-0e2c-4eee-8e63-df45eae0b3aa",
    legacyId: 48,
    standardEfficiency: 5.212571295,
  },
  {
    id: "0464c1c6-d044-41d1-927b-00e055ca3aa1",
    legacyId: 49,
    standardEfficiency: 5.36493165,
  },
  {
    id: "7eceea99-785c-4231-9748-459b3986161d",
    legacyId: 50,
    standardEfficiency: 5.212571295,
  },
  {
    id: "29319abd-2795-439a-9c80-4750224a28d6",
    legacyId: 51,
    standardEfficiency: 5.36493165,
  },
  {
    id: "e53724d9-70e0-4dff-a79e-75b18293757d",
    legacyId: 52,
    standardEfficiency: 5.212571295,
  },
  {
    id: "65982d1f-dc7f-4ab1-8ab0-432f4af46883",
    legacyId: 53,
    standardEfficiency: 5.183131822,
  },
  {
    id: "52a3b86f-9ba6-4a98-a8a4-78889632fdbc",
    legacyId: 54,
    standardEfficiency: 5.056214472,
  },
  {
    id: "1a6c1e3b-2ca5-4dca-aa4f-94fbde85b21b",
    legacyId: 55,
    standardEfficiency: 5.176634819,
  },
  {
    id: "83433181-020f-4a78-9626-cf6b2d21e56b",
    legacyId: 56,
    standardEfficiency: 5.176634819,
  },
  {
    id: "a66cd527-4dbd-4f21-ba75-c7c69ce92d63",
    legacyId: 57,
    standardEfficiency: 4.978499025,
  },
  {
    id: "6465580c-afa5-4268-903a-2d1806b31965",
    legacyId: 58,
    standardEfficiency: 5.163689555,
  },
  {
    id: "8cd322c4-489d-43a7-b9b3-dceb5ec0ee79",
    legacyId: 59,
    standardEfficiency: 5.037711,
  },
  {
    id: "31c9def9-12ae-4b25-866f-a4f9cc9cc6b4",
    legacyId: 60,
    standardEfficiency: 4.962049043,
  },
  {
    id: "591faef2-ee32-4792-b61b-75ef0b82ceb1",
    legacyId: 61,
    standardEfficiency: 4.962049043,
  },
  {
    id: "7e5f1325-38dd-4d7f-8ddc-2704c7522882",
    legacyId: 62,
    standardEfficiency: 5.037711,
  },
  {
    id: "189513d3-eaac-4816-83cc-b06354d28ac2",
    legacyId: 63,
    standardEfficiency: 5.08616288,
  },
  {
    id: "3d7047b6-a038-4271-88bb-71151811629b",
    legacyId: 64,
    standardEfficiency: 5.08616288,
  },
  {
    id: "a65883dd-734b-40cb-86b9-fee20efbbff8",
    legacyId: 65,
    standardEfficiency: 5.037711,
  },
  {
    id: "b0fda137-1ace-42a3-8b24-3b1337618a40",
    legacyId: 66,
    standardEfficiency: 4.653253562,
  },
  {
    id: "abdc6240-15ae-4cb8-b4a1-66d111f1c258",
    legacyId: 67,
    standardEfficiency: 4.653253562,
  },
  {
    id: "8f151ba1-3a56-4552-aed9-0010d839af06",
    legacyId: 68,
    standardEfficiency: 4.614595911,
  },
  {
    id: "1e20558f-de33-4352-a9ba-cb3601930fc2",
    legacyId: 69,
    standardEfficiency: 4.539176045,
  },
  {
    id: "9ce85cf0-4003-4528-8591-ce0edc6ee246",
    legacyId: 70,
    standardEfficiency: 4.478184058,
  },
  {
    id: "b23f61d2-e40b-4e2d-b71b-336cadad8944",
    legacyId: 71,
    standardEfficiency: 4.455434686,
  },
  {
    id: "692b49c4-4edc-4417-92ee-2b015e562ab2",
    legacyId: 72,
    standardEfficiency: 4.396657802,
  },
  {
    id: "db5f741f-edd7-4a77-bf58-68ddd540c86a",
    legacyId: 73,
    standardEfficiency: 4.455434686,
  },
  {
    id: "fabf0880-8eff-448c-8491-3f59e4660de7",
    legacyId: 74,
    standardEfficiency: 4.455434686,
  },
  {
    id: "b7ab70d8-2102-4d20-b6e0-c508ee1e9f2f",
    legacyId: 75,
    standardEfficiency: 4.412831928,
  },
  {
    id: "33fa8aec-84eb-47b5-9e7d-c874d3673569",
    legacyId: 76,
    standardEfficiency: 3.113101995,
  },
  {
    id: "d72b9901-b671-4854-a559-9669ecdfe307",
    legacyId: 77,
    standardEfficiency: 3.113101995,
  },
  {
    id: "ef46115a-fbd9-4c75-86a6-e9c82c30a5e8",
    legacyId: 78,
    standardEfficiency: 3.113101995,
  },
  {
    id: "a6dccb06-c819-4b0f-805b-08ed37c14c99",
    legacyId: 79,
    standardEfficiency: 5.684789657,
  },
  {
    id: "615e75a7-7724-4062-a8b4-8fb20fa6443f",
    legacyId: 80,
    standardEfficiency: 10.74473808,
  },
  {
    id: "9e4213d3-bf7a-4f48-86e8-ec64f5072f37",
    legacyId: 81,
    standardEfficiency: 10.74473808,
  },
  {
    id: "b185388e-c01a-44d7-90eb-8fe2047f3809",
    legacyId: 82,
    standardEfficiency: 10.74473808,
  },
  {
    id: "1a241dc3-1abc-4e55-b65c-6986f6aa739a",
    legacyId: 83,
    standardEfficiency: 3.965443476,
  },
  {
    id: "9cd3c094-f517-47cf-a135-34a7adc5fb47",
    legacyId: 84,
    standardEfficiency: 3.842528461,
  },
  {
    id: "2042aba0-7319-4b64-a312-243c61030a03",
    legacyId: 85,
    standardEfficiency: 3.988729118,
  },
  {
    id: "ed23c290-1100-4e00-b1ff-065f597066b9",
    legacyId: 86,
    standardEfficiency: 6.64389882,
  },
  {
    id: "16461e7f-5b6b-49ce-a00b-690661cd8b31",
    legacyId: 87,
    standardEfficiency: 6.32586995,
  },
  {
    id: "d332a768-3753-470e-82fb-7e7bc4ac5dd7",
    legacyId: 88,
    standardEfficiency: 6.265883132,
  },
  {
    id: "575bda8b-ad49-4c0b-86a9-3ad0ba278317",
    legacyId: 89,
    standardEfficiency: 6.281055228,
  },
  {
    id: "d3b4be11-e2f7-4a8a-96d3-1a39eab201af",
    legacyId: 90,
    standardEfficiency: 5.624266434,
  },
  {
    id: "a86468b1-808f-4291-8be5-db002c519303",
    legacyId: 91,
    standardEfficiency: 4.188638945,
  },
  {
    id: "8f0c6a82-b4fc-4a72-ac9e-914536a324f6",
    legacyId: 92,
    standardEfficiency: 3.899173727,
  },
  {
    id: "344e0950-56f3-4f4c-b75d-179a5fc78488",
    legacyId: 93,
    standardEfficiency: 5.501581998,
  },
  {
    id: "8c439707-8356-4e77-8108-ba582d77d7d5",
    legacyId: 94,
    standardEfficiency: 5.518121966,
  },
  {
    id: "9801eaaa-0fa5-4385-899e-9cda175685db",
    legacyId: 95,
    standardEfficiency: 6.148380068,
  },
  {
    id: "98edeb3e-fab7-4c41-8b28-7a79e10bc997",
    legacyId: 96,
    standardEfficiency: 6.585746004,
  },
  {
    id: "aa3f2dca-954b-43c7-824c-bdcd8538102e",
    legacyId: 97,
    standardEfficiency: 4.737804566,
  },
  {
    id: "d8109e82-b7eb-4aba-b4d4-1e50f8cfb957",
    legacyId: 98,
    standardEfficiency: 6.258849038,
  },
  {
    id: "d81b6f44-1a55-4492-b0f0-cffb1ff3de8d",
    legacyId: 99,
    standardEfficiency: 4.716302539,
  },
  {
    id: "43061683-e166-4d29-9257-53e6e54dae1f",
    legacyId: 100,
    standardEfficiency: 4.586493119,
  },
  {
    id: "e4b3c026-7815-41a9-a77f-2eaaf2b1b9c7",
    legacyId: 101,
    standardEfficiency: 4.925404761,
  },
  {
    id: "15a96d70-07e1-44db-a393-cd867e0dd342",
    legacyId: 102,
    standardEfficiency: 4.784002254,
  },
  {
    id: "c149a86a-d416-4401-9e73-9f38eb72f097",
    legacyId: 103,
    standardEfficiency: 5.86139398,
  },
  {
    id: "f958ed1c-b71c-401b-b1c6-735672aae872",
    legacyId: 104,
    standardEfficiency: 6.854024396,
  },
  {
    id: "d143638a-a3e5-4eb8-bf93-3dca663a1dbb",
    legacyId: 105,
    standardEfficiency: 6.583057282,
  },
  {
    id: "6c3a8243-ea1a-40f1-8475-9d43604c6f13",
    legacyId: 106,
    standardEfficiency: 5.878830662,
  },
  {
    id: "815bab8c-a1d9-4c71-be46-e6c770fc0149",
    legacyId: 107,
    standardEfficiency: 5.583368218,
  },
  {
    id: "2a43647a-114f-4684-b5b0-3ec7fa86b87d",
    legacyId: 108,
    standardEfficiency: 4.57091759,
  },
  {
    id: "bfb63d50-d1e6-41a6-a92f-b22e91ea96fe",
    legacyId: 109,
    standardEfficiency: 5.610417498,
  },
  {
    id: "8323d384-75a1-4e59-8044-d96163c2bf8c",
    legacyId: 110,
    standardEfficiency: 5.461476561,
  },
  {
    id: "5cb3a1fc-8623-4ff8-89a4-5766ba690080",
    legacyId: 111,
    standardEfficiency: 5.363565193,
  },
  {
    id: "4b3a62f1-f37c-44bf-b9f2-5425eedf480d",
    legacyId: 112,
    standardEfficiency: 5.404371087,
  },
  {
    id: "ff669e93-7ba9-4546-9cd5-af0dc60d12b8",
    legacyId: 113,
    standardEfficiency: 5.404371087,
  },
  {
    id: "ca11bbca-8be6-4853-b9b7-bf069bb3f432",
    legacyId: 114,
    standardEfficiency: 5.2200415,
  },
  {
    id: "ff0e7899-a588-4360-8d14-ea790ef0d551",
    legacyId: 115,
    standardEfficiency: 5.078455784,
  },
  {
    id: "f012a9d7-cac7-4b9d-a39c-94badc12db4d",
    legacyId: 116,
    standardEfficiency: 8.529567476,
  },
  {
    id: "7bc99c15-2ace-4d51-b0dc-06fd3603d9da",
    legacyId: 117,
    standardEfficiency: 5.260645002,
  },
  {
    id: "d2ba9162-83c6-4769-bb62-fe172e261951",
    legacyId: 118,
    standardEfficiency: 5.168181698,
  },
  {
    id: "6ea2533a-8800-468b-aac5-74ce0da4f28a",
    legacyId: 119,
    standardEfficiency: 4.1165928,
  },
  {
    id: "8351af02-f658-47b6-a9de-322be939b34d",
    legacyId: 120,
    standardEfficiency: 5.681344516,
  },
  {
    id: "dd19c1bc-4418-48b5-a057-0408d6deca45",
    legacyId: 121,
    standardEfficiency: 2.661902047,
  },
  {
    id: "e05d1019-9d7f-4803-8d16-d1399e195222",
    legacyId: 122,
    standardEfficiency: 2.689495773,
  },
  {
    id: "802dc095-28a3-4b3c-b2fb-7533e52a9af2",
    legacyId: 123,
    standardEfficiency: 3.755154084,
  },
  {
    id: "6b0740db-a239-4c32-885b-6efed656a3cb",
    legacyId: 124,
    standardEfficiency: 4.922744487,
  },
  {
    id: "19b68d00-e7d5-42f0-b9b8-d5e8498e9a44",
    legacyId: 125,
    standardEfficiency: 6.064749766,
  },
  {
    id: "ab6ae0d1-3d81-4cf5-bf55-e431c902b406",
    legacyId: 126,
    standardEfficiency: 6.585746004,
  },
  {
    id: "d70f81b2-9ac9-482f-91eb-7a15f59e97f0",
    legacyId: 127,
    standardEfficiency: 5.099786567,
  },
  {
    id: "ef48ef7e-590f-487b-ab12-92b4ee3bf577",
    legacyId: 128,
    standardEfficiency: 5.013118078,
  },
  {
    id: "46d4b136-22fa-4d7c-a9e7-4171f2481bf2",
    legacyId: 129,
    standardEfficiency: 5.495958121,
  },
  {
    id: "70234867-4651-416f-a86c-f2bcb0705cbd",
    legacyId: 130,
    standardEfficiency: 6.048178152,
  },
  {
    id: "e63cde58-868f-45e2-87aa-65a9add6c73f",
    legacyId: 131,
    standardEfficiency: 5.986803244,
  },
  {
    id: "2bde64a2-9b93-4492-ae13-5dbf9d21d2a7",
    legacyId: 132,
    standardEfficiency: 5.645328707,
  },
  {
    id: "776ccc2c-8458-4718-af68-97bc8ad56ddd",
    legacyId: 133,
    standardEfficiency: 5.350423101,
  },
  {
    id: "3fc0e0ac-de84-4d10-8e7c-38e34c595b51",
    legacyId: 134,
    standardEfficiency: 5.636011604,
  },
  {
    id: "abdffbc6-a5bf-46ec-84d2-61c39a2edaf6",
    legacyId: 135,
    standardEfficiency: 5.958064834,
  },
  {
    id: "2d3f57e5-cc69-42bb-85c8-f0b9f4cf0796",
    legacyId: 136,
    standardEfficiency: 5.452501193,
  },
  {
    id: "3af2e6fd-57d8-4f4b-895b-ff10eb86bdb5",
    legacyId: 137,
    standardEfficiency: 6.10870891,
  },
  {
    id: "2e62c3c4-5f45-4308-b420-425f46899067",
    legacyId: 138,
    standardEfficiency: 4.674556804,
  },
  {
    id: "e619636b-ad67-4e66-a078-bcfd76bb0f00",
    legacyId: 139,
    standardEfficiency: 6.585746004,
  },
  {
    id: "77dd0a0a-54dc-4498-aeee-e55f9fcc1d46",
    legacyId: 140,
    standardEfficiency: 4.933263105,
  },
  {
    id: "7407d36b-0184-46e9-a3e5-78521f38d910",
    legacyId: 141,
    standardEfficiency: 4.809474766,
  },
  {
    id: "a6877d75-7965-4734-8e2f-b63c3fbf03c2",
    legacyId: 142,
    standardEfficiency: 4.698935589,
  },
  {
    id: "564bdffc-bc42-42e3-ae4d-df21a2cb66d2",
    legacyId: 143,
    standardEfficiency: 4.584456853,
  },
  {
    id: "2016a792-8ab6-4160-8dda-19941fea1d73",
    legacyId: 144,
    standardEfficiency: 6.026182861,
  },
  {
    id: "8e125531-ea15-48c6-bd7f-b581365a3e29",
    legacyId: 145,
    standardEfficiency: 6.026182861,
  },
  {
    id: "7c62b149-2c33-456a-b291-2f4d3578e129",
    legacyId: 146,
    standardEfficiency: 6.071291384,
  },
  {
    id: "b4a57fe2-dc2a-47b0-9165-d88c74c889f2",
    legacyId: 147,
    standardEfficiency: 6.016014542,
  },
  {
    id: "e206e220-8596-4431-b60e-42731f37f297",
    legacyId: 148,
    standardEfficiency: 6.060154901,
  },
  {
    id: "0cc619ff-5701-4701-874f-e758b436e50b",
    legacyId: 149,
    standardEfficiency: 5.024022475,
  },
  {
    id: "76605984-5d16-4e97-ad74-836c2afa82cb",
    legacyId: 150,
    standardEfficiency: 5.009050012,
  },
  {
    id: "e4289242-98d8-455e-a5f0-65113fd77880",
    legacyId: 151,
    standardEfficiency: 3.751636616,
  },
  {
    id: "c1ba1a45-a399-432d-8c42-3cb37f84b446",
    legacyId: 152,
    standardEfficiency: 5.151909826,
  },
  {
    id: "febb594c-af28-4699-bd02-7305f403718a",
    legacyId: 153,
    standardEfficiency: 6.597789469,
  },
  {
    id: "38764982-913b-4269-a5c9-62b1fc0df2a3",
    legacyId: 154,
    standardEfficiency: 5.554998207,
  },
  {
    id: "d423478b-b70c-4c3a-a2ba-467962a0dd67",
    legacyId: 155,
    standardEfficiency: 6.795778373,
  },
  {
    id: "f4c5f73b-8677-4950-a439-011ffc5ac7b4",
    legacyId: 156,
    standardEfficiency: 6.275781444,
  },
  {
    id: "4f0379a7-6386-40fc-96c6-2b40228f4623",
    legacyId: 157,
    standardEfficiency: 6.657605985,
  },
  {
    id: "794a6ff0-2301-4319-85ab-50c304b82ea0",
    legacyId: 158,
    standardEfficiency: 6.317478768,
  },
  {
    id: "40b393bd-3925-4e97-a4f9-ff7ac7dfc06c",
    legacyId: 159,
    standardEfficiency: 5.474822085,
  },
  {
    id: "cd39603d-1829-4342-946e-1d893c57a3d3",
    legacyId: 160,
    standardEfficiency: 5.509977727,
  },
  {
    id: "ad01fa34-b7da-4c87-8e78-d3f7ca31026c",
    legacyId: 161,
    standardEfficiency: 6.243062247,
  },
  {
    id: "1e3eddf4-4379-4551-b204-c1d326d52add",
    legacyId: 162,
    standardEfficiency: 6.589865568,
  },
  {
    id: "0f9fe2d7-1630-446d-9e45-0acb43780790",
    legacyId: 163,
    standardEfficiency: 5.866147964,
  },
  {
    id: "eec31e9c-0999-4a01-b5f1-4cf68563d78f",
    legacyId: 164,
    standardEfficiency: 5.73097795,
  },
  {
    id: "f8f64683-c822-4531-8d25-e06e4a41c255",
    legacyId: 165,
    standardEfficiency: 5.529402959,
  },
  {
    id: "0e931fbb-1280-43b6-93c3-a6b0990b0b19",
    legacyId: 166,
    standardEfficiency: 4.580727459,
  },
  {
    id: "ddb49bd4-38df-4bc3-a683-65cb86a384fe",
    legacyId: 167,
    standardEfficiency: 6.666660248,
  },
  {
    id: "b6601ccb-ef46-4f87-afb5-06b114d4c398",
    legacyId: 168,
    standardEfficiency: 6.593894392,
  },
  {
    id: "404a8658-5ac1-4373-9dbd-7480d11ccb20",
    legacyId: 169,
    standardEfficiency: 5.818203721,
  },
  {
    id: "5108fb2a-4c90-406e-a90a-55321057dc59",
    legacyId: 170,
    standardEfficiency: 12.95424849,
  },
  {
    id: "1c3c46ea-4cdd-4421-9397-fc76c8ccc9ae",
    legacyId: 171,
    standardEfficiency: 12.16891477,
  },
  {
    id: "60836202-3f9d-4232-9254-77dfd368fec2",
    legacyId: 172,
    standardEfficiency: 5.870280967,
  },
  {
    id: "ffcdcba1-45fa-4366-84fb-ce05ae0f848b",
    legacyId: 173,
    standardEfficiency: 5.480243963,
  },
  {
    id: "f3077bdc-c1fd-4c3c-9e17-6f14c6408f91",
    legacyId: 174,
    standardEfficiency: 5.278387455,
  },
  {
    id: "1ce567da-0b07-455e-a42f-5ab51b6ef343",
    legacyId: 175,
    standardEfficiency: 5.853362807,
  },
  {
    id: "f754deec-a9a1-445d-b362-554178d662b4",
    legacyId: 176,
    standardEfficiency: 4.904988096,
  },
  {
    id: "df7fc185-1824-4123-953e-2be765017d43",
    legacyId: 177,
    standardEfficiency: 4.904988096,
  },
  {
    id: "bbbb686f-b278-4a33-968b-b22334155772",
    legacyId: 178,
    standardEfficiency: 4.382543296,
  },
  {
    id: "c5551729-ebc7-47c9-b206-a8a7bf9efd08",
    legacyId: 179,
    standardEfficiency: 4.475195986,
  },
  {
    id: "2c7e5e17-3e9a-428c-ad7f-5b40bb133371",
    legacyId: 180,
    standardEfficiency: 5.153002741,
  },
  {
    id: "920203d1-342d-49e1-bc17-1e4c8138f1b1",
    legacyId: 181,
    standardEfficiency: 5.153002741,
  },
  {
    id: "138d63d3-72fb-4957-8e01-b464ff64a7bf",
    legacyId: 182,
    standardEfficiency: 5.174934673,
  },
  {
    id: "291c7147-7aa3-40f4-9861-e29cb9b632b7",
    legacyId: 183,
    standardEfficiency: 4.942496912,
  },
  {
    id: "6b1faa7c-9844-46df-8d45-7816028d2517",
    legacyId: 184,
    standardEfficiency: 4.897339001,
  },
  {
    id: "943810a9-e206-43fe-ae1b-0160abe62186",
    legacyId: 185,
    standardEfficiency: 4.710827038,
  },
  {
    id: "802c27fc-8e6b-4634-86a1-a86ae6ae2463",
    legacyId: 186,
    standardEfficiency: 4.020384925,
  },
  {
    id: "2d37fdb8-fd79-4d8a-9184-2ebe45c702bc",
    legacyId: 187,
    standardEfficiency: 4.952764008,
  },
  {
    id: "ed4f1b8d-c660-4cec-97a7-a1af73697d4e",
    legacyId: 188,
    standardEfficiency: 4.783533849,
  },
  {
    id: "00c7fe1a-ae66-48a3-92a8-d1d39abe9c27",
    legacyId: 189,
    standardEfficiency: 6.342222493,
  },
  {
    id: "f0931c30-4d4d-43b7-893b-0394e6f45bbc",
    legacyId: 190,
    standardEfficiency: 5.064577149,
  },
  {
    id: "c36bd2a5-a7de-43b5-9fda-c2a337ffea49",
    legacyId: 191,
    standardEfficiency: 4.922451631,
  },
  {
    id: "3ddf6c3d-cec5-43c7-8118-5e6d7818c100",
    legacyId: 192,
    standardEfficiency: 4.781216205,
  },
  {
    id: "445952c9-2a4b-4ea2-9272-fca50dcd7f73",
    legacyId: 193,
    standardEfficiency: 4.937035459,
  },
  {
    id: "1ecadb25-7198-4938-b6a2-90d6e7a9eb3f",
    legacyId: 194,
    standardEfficiency: 2.976785325,
  },
  {
    id: "df2b350b-1452-4a31-abde-6e7dadf4a9b6",
    legacyId: 195,
    standardEfficiency: 4.924359098,
  },
  {
    id: "4023fd62-4525-4262-8a00-524a1f3ea81e",
    legacyId: 196,
    standardEfficiency: 4.77803853,
  },
  {
    id: "6aabe7ed-0154-4553-9720-335fc1a0878f",
    legacyId: 197,
    standardEfficiency: 2.660467088,
  },
  {
    id: "fd4e3da6-c9e4-45a0-a302-be614c319123",
    legacyId: 198,
    standardEfficiency: 11.74869091,
  },
  {
    id: "90b9b9ac-688a-4735-8bfe-4ffc2b26f7cc",
    legacyId: 199,
    standardEfficiency: 4.556385147,
  },
  {
    id: "04c74ee2-5f1d-4912-a10e-1cd449450dd0",
    legacyId: 200,
    standardEfficiency: 3.41488086,
  },
  {
    id: "27e13949-b728-4a4f-b488-ab64116466c8",
    legacyId: 201,
    standardEfficiency: 3.448544332,
  },
  {
    id: "c330e6f5-7248-4093-8d47-54afec058e7c",
    legacyId: 202,
    standardEfficiency: 5.338589215,
  },
  {
    id: "2e5134a9-9b03-4713-89ff-d1056099edbe",
    legacyId: 203,
    standardEfficiency: 5.174243027,
  },
  {
    id: "99ea5bae-6d12-4c75-86ea-8640c498b9c0",
    legacyId: 204,
    standardEfficiency: 5.004766469,
  },
  {
    id: "8cfea268-bdf3-4083-9d0e-86c9d470d4de",
    legacyId: 205,
    standardEfficiency: 5.309717743,
  },
  {
    id: "9d88fa06-91f9-45e2-a4ae-b763e37005d0",
    legacyId: 206,
    standardEfficiency: 5.131403278,
  },
  {
    id: "1cc6eef3-f019-417c-a19f-61486aeadc3f",
    legacyId: 207,
    standardEfficiency: 4.972019336,
  },
  {
    id: "ed01e9c1-4ed9-4829-b833-fc711bf3cc20",
    legacyId: 208,
    standardEfficiency: 4.81163713,
  },
  {
    id: "ab8e626e-fe81-4051-b0dd-a76634a5eec7",
    legacyId: 209,
    standardEfficiency: 5.316617013,
  },
  {
    id: "b2d5ef3a-465a-454d-b3b8-050c742f492f",
    legacyId: 210,
    standardEfficiency: 5.198458792,
  },
  {
    id: "f24843aa-78d8-4daa-bc7d-042f6bff309d",
    legacyId: 211,
    standardEfficiency: 4.73281405,
  },
  {
    id: "ff8984c2-82a0-4cc4-9c18-43942b5010c8",
    legacyId: 212,
    standardEfficiency: 5.400402998,
  },
  {
    id: "97a7343e-fac0-4430-af3b-cee80c3056ce",
    legacyId: 213,
    standardEfficiency: 1.267439438,
  },
  {
    id: "8be07a8b-9159-49aa-b983-d34ec69eff25",
    legacyId: 214,
    standardEfficiency: 1.238162074,
  },
  {
    id: "f70044f3-6b91-4d17-b3ee-36d9a3f9c9a7",
    legacyId: 215,
    standardEfficiency: 1.315145279,
  },
  {
    id: "5d68673a-4506-4b43-bbb2-77e3e8e6b2d8",
    legacyId: 216,
    standardEfficiency: 3.052058723,
  },
  {
    id: "93c4983a-465d-4eae-8932-9b4b90428468",
    legacyId: 217,
    standardEfficiency: 2.963669649,
  },
  {
    id: "89f286a1-81a0-45cb-bbc7-55702d69e9e5",
    legacyId: 218,
    standardEfficiency: 2.870158353,
  },
];

export default hardcodedStandardEfficiencyBackup;
