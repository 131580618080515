var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',{staticClass:"rounded-lg",class:{
    'pl-0': _vm.removeLeftPadding,
    'pr-0': _vm.removeRightPadding,
  },attrs:{"ripple":"","flat":_vm.flat,"disabled":_vm.disabled,"loading":_vm.loading,"color":"background","max-width":"90px"},on:{"click":function($event){return _vm.$emit('click')}}},[_c('v-card-text',{staticClass:"pb-2",class:{
      'pl-0': _vm.removeLeftPadding,
      'pr-0': _vm.removeRightPadding,
    }},[_c('v-card',{staticClass:"d-flex justify-center align-center rounded-lg",attrs:{"color":_vm.color,"width":"50px","height":"50px","flat":""}},[_c('v-icon',{attrs:{"color":_vm.iconColor,"size":_vm.iconSize}},[_vm._v(_vm._s(_vm.icon))])],1)],1),_c('v-card-text',{staticClass:"text-center pt-0",class:{
      'pl-0': _vm.removeLeftPadding,
      'pr-0': _vm.removeRightPadding,
    }},[_c('span',{staticClass:"text-caption"},[_vm._v(_vm._s(_vm.label))])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }