/**
 * Converts a query value to a string.
 *
 * @param {string | (string | null)[]} value - The query value to convert.
 * @return {string | undefined} The converted string value or undefined if the input is falsy.
 */
export default function queryValueToString(
  value: string | (string | null)[]
): string | undefined {
  const result = value
    ? Array.isArray(value)
      ? value.join(" ")
      : value
    : undefined;
  return result ? decodeURI(result) : undefined;
}
