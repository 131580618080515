/**
 * Calculates the time in hours to charge a given battery using a DC charger.
 *
 * @param {number} startCharge - how much charge at start in kWh
 * @param {number} endCharge - how much charge at end in kWh
 * @param {number} accessibleBattery - max number of kWh battery can currently hold
 * @param {number} chargeRate - min of either what car can accept or what charging station can output, in kW
 * @returns time in hours to charge
 */
export function timeWithDCCurve(
  startCharge: number,
  endCharge: number,
  accessibleBattery: number,
  chargeRate: number
): number | undefined {
  // exit fast if no charging
  if (startCharge === endCharge) return 0;
  if (chargeRate === 0) return 0;

  // exit fast if invalid input
  if (startCharge > endCharge) return undefined;
  // if (startCharge < 0 || endCharge < 0) return undefined;
  if (chargeRate < 0) return undefined;
  if (accessibleBattery < startCharge) return undefined;

  // calculate drop off point
  const dropOff = accessibleBattery * 0.8; //assume drop off point when battery reaches 80% charge
  // calculate time
  const time = calculateTime(
    startCharge,
    endCharge,
    dropOff,
    chargeRate,
    accessibleBattery
  );

  return time;
}

/**
 * helper function to support `calcChargeTimeWithDCCurve`,
 *
 * @param {number} currentBattery - how much charge at start in kWh
 * @param {number} targetBattery - how much charge at end in kWh
 * @param {number} dropOff - float representation of % of batter where charging slows down e.g. 0.8 at 80%.
 * @param {number} chargingMax - min of either what car can accept or what charging station can output, in kW
 * @param {number} accessibleBattery - max number of kWh battery can currently hold
 * @returns time in hours to charge
 */
function calculateTime(
  currentBattery: number,
  targetBattery: number,
  dropOff: number,
  chargingMax: number,
  accessibleBattery: number
): number | undefined {
  const minC = chargingMax * 0.25; //assume charger slows to 25% of max, this values influences the slowdown rate of the charger

  const m = (minC - chargingMax) / (accessibleBattery - dropOff); //slowdown rate of charger
  const c = chargingMax - m * dropOff;

  if (targetBattery <= dropOff) {
    const time = targetBattery / chargingMax - currentBattery / chargingMax;
    return time;
  } else if (currentBattery < dropOff && targetBattery > dropOff) {
    const time1 = dropOff / chargingMax - currentBattery / chargingMax;
    const time2 =
      (1 / m) *
      (Math.log(Math.abs(-m * targetBattery - c)) -
        Math.log(Math.abs(-m * dropOff - c)));

    return time1 + time2;
  } else if (currentBattery >= dropOff) {
    // reflect the slowdown rate with increasing time

    const time =
      (1 / m) *
      (Math.log(Math.abs(-m * targetBattery - c)) -
        Math.log(Math.abs(-m * currentBattery - c)));

    return time;
  }
}

/**
 * Calculates the time in hours to charge a given battery using a AC charger.
 *
 * @param {number} startCharge - how much charge at start in kWh
 * @param {number} endCharge - how much charge at end in kWh
 * @param {number} chargeRate  - min of either what car can accept or what charging station can output, in kW
 * @returns time in hours to charge
 */
export function calcChargingTimeAC(
  startCharge: number,
  endCharge: number,
  chargeRate: number
): number | undefined {
  // exit fast if no charging
  if (startCharge === endCharge) return 0;
  if (chargeRate === 0) return 0;

  // exit fast if invalid input
  if (startCharge > endCharge) return undefined;
  // if (startCharge < 0 || endCharge < 0) return undefined;
  if (chargeRate < 0) return undefined;

  const energy = endCharge - startCharge;

  const time = energy / chargeRate;

  return time;
}
