<template>
  <v-carousel
    v-model="slide"
    height="unset"
    hide-delimiters
    :show-arrows="groups.length > 1"
    class="pb-5 px-3"
  >
    <v-carousel-item
      v-for="(group, index) in groups"
      :key="`slide-group-card-${index}`"
    >
      <v-card flat :class="groups.length > 1 ? 'px-15' : ''">
        <v-row>
          <v-col
            v-for="(UUID, index) in group"
            cols="4"
            :key="`slide-group-image-card-${index}`"
            style="max-height: 300px"
          >
            <v-card
              v-if="UUID === 'default'"
              class="rounded-lg secondary d-flex justify-center align-center"
              style="height: 100%; min-height: 120px"
              @click="showUploader"
            >
              <v-icon dark x-large>mdi-camera-plus</v-icon>
            </v-card>
            <v-img
              v-else
              :lazy-src="getSrc('car_images/No_Image_Powersell.png')"
              :src="UUID"
              width="100%"
              height="auto"
              contain
            />
          </v-col>
        </v-row>
      </v-card>
    </v-carousel-item>
    <v-dialog v-model="showingUploader" max-width="300">
      <v-card class="rounded-lg">
        <v-card-title class="d-flex justify-space-between">
          <span> Upload an image </span>
          <v-btn icon @click="hideUploader">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </v-card-title>
        <template v-if="imageUploadStatus">
          <v-card-text
            v-if="imageUploadStatus === 'PROCESSING'"
            class="d-flex flex-column justify-center align-center"
          >
            <LoadingCard />
            <v-row no-gutters class="align-center">
              Processing <PulseLoader />
            </v-row>
          </v-card-text>
          <v-card-text
            v-else-if="imageUploadStatus === 'SUCCESS'"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon color="success" x-large>mdi-check-circle</v-icon>
            <span>Successfully uploaded</span>
          </v-card-text>
          <v-card-text
            v-else-if="imageUploadStatus === 'FAILED'"
            class="d-flex flex-column justify-center align-center"
          >
            <v-icon color="error" x-large>mdi-alert-circle</v-icon>
            <span>Failed to uploaded</span>
          </v-card-text>
        </template>
        <template v-else>
          <v-card-text class="pr-8 pb-0">
            <v-file-input
              label="select image to upload"
              accept="image/*"
              :error-messages="errorMsg"
              @click="clearError"
              v-model="imageFile"
            ></v-file-input>
          </v-card-text>
          <v-card-actions class="d-flex justify-center pb-5">
            <ElevatedBtn @click="uploadImage"> Upload image </ElevatedBtn>
          </v-card-actions>
        </template>
      </v-card>
    </v-dialog>
  </v-carousel>
</template>
<script lang="ts">
import Vue from "vue";
import { mapGetters, mapState } from "vuex";
import PulseLoader from "../../../ui-elements/loaders/PulseLoader.vue";
import LoadingCard from "../../../ui-elements/loaders/LoadingCard.vue";
import Charger from "@/logic/classes/charger_classes/charger";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import {
  ActionTypes,
  GettersTypes,
  type State,
} from "@/logic/store/store_types";
import ElevatedBtn from "@/ui/components/ui-elements/buttons/ElevatedBtn.vue";

export default Vue.extend({
  name: "ChargingStationImageCarousel",
  data() {
    return {
      slide: 0,
      showingUploader: false,
      imageFile: undefined as File | undefined,
      errorMsg: undefined as string | undefined,
    };
  },
  computed: {
    ...mapGetters({
      station: GettersTypes.selectedChargerData,
    }),
    ...mapState({
      imageUploadStatus: (state: unknown) => (state as State).updateImageStatus,
    }),
    groups(): [string, string, string][] {
      const returnArray: [string, string, string][] = [];
      const numberOfGroups = this.getNumberOfGroups(
        this.station?.images?.length ?? 0
      );
      for (let i = 0; i < numberOfGroups; i++) {
        returnArray.push([
          (this.station as Charger)?.images?.[0 + i]?.thumbnail ?? "default",
          (this.station as Charger)?.images?.[0 + i + 1]?.thumbnail ??
            "default",
          (this.station as Charger)?.images?.[0 + i + 2]?.thumbnail ??
            "default",
        ]);
      }
      return returnArray;
    },
  },
  methods: {
    getNumberOfGroups(numberOfImages: number) {
      if (numberOfImages === 0) return 1;
      if (numberOfImages <= 2) return 1;
      // ASSUMES: more than 2 so therefore more than one group
      // NOTE: three images are displayed at once and the final image is the default add new image display
      return numberOfImages - 1;
    },
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
    showUploader() {
      this.showingUploader = true;
    },
    hideUploader() {
      this.showingUploader = false;
    },
    uploadImage() {
      if (!this.imageFile) {
        this.errorMsg = "please select an image";
      } else {
        this.$store.dispatch(ActionTypes.uploadStationImage, this.imageFile);
      }
    },
    clearError() {
      this.errorMsg = undefined;
    },
  },
  components: { PulseLoader, LoadingCard, ElevatedBtn },
  watch: {
    imageUploadStatus(val: "processing" | "success" | "failed" | null) {
      if (val === "success" || val === "failed") {
        setTimeout(() => {
          this.showingUploader = false;
        }, 900);
      }
    },
  },
});
</script>
