const evNavDefaultData = {
  WeatherFactor: 1.0,
  SpeedAdjustment: 0.0,
  AccelerationAdjustment: 0.5,
  Mass: 1500,
  DragCoefficient: 0.6,
  RollingResistanceCoefficient: 0.015,
  RegenerativeBreakingEfficiency: 0.4,
  PowerChainEfficiency: 0.75,
  MaxElectricPowerAc: 3.6,
  MaxElectricPowerDc: 50,
};

export default evNavDefaultData;
