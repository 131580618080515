/** Default data used by the optimiser */
const OptimiserDefaultData = {
  battery: 38,
  modelId: "776ccc2c-8458-4718-af68-97bc8ad56ddd",
  SOCAct: 0.5,
  SOCMin: 0.1,
  SOCMax: 0.9,
  SOCEnd: 0.2,
  relativeSpeed: 0,
  petrolCostPerLitre: 3,
  petrolKmPerLitre: 9.24,
  dieselCostPerLitre: 2.8,
  dieselKmPerLitre: 12,
  roadUserCharges: 76,
  kWhCostHome: 0.26,
  kWhCostPublic: 0.25,
  electricityCostPerMinPublic: 0.25,
  perAnnumMaintenanceCurrentV: 2000,
  perAnnumMaintenanceEV: 500,
};

export default OptimiserDefaultData;
