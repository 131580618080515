<template>
  <v-row id="logo" dark align="center" style="z-index: 1100; height: 70px">
    <span> Powered by </span>
    <v-img
      :src="getSrc('White-Text-SVG.svg')"
      width="100px"
      height="auto"
      max-height="70px"
      class="ml-1"
      contain
    />
  </v-row>
</template>

<script lang="ts">
import getAssetSrc from "@/logic/utils/getAssetSrc";

/** Vue Component: renders the bottom right power trip logo. To be used in the `App` component.*/
export default {
  name: "BottomLogo",
  methods: {
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
  },
};
</script>

<style scoped>
#logo {
  position: absolute;
  bottom: 10px;
  right: 60px;
  color: #ffffff;
  font-size: 1rem;
}
</style>
