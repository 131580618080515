<template>
  <svg
    width="673"
    height="673"
    viewBox="0 0 673 673"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clip-path="url(#clip0_567_16240)" filter="url(#filter0_i_567_16240)">
      <path
        d="M532.932 138.257C527.256 120.411 511.363 107.708 492.632 107.708H180.455C161.724 107.708 146.116 120.411 140.156 138.257L81.126 319.436V561.41C81.126 578.046 93.8969 591.657 109.506 591.657H137.885C153.494 591.657 166.265 578.046 166.265 561.41V531.163H506.822V561.41C506.822 578.046 519.593 591.657 535.202 591.657H563.582C579.191 591.657 591.961 578.046 591.961 561.41V319.436L532.932 138.257ZM180.455 440.423C156.9 440.423 137.885 420.158 137.885 395.053C137.885 369.948 156.9 349.682 180.455 349.682C204.01 349.682 223.025 369.948 223.025 395.053C223.025 420.158 204.01 440.423 180.455 440.423ZM492.632 440.423C469.077 440.423 450.063 420.158 450.063 395.053C450.063 369.948 469.077 349.682 492.632 349.682C516.188 349.682 535.202 369.948 535.202 395.053C535.202 420.158 516.188 440.423 492.632 440.423ZM137.885 289.189L180.455 153.078H492.632L535.202 289.189H137.885Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_567_16240"
        x="0.469727"
        y="0.164551"
        width="702.026"
        height="702.025"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="29.8734" dy="29.8734" />
        <feGaussianBlur stdDeviation="29.8734" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_567_16240"
        />
      </filter>
      <clipPath id="clip0_567_16240">
        <rect
          width="672.152"
          height="672.152"
          fill="white"
          transform="translate(0.469727 0.164551)"
        />
      </clipPath>
    </defs>
  </svg>
</template>
<script lang="ts">
/** Custom icon wrapped in a template to be used by vuetify to gain access to the vuetify component properties*/
export default {
  name: "VehiclesIcon",
};
</script>
