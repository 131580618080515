<template>
  <v-card flat>
    <v-row class="mx-2">
      <!-- conditionally rendered plug images -->
      <v-col
        cols="auto"
        v-for="(connector, index) in filteredArray"
        :key="index"
      >
        <v-img
          :src="connector.getPlugIconSrc"
          width="50"
          height="50"
          contain
          class="mx-auto"
          :style="
            disableGrayScale
              ? ''
              : isCompatible(connector)
                ? ''
                : 'filter: grayscale(1);'
          "
        />
        <v-card-text>{{ connector.displayName }}</v-card-text>
      </v-col>
    </v-row>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapGetters } from "vuex";
import Connector from "@/logic/classes/charger_classes/connector";
import { GettersTypes } from "@/logic/store/store_types";

/** Vue Component: renders plug images and names for use in the `ChargingStationsDetailsContent` component.
 *
 * @props `plugs` - the array of plugs for a given charger found in the `ChargingStation` object.
 * see `chargingStationsStore` for details.
 */
export default Vue.extend({
  name: "PlugsCard",
  props: {
    plugs: Array as PropType<Connector[]>,
    disableGrayScale: {
      type: Boolean as PropType<boolean | undefined>,
      default: false,
    },
  },
  computed: {
    filteredArray() {
      const usedArray: string[] = [];
      const tempArray: Connector[] = [];
      (this.plugs as Connector[]).forEach((connector) => {
        if (connector.standard && connector.format) {
          const usedName = connector.standard + connector.format;
          if (!usedArray.includes(usedName)) {
            tempArray.push(connector);
            usedArray.push(usedName);
          }
        }
      });
      return tempArray;
    },
    ...mapGetters({
      vehicle: GettersTypes.selectedVehicleData,
    }),
  },
  methods: {
    isCompatible(connector: Connector): boolean {
      if (!this.vehicle) return false;
      const compatibility = connector.isCompatible(this.vehicle);
      return compatibility !== "incompatible";
    },
  },
});
</script>
