<template>
  <v-card flat class="pa-5">
    <!-- Recommended EVs section -->
    <v-card-title class="pt-5 tertiary--text" v-if="isShowingTrip">
      Recommended EVs
    </v-card-title>
    <v-card-text v-if="isShowingTrip">
      <SuggestedEVsSection />
    </v-card-text>
    <!-- My Vehicles Section -->
    <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
      <span> My Vehicles </span>
      <OutlinedBtn @click="showCustom"> View All </OutlinedBtn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-if="customVehicles.length"
          :vehicle="customVehicles[0]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="customVehicles.length >= 2"
          :vehicle="customVehicles[1]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="customVehicles.length >= 3"
          :vehicle="customVehicles[2]"
          @select="handleSelect"
        />
      </v-row>
    </v-card-text>
    <!-- Telematics connected vehicles section -->
    <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
      <span> Connected Vehicles </span>
      <OutlinedBtn @click="showConnected"> View All </OutlinedBtn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-if="connectedVehicles.length"
          :vehicle="connectedVehicles[0]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="connectedVehicles.length >= 2"
          :vehicle="connectedVehicles[1]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="connectedVehicles.length >= 3"
          :vehicle="connectedVehicles[2]"
          @select="handleSelect"
        />
      </v-row>
    </v-card-text>
    <!-- Generic Evs -->
    <v-card-title class="d-flex justify-space-between pt-5 tertiary--text">
      <span> Generic EVs </span>
      <OutlinedBtn @click="showGeneric"> View All </OutlinedBtn>
    </v-card-title>
    <v-card-text>
      <v-row>
        <VehicleGalleryCard
          v-if="genericVehicles.length"
          :vehicle="genericVehicles[0]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="genericVehicles.length >= 2"
          :vehicle="genericVehicles[1]"
          @select="handleSelect"
        />
        <VehicleGalleryCard
          v-if="genericVehicles.length >= 3"
          :vehicle="genericVehicles[2]"
          @select="handleSelect"
        />
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script lang="ts">
import Vue from "vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import {
  ActionTypes,
  GettersTypes,
  MainDialogContent,
  MutationTypes,
  type State,
} from "@/logic/store/store_types";
import SuggestedEVsSection from "../info-panel-content/SuggestedEVsSection.vue";
import VehicleGalleryCard from "./VehicleGalleryCard.vue";
import OutlinedBtn from "../../ui-elements/buttons/OutlinedBtn.vue";

export default Vue.extend({
  name: "AllVehiclesGalleryContent",
  computed: {
    connectedVehicles(): Vehicle[] {
      return this.$store.getters[GettersTypes.connectedVehicles];
    },
    genericVehicles(): Vehicle[] {
      return this.$store.getters[GettersTypes.genericVehicles];
    },
    customVehicles(): Vehicle[] {
      return this.$store.getters[GettersTypes.customVehicles];
    },
    rideShareAndRentals(): Vehicle[] {
      return []; // place holder until this is ready
    },
    isShowingTrip(): boolean {
      return !!(this.$store.state as State).selectedTrip;
    },
  },
  components: { SuggestedEVsSection, VehicleGalleryCard, OutlinedBtn },
  methods: {
    handleSelect(vehicle: Vehicle) {
      this.$store.dispatch(ActionTypes.selectVehicle, vehicle);
      this.$store.commit(MutationTypes.setMainDialogContent, undefined);
    },
    /** swaps modal content with connected vehicles gallery */
    showConnected() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY_CONNECTED
      );
    },
    /** swaps modal content with custom vehicles gallery */
    showCustom() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY_CUSTOM
      );
    },
    /** swaps modal content with generic vehicles gallery */
    showGeneric() {
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.VEHICLE_GALLERY_GENERIC
      );
    },
  },
});
</script>
