<template>
  <v-card flat>
    <v-card-text>{{ moduleName || "Unknown" }}</v-card-text>
    <template v-if="savedStyle">
      <SavedTripCard
        v-for="(trip, index) in groupedTrips.trips"
        :key="`saved-trip-card-${index}`"
        :trip="trip"
      />
    </template>
    <template v-else>
      <UnsavedTripCard
        v-for="(trip, index) in groupedTrips.trips"
        :key="`unsaved-trip-card-${index}`"
        :trip="trip"
      />
    </template>
  </v-card>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { State } from "@/logic/store/store_types";
import Trip from "@/logic/classes/trip_classes/trip";
import SavedTripCard from "./SavedTripCard.vue";
import UnsavedTripCard from "./UnsavedTripCard.vue";

interface SortedTripsGroup {
  evModel: string;
  trips: Trip[];
}

export default Vue.extend({
  name: "StoredTripsGroupCard",
  props: {
    groupedTrips: Object as PropType<SortedTripsGroup>,
    savedStyle: { default: false, type: Boolean },
  },
  computed: {
    moduleName(): string | undefined {
      return (this.$store.state as State).evModels?.find(
        (model) => model.id === this.groupedTrips.evModel
      )?.name;
    },
    ...mapState({}),
  },
  components: { UnsavedTripCard, SavedTripCard },
});
</script>
