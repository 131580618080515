<template>
  <v-row>
    <v-col cols="1" class="py-0" v-if="type === 'origin'" align-self="end">
      <v-icon color="primary" :size="iconSize"> mdi-map-marker-outline </v-icon>
      <div :style="dividerStyle" :class="dividerColor"></div>
    </v-col>
    <v-col
      cols="1"
      class="py-0"
      v-if="type === 'destination'"
      align-self="start"
    >
      <div :style="dividerStyle" :class="dividerColor"></div>
      <v-icon color="primary" :size="iconSize"> mdi-map-marker </v-icon>
    </v-col>
    <v-col cols="1" class="py-0" v-if="type === 'stop'" align-self="center">
      <div :style="dividerStyle" :class="dividerColor"></div>
      <v-icon color="primary" large> mdi-map-marker-outline </v-icon>
      <div :style="dividerStyle" :class="dividerColor"></div>
    </v-col>
    <v-col cols="11">
      <AddressAutocompleteInput
        class="px-4"
        :class="type === 'destination' ? 'mb-5' : ''"
        @update="handleUpdate"
        :initialValue="addressData"
        :id="id"
        allowFavLocations
        :placeholder="placeholder"
        :loading="loading"
        :readonly="readonly"
        :errorMsg="errorMsg"
        :appendIcon="appendIcon"
        @append="$emit('append')"
        @clear="$emit('clear')"
        @click="$emit('click')"
      />
    </v-col>
  </v-row>
</template>
<script lang="ts">
import { processedAddressObj } from "@/logic/utils/processAddressSearchResults";
import Vue, { PropType } from "vue";
import AddressAutocompleteInput, {
  AddressAutocompleteInputUpdateObj,
} from "../../ui-elements/inputs/AddressAutocompleteInput.vue";

export default Vue.extend({
  name: "LocationsListRow",
  props: {
    addressData: {
      type: Object as PropType<processedAddressObj | undefined>,
    },
    id: {
      type: String,
      required: true,
    },
    type: {
      type: String as PropType<"origin" | "stop" | "destination">,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    errorMsg: {
      type: String as PropType<string | null>,
      default: null,
    },
    appendIcon: {
      type: String as PropType<string | undefined>,
      default: undefined,
    },
  },
  data() {
    return {
      iconSize: 36,
      dividerWidth: 2,
      dividerColor: "grey lighten-1",
    };
  },
  computed: {
    dividerStyle(): string {
      return `height: ${60 - (this.type === "stop" ? this.iconSize - 5 : this.iconSize)}px; width: ${this.dividerWidth}px; margin-left: ${
        (this.iconSize - this.dividerWidth) / 2
      }px`;
    },
    circleStyle(): string {
      return `width: ${this.iconSize}px; height: ${this.iconSize}px`;
    },
    placeholder(): string | undefined {
      switch (this.type) {
        case "origin":
          return "Search for starting address";
        case "stop":
          return "Search for stop address";
        case "destination":
          return "Search for destination address";
        default:
          return undefined;
      }
    },
  },
  methods: {
    handleUpdate(val: AddressAutocompleteInputUpdateObj) {
      this.$emit("update", val);
    },
  },
  components: {
    AddressAutocompleteInput,
  },
});
</script>
