import { render, staticRenderFns } from "./TripsHomeBtn.vue?vue&type=template&id=15b19578"
import script from "./TripsHomeBtn.vue?vue&type=script&lang=ts"
export * from "./TripsHomeBtn.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports