<template>
  <v-card
    id="pwt-info-panel"
    :width="$vuetify.breakpoint.xsOnly ? 'initial' : '40%'"
    height="100%"
    :class="classes"
    :min-width="$vuetify.breakpoint.xs ? `100%` : 400"
    max-width="600"
  >
    <!-- Account details card -->
    <v-card
      class="d-flex justify-space-between align-center elevation-5 pl-5"
      style="height: 100px"
      @click="emitShowDashboard"
      @keydown="keyDown($event)"
    >
      <div class="d-flex align-center">
        <v-avatar color="primary">
          <img v-if="avatarSrc" :src="avatarSrc" />
          <v-icon v-else dark> mdi-account-circle </v-icon>
        </v-avatar>
        <v-card flat color="transparent">
          <v-card-title> {{ usersName ?? "Name" }}</v-card-title>
          <v-card-subtitle>{{ emailAddress ?? "email" }}</v-card-subtitle>
        </v-card>
      </div>
      <v-btn icon class="align-self-start ma-2" @click.stop="emitMinimize">
        <v-icon>mdi-window-minimize </v-icon>
      </v-btn>
    </v-card>
    <v-divider />
    <v-card
      flat
      class="pwt-scroll-container overflow-y-auto pwt-scrollbar-styles"
      style="padding-bottom: 100px"
      id="info-panel-scroll-container"
    >
      <router-view />
    </v-card>
  </v-card>
</template>

<script lang="ts">
import Vue, { PropType } from "vue";
import { mapState } from "vuex";
import { GettersTypes, type State } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import { RouteNames } from "@/logic/router";
import Driver from "@/logic/classes/user_classes/driver";

/**Vue Component: renders the main UI panel for users to operate the app. To be used in the 'App' component. */
export default Vue.extend({
  name: "InfoPanel",
  props: {
    animation: String as PropType<"slide-left" | "slide-right" | null>,
  },
  computed: {
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    ...mapState({
      loadingStations: (state: unknown) => (state as State).chargerFetchingFlag,
      viewingTrip: (state: unknown) => !!(state as State).selectedTrip,
      usersName: (state: unknown) => (state as State).user?.fullName,
      emailAddress: (state: unknown) => (state as State).user?.email,
    }),
    classes(): string {
      if (this.animation == "slide-left") {
        return this.$vuetify.breakpoint.xs
          ? "overflow-hidden ma-0 rounded-0"
          : "overflow-hidden ma-0 rounded-0 pwt-info-panel-right-to-left-animation";
      }

      if (this.animation == "slide-right") {
        return this.$vuetify.breakpoint.xs
          ? "overflow-hidden ma-0 rounded-0"
          : "overflow-hidden ma-0 rounded-0 pwt-info-panel-left-to-right-animation";
      }

      return this.$vuetify.breakpoint.xs
        ? "overflow-hidden ma-0 rounded-0"
        : "overflow-hidden ma-0 rounded-0";
    },
    currentRouteName(): RouteNames | undefined {
      return (this.$route.name as RouteNames | null | undefined) ?? undefined;
    },
  },
  methods: {
    getSrc(partialFilePath: string): string {
      return getAssetSrc(partialFilePath);
    },
    handleRouteChange(routeName: string) {
      // attempting to navigate to the route name that is currently being displayed guard clause.
      if (this.$router.currentRoute.name === routeName) return;
      this.$router.push({ name: routeName });
      this.notifyAnalytics();
    },
    emitMinimize() {
      this.$emit("minimize");
    },
    emitShowDashboard() {
      this.$emit("showDashboard");
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Enter") this.emitShowDashboard();
    },
    notifyAnalytics() {
      // Notify analytics server
      Vue.prototype.$Countly.q.push(["track_pageview", this.currentRouteName]);
    },
    resetScrollPosition() {
      this.$nextTick(() => {
        const myDiv = document.getElementById("info-panel-scroll-container");
        if (myDiv) myDiv.scrollTop = 0;
      });
    },
  },
  data() {
    return {
      minAnimation: false,
    };
  },
  watch: {
    $route(to, from) {
      if (to.name !== from.name) {
        this.resetScrollPosition();
      }
    },
  },
});
</script>

<style scoped>
#pwt-info-panel {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1102;
  border-radius: 10px;
}

#pwt-info-panel-expand-btn {
  z-index: 1102;
  position: absolute;
  bottom: 8px;
  left: 8px;
}

#pwt-info-panel-expand-btn-mobile {
  z-index: 1102;
  position: absolute;
  top: 5px;
  left: 5px;
}

#pwt-info-panel-minimize-btn {
  z-index: 1102;
  position: absolute;
  top: 0;
  left: clamp(410px, calc(40% + 10px), 610px);
}

#pwt-info-panel-floating-controls-overlay {
  z-index: 1102;
  position: relative;
  min-width: 400px;
  max-width: 600px;
  width: 40%;
  height: calc(100% - 30px) !important;
  background-color: transparent;
}

.pwt-info-panel-left-to-right-animation {
  left: -41%;
  -webkit-animation: left-to-right 700ms linear forwards;
  animation: left-to-right 700ms linear forwards;
}

.pwt-info-panel-right-to-left-animation {
  left: 0;
  -webkit-animation: right-to-left 1s linear forwards;
  animation: right-to-left 1s linear forwards;
}

@-webkit-keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@-webkit-keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

@keyframes left-to-right {
  from {
    left: -41%;
  }
  to {
    left: 0;
  }
}
@keyframes right-to-left {
  from {
    left: 0;
  }
  to {
    left: -100%;
  }
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
