<template>
  <v-btn
    :color="color"
    :disabled="disabled"
    :loading="loading"
    class="rounded-pill text-none px-5"
    :class="darkText ? '' : 'white--text'"
    @click="emitClick"
  >
    <slot></slot>
  </v-btn>
</template>
<script lang="ts">
import { powerTripDarkBlue } from "@/logic/data/const";
import Vue from "vue";

export default Vue.extend({
  name: "ElevatedBtn",
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: powerTripDarkBlue,
    },
    darkText: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    emitClick() {
      this.$emit("click");
    },
  },
});
</script>
