<template>
  <v-dialog
    v-if="$vuetify.breakpoint.smAndDown"
    v-model="showMenu"
    fullscreen
    hide-overlay
    transition="dialog-top-transition"
    content-class="background"
    class="background"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar color="primary" class="mr-3 mt-1" v-bind="attrs" v-on="on">
        <img v-if="avatarSrc" :src="avatarSrc" />
        <v-icon v-else dark> mdi-account-circle </v-icon>
      </v-avatar>
    </template>
    <v-card class="background">
      <v-toolbar flat class="background">
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-btn icon @click="showMenu = false">
            <v-icon color="grey lighten-1">mdi-close</v-icon>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>
      <AvatarMenuListContent />
    </v-card>
  </v-dialog>
  <!-- normal size avatar and menu -->
  <v-menu
    v-else
    v-model="showMenu"
    absolute
    offset-y
    content-class="rounded-lg"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-avatar
        color="primary"
        class="mr-3 mt-1"
        v-bind="attrs"
        v-on="on"
        :size="small ? 36 : 48"
      >
        <img v-if="avatarSrc" :src="avatarSrc" />
        <v-icon v-else dark :small="small"> mdi-account-circle </v-icon>
      </v-avatar>
    </template>

    <AvatarMenuListContent />
  </v-menu>
</template>
<script lang="ts">
import Vue from "vue";
import AvatarMenuListContent from "./AvatarMenuListContent.vue";
import Driver from "@/logic/classes/user_classes/driver";
import { GettersTypes } from "@/logic/store/store_types";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "AvatarIconMenu",
  props: {
    small: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      showMenu: false,
    };
  },
  components: {
    AvatarMenuListContent,
  },
  computed: {
    /**
     * Returns the src path for the users profile picture if they have one.
     *
     * @return {string | undefined} The src path for the users profile picture if they have one, undefined otherwise.
     */
    avatarSrc(): string | undefined {
      const driver: Driver | undefined =
        this.$store.getters[GettersTypes.currentDriver];
      return driver?.profilePicSrc;
    },
    ...mapGetters({
      driver: GettersTypes.currentDriver,
    }),
  },
});
</script>
