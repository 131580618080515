<template>
  <div class="d-flex pt-1 px-1">
    <div
      class="rounded-circle pulse"
      :style="`background-color: ${color}; height: ${size}px; width: ${size}px`"
    ></div>
    <div
      class="rounded-circle pulse-1st-delay"
      :style="`background-color: ${color}; height: ${size}px; width: ${size}px`"
    ></div>
    <div
      class="rounded-circle pulse-2nd-delay"
      :style="`background-color: ${color}; height: ${size}px; width: ${size}px`"
    ></div>
  </div>
</template>
<script lang="ts">
import Vue, { PropType } from "vue";
/** Vue Component: renders a pules loader animated ellipsis
 *
 * @prop `color` takes any valid CSS compatible color as a string. Default is "#9E9E9E".
 * @prop `size` takes a number to be used as the height and width in px for each dot in the ellipsis. Default is 5.
 */
export default Vue.extend({
  name: "PulseLoader",
  props: {
    color: {
      type: String as PropType<string>,
      default: "#9E9E9E",
    },
    size: {
      type: Number as PropType<number>,
      default: 5,
    },
  },
});
</script>
<style scoped>
.pulse {
  animation: pulse-loading 1s linear infinite alternate;
  margin-right: 2px;
}

.pulse-1st-delay {
  animation: pulse-loading 1s linear 0.3s infinite alternate;
  margin-right: 2px;
}

.pulse-2nd-delay {
  animation: pulse-loading 1s linear 0.6s infinite alternate;
  margin-right: 2px;
}

@keyframes pulse-loading {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0.3;
  }
}
</style>
