<template>
  <v-dialog
    :value="!!content"
    :content-class="$vuetify.breakpoint.xs ? '' : 'rounded-lg'"
    style="z-index: 1400"
    @click:outside="close()"
    @keydown="keyDown($event)"
    :width="$vuetify.breakpoint.mdAndUp ? '50%' : '70%'"
    max-width="600px"
    min-width="300px"
    :fullscreen="$vuetify.breakpoint.xs"
    scrollable
  >
    <v-card
      class="overflow-hidden py-5"
      id="pwt-card"
      :style="$vuetify.breakpoint.xs ? 'height: 100vh;' : 'height: 50vh;'"
    >
      <v-card-title
        class="d-flex justify-space-between"
        style="word-break: normal"
      >
        <v-row no-gutters>
          <v-col cols="2" md="1">
            <v-icon color="primary" class="pb-1">
              {{ icon }}
            </v-icon>
          </v-col>
          <v-col cols="8" md="10">
            <span class="primary--text"> {{ title }} </span>
          </v-col>
          <v-col cols="2" md="1">
            <v-btn icon @click="close()">
              <v-icon> mdi-close </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto overflow-x-hidden pb-0"
        style="height: calc(100% - 96px)"
      >
        <!-- conditionally rendered content. -->
        <LocationDetailsContent v-if="content === 'location details'" />
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import Vue from "vue";
import { mapState } from "vuex";
import {
  TertiaryDialogContent,
  type State,
  MutationTypes,
} from "../../../logic/store/store_types";
import LocationDetailsContent from "../trips/Itinerary/dialog-content/LocationDetailsContent.vue";

/** Vue Component: renders the smallest of the three dialog wrapper to
 * display conditional content. To be used in the `App` component. */
export default Vue.extend({
  name: "TertiaryDialog",
  computed: {
    ...mapState({
      content: (state: unknown) => (state as State).tertiaryDialogContent,
      title: (state: unknown) => {
        switch ((state as State).tertiaryDialogContent) {
          case TertiaryDialogContent.LOCATION_DETAILS:
            return "Location details";
          default:
            return null;
        }
      },
      icon: (state: unknown) => {
        switch ((state as State).tertiaryDialogContent) {
          case TertiaryDialogContent.LOCATION_DETAILS:
            return "mdi-map-marker";
          default:
            return null;
        }
      },
    }),
  },
  components: {
    LocationDetailsContent,
  },
  methods: {
    close() {
      this.$store.commit(MutationTypes.setTertiaryDialogContent, undefined);
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.close();
    },
  },
  watch: {
    content(val: TertiaryDialogContent) {
      if (val) {
        // Notify analytics server
        // eslint-disable-next-line
        // @ts-ignore
        Vue.prototype.$Countly.q.push(["track_pageview", val]);
      }
    },
  },
});
</script>
<style scoped>
#pwt-card {
  background-color: var(--v-background-base);
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
