<template>
  <v-card flat class="pa-5 background">
    <!-- profile section -->
    <ProfileCard
      @view-account="emitViewAccount"
      @view-vehicle="emitViewVehicle"
      @view-vehicles="emitViewAllVehicles"
      @view-defaults="emitViewDefaults"
      @logout="emitLogout"
    />
    <!-- selected vehicle section -->
    <DefaultVehicleCard @view-vehicle="emitViewVehicle" class="mb-10" />
    <!-- saved trips section -->
    <SavedTripCarousel @view-trip="emitViewTrip" class="mb-10" />
    <!-- my fleet section -->
    <MyFleetContent
      @view-vehicles="emitViewAllVehicles"
      @view-locations="emitViewLocations"
      @view-vehicle="emitViewVehicle"
    />
  </v-card>
</template>

<script lang="ts">
import Vue from "vue";
import ProfileCard from "@/ui/components/user/profile/info-panel-content/ProfileCard.vue";
import DefaultVehicleCard from "../vehicles/DefaultVehicleCard.vue";
import SavedTripCarousel from "../trips/history/SavedTripCarousel.vue";
import MyFleetContent from "./MyFleetContent.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { GettersTypes } from "@/logic/store/store_types";

export default Vue.extend({
  name: "DashboardMain",
  components: {
    ProfileCard,
    DefaultVehicleCard,
    SavedTripCarousel,
    MyFleetContent,
  },
  methods: {
    /**
     * Emits an event to view a vehicle. If the vehicle being viewed is the
     * same as the selected vehicle, the event emits `undefined` as the vehicle
     * ID. Otherwise, the event emits the local ID of the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle to be viewed.
     * @return {void} This function does not return anything.
     */
    emitViewVehicle(vehicle?: Vehicle): void {
      const id =
        vehicle?.localId ===
        this.$store.getters[GettersTypes.selectedVehicleData]?.localId
          ? undefined
          : vehicle?.localId;
      this.$emit("view-vehicle", id);
    },
    /**
     * Emits an event to view the user's account.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAccount(): void {
      this.$emit("view-account");
    },
    /**
     * Emits an event to view a trip.
     *
     * @return {void} This function does not return anything.
     */
    emitViewTrip(): void {
      this.$emit("view-trip");
    },
    /**
     * Emits an event to view all vehicles.
     *
     * @return {void} This function does not return anything.
     */
    emitViewAllVehicles(): void {
      this.$emit("view-all-vehicles");
    },
    /**
     * Emits an event to view the user's locations.
     *
     * @return {void} This function does not return anything.
     */
    emitViewLocations() {
      this.$emit("view-locations");
    },
    /**
     * Emits the 'view-defaults' event to the parent component.
     * Used to navigate to the user trip planning defaults page.
     *
     * @return {void} This function does not return anything.
     */
    emitViewDefaults() {
      this.$emit("view-defaults");
    },
    /**
     * Emits a "logout" event to the parent component.
     *
     * @return {void} This function does not return anything.
     */
    emitLogout() {
      this.$emit("logout");
    },
  },
});
</script>
