<template>
  <v-card class="py-4 pl-md-12 rounded-lg mb-10">
    <v-row class="flex-column flex-md-row">
      <v-col md="3">
        <!-- image section -->
        <v-skeleton-loader
          type="image"
          width="100%"
          height="140px"
          v-if="!vehicle"
        />
        <v-img
          v-else
          contain
          :src="imageSrc(vehicle)"
          aspect-ratio="1"
          :width="$vuetify.breakpoint.mdAndUp ? '100%' : '40%'"
          :height="$vuetify.breakpoint.mdAndUp ? '100%' : 'auto'"
          style="margin: auto"
        ></v-img>
      </v-col>
      <v-col md="9">
        <!-- details section -->
        <div v-if="vehicle">
          <v-card-title>
            {{ vehicle.name ?? "unnamed vehicle" }}
          </v-card-title>
          <v-card-subtitle class="font-weight-medium pr-12">
            <p class="mb-0">
              {{ vehicle.licensePlate ?? "unrecorded license plate" }}
            </p>
            <p v-if="vehicle.evModel">
              {{ vehicle.evModel.name }}
            </p>
          </v-card-subtitle>
          <v-card-text class="pr-12">
            <v-slider
              style="max-width: 250px"
              :value="vehicle.stateOfCharge ?? 0"
              readonly
              :label="(vehicle.stateOfCharge ?? '??') + '% battery'"
              min="0"
              max="100"
              track-color="grey lighten-2"
              :messages="!vehicle.stateOfCharge ? 'charge unknown' : undefined"
            />
          </v-card-text>
        </div>
        <div v-else>
          <v-row no-gutters class="mb-4">
            <v-skeleton-loader type="heading" width="70%" />
            <v-skeleton-loader type="heading" width="30%" />
          </v-row>
          <v-skeleton-loader type="text" width="40%" />
          <v-skeleton-loader type="text" width="70%" class="mb-4" />
          <v-skeleton-loader type="heading" width="100%" />
        </div>
      </v-col>
    </v-row>
  </v-card>
</template>
<script lang="ts">
import EVModel from "@/logic/classes/vehicle_classes/evModel";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import { State } from "@/logic/store/store_types";
import getAssetSrc from "@/logic/utils/getAssetSrc";
import Vue, { PropType } from "vue";

export default Vue.extend({
  name: "SelectedComparisonVehicleCard",
  props: {
    vehicle: {
      type: Object as PropType<Vehicle | undefined>,
    },
  },
  methods: {
    /**
     * Returns the source URL of the image associated with the vehicle.
     *
     * @param {Vehicle} vehicle - The vehicle object.
     * @return {string} The source URL of the image.
     */
    imageSrc(vehicle: Vehicle): string {
      // get uploaded image to car in first instance.
      const images = vehicle.imageSrcPaths;
      if (images.length) return images[0];
      // get model image in second instance.
      if (vehicle.evModel)
        return (
          (vehicle.evModel as EVModel)?.imageSrc ??
          getAssetSrc("car_images/No_Image_Powersell.png")
        );
      // get company logo image in third instance.
      const company = (this.$store.state as State).fleet;
      if (company && company.logoSrc) {
        return company.logoSrc;
      }
      // get default image in fourth instance.
      return getAssetSrc("car_images/No_Image_Powersell.png");
    },
  },
});
</script>
<style scoped>
* >>> .v-slider--horizontal {
  margin-left: unset;
  margin-right: unset;
}
* >>> .v-slider--horizontal .v-slider__track-container {
  height: 6px; /* override default slider thickness */
}

* >>> .v-slider__track-fill {
  border-radius: 2px; /* override default slider border-radius */
}

* >>> .v-slider__track-background {
  border-radius: 2px; /* override default slider border-radius */
}
</style>
