<template>
  <v-dialog
    :value="activator"
    :width="$vuetify.breakpoint.mdAndUp ? '75%' : '100%'"
    :content-class="
      $vuetify.breakpoint.mdAndUp
        ? 'rounded-lg background pwt-scrollbar-styles'
        : 'background pwt-scrollbar-styles'
    "
    class="pwt-scroll-styles"
    scrollable
    style="z-index: 1200"
    @click:outside="emitClose()"
    @keydown="keyDown($event)"
    :fullscreen="$vuetify.breakpoint.smAndDown"
  >
    <!-- must have vh for height to work with v-dialog having no exposed height prop. -->
    <v-card
      style="height: 80vh"
      :class="
        $vuetify.breakpoint.smAndDown
          ? 'background overflow-hidden'
          : 'rounded-lg background overflow-hidden'
      "
      flat
    >
      <div
        id="dashboard-scroll-container"
        class="pwt-scroll-container pwt-scrollbar-styles overflow-y-auto"
      >
        <!-- second card to counteract miss-behaving v-dialog overflow due to vh needed earlier. -->
        <v-card
          class="pt-5 background"
          :class="$vuetify.breakpoint.mdAndUp ? 'pr-10 pl-5' : 'px-0'"
          flat
          style="position: relative"
        >
          <DashboardMobileAppBar
            v-if="$vuetify.breakpoint.smAndDown"
            @close="emitClose"
            @view-home="viewDashboard"
            @view-account="viewAccount"
            @view-vehicle="viewVehicle"
            @view-vehicles="viewAllVehicles"
            @view-defaults="viewDefaults"
            @view-locations="viewLocationsManager"
            @view-saved-trips="viewSavedTrips"
            :tab="tab"
          />
          <!-- tabs -->
          <v-tabs
            v-if="$vuetify.breakpoint.mdAndUp"
            vertical
            :value="tab"
            style="position: fixed; top: calc(10vh + 40px); width: 200px"
            hide-slider
            @change="resetScrollPosition"
            optional
          >
            <v-tab
              class="text-none background"
              tab-value="home"
              @click="viewDashboard"
            >
              Home <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none background"
              tab-value="account"
              @click="viewAccount"
            >
              Account <v-spacer></v-spacer>
            </v-tab>
            <v-expansion-panels v-model="expand" accordion flat tile>
              <v-expansion-panel>
                <v-expansion-panel-header class="v-tab text-none background">
                  Vehicles
                </v-expansion-panel-header>
                <v-expansion-panel-content class="background">
                  <v-tab
                    class="text-none"
                    :class="tab === 'vehicle-details' ? 'primary--text' : ''"
                    tab-value="vehicle-details"
                    @click="viewVehicle(undefined)"
                  >
                    Vehicle details <v-spacer></v-spacer>
                  </v-tab>
                  <v-tab
                    class="text-none"
                    :class="tab === 'fleet-vehicles' ? 'primary--text' : ''"
                    tab-value="fleet-vehicles"
                    @click="viewAllVehicles"
                  >
                    Fleet vehicles <v-spacer></v-spacer>
                  </v-tab>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <v-tab
              class="text-none background"
              tab-value="locations-manager"
              @click="viewLocationsManager"
            >
              Locations manager
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none background"
              tab-value="defaults"
              @click="viewDefaults"
            >
              Trip planning defaults
              <v-spacer></v-spacer>
            </v-tab>
            <v-tab
              class="text-none background"
              tab-value="saved-trips"
              @click="viewSavedTrips"
            >
              Saved trips <v-spacer></v-spacer>
            </v-tab>
          </v-tabs>
          <v-tabs-items
            v-model="tab"
            :style="
              $vuetify.breakpoint.smAndUp
                ? 'margin-left: 200px; border-left: 2px solid rgba(218, 218, 218, 0.5) !important'
                : ''
            "
            class="background"
            :class="$vuetify.breakpoint.smAndUp ? 'py-5 pl-5' : 'pa-5'"
          >
            <v-tab-item value="home">
              <DashboardMain
                @view-vehicle="viewVehicle"
                @view-account="viewAccount"
                @view-trip="emitClose"
                @view-all-vehicles="viewAllVehicles"
                @view-locations="viewLocationsManager"
                @view-defaults="viewDefaults"
                @logout="emitClose"
              />
            </v-tab-item>
            <v-tab-item value="account">
              <DashboardBreadcrumbs
                v-if="$vuetify.breakpoint.mdAndUp"
                :items="[
                  { text: 'Home' },
                  { text: 'Manage account', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <EditUserContent />
            </v-tab-item>
            <v-tab-item value="vehicle-details">
              <DashboardBreadcrumbs
                v-if="$vuetify.breakpoint.mdAndUp"
                :items="[
                  { text: 'Home' },
                  { text: 'Fleet vehicles' },
                  { text: 'Vehicle details', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Fleet vehicles' })"
              />
              <VehicleDetails :vehicle="vehicleData" :isSelected="isSelected" />
            </v-tab-item>
            <v-tab-item value="fleet-vehicles">
              <DashboardBreadcrumbs
                v-if="$vuetify.breakpoint.mdAndUp"
                :items="[
                  { text: 'Home' },
                  { text: 'Fleet vehicles', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <VehicleList @view-vehicle="viewVehicle" />
            </v-tab-item>
            <v-tab-item value="locations-manager">
              <DashboardBreadcrumbs
                v-if="$vuetify.breakpoint.mdAndUp"
                :items="[
                  { text: 'Home' },
                  { text: 'Location manager', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <FavLocationsMain />
            </v-tab-item>
            <v-tab-item value="defaults">
              <DashboardBreadcrumbs
                v-if="$vuetify.breakpoint.mdAndUp"
                :items="[
                  { text: 'Home' },
                  { text: 'Edit trip planning presets', disabled: true },
                ]"
                @navigation="breadcrumbNavigation"
                @back="breadcrumbNavigation({ text: 'Home' })"
              />
              <TripPlanningDefaults />
            </v-tab-item>
            <v-tab-item value="saved-trips">
              <SavedTripsContent @close="emitClose" />
            </v-tab-item>
          </v-tabs-items>
        </v-card>
      </div>
      <!-- floating close button -->
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp"
        icon
        @click.stop="emitClose()"
        style="position: absolute; top: 8px; right: 12px"
      >
        <v-icon color="grey lighten-1">mdi-close</v-icon>
      </v-btn>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import Vue from "vue";
import FavLocationsMain from "../favourite-locations/info-panel-content/FavLocationsMain.vue";
import TripPlanningDefaults from "./TripPlanningDefaults.vue";
import VehicleDetails from "../vehicles/VehicleDetails.vue";
import Vehicle from "@/logic/classes/vehicle_classes/vehicle";
import {
  GettersTypes,
  MutationTypes,
  State,
  DashboardDialogContent,
} from "@/logic/store/store_types";
import VehicleList from "../vehicles/VehicleList.vue";
import DashboardMain from "./DashboardMain.vue";
import DashboardBreadcrumbs, {
  BreadcrumbsItem,
} from "./DashboardBreadcrumbs.vue";
import DashboardMobileAppBar from "./DashboardMobileAppBar.vue";
import EditUserContent from "@/ui/components/user/profile/dialog-content/EditUserContent.vue";
import { mapState } from "vuex";
import SavedTripsContent from "./SavedTripsContent.vue";
export type tabs =
  | "home"
  | "account"
  | "vehicle-details"
  | "fleet-vehicles"
  | "locations-manager"
  | "saved-trips"
  | "defaults";

export default Vue.extend({
  name: "DashboardDialog",
  props: {
    activator: {
      type: Boolean,
    },
  },
  methods: {
    emitClose() {
      this.$emit("close");
    },
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    keyDown(event: any) {
      if (event.key === "Escape") this.emitClose();
    },
    /**
     * Sets the current vehicle ID and navigates to the vehicle details tab.
     *
     * @param {string|undefined} vehicleId - The ID of the vehicle to view.
     * @return {void}
     */
    viewVehicle(vehicleId: string | undefined) {
      this.vehicleId = vehicleId;
      this.expand = 0;
      this.$store.commit(
        MutationTypes.setDashboardDialogContent,
        "vehicle-details"
      );
      this.resetScrollPosition();
    },
    viewAllVehicles() {
      this.$store.commit(
        MutationTypes.setDashboardDialogContent,
        "fleet-vehicles"
      );
      this.expand = 0;
      this.resetScrollPosition();
    },
    viewAccount() {
      this.$store.commit(MutationTypes.setDashboardDialogContent, "account");
      this.resetScrollPosition();
    },
    viewLocationsManager() {
      this.$store.commit(
        MutationTypes.setDashboardDialogContent,
        "locations-manager"
      );
      this.resetScrollPosition();
    },
    viewDashboard() {
      this.$store.commit(MutationTypes.setDashboardDialogContent, "home");
      this.resetScrollPosition();
    },
    viewDefaults() {
      this.$store.commit(MutationTypes.setDashboardDialogContent, "defaults");
      this.resetScrollPosition();
    },
    viewSavedTrips() {
      this.$store.commit(
        MutationTypes.setDashboardDialogContent,
        "saved-trips"
      );
      this.resetScrollPosition();
    },
    breadcrumbNavigation(item: BreadcrumbsItem) {
      if (item.disabled) return;
      if (item.text === "Home") {
        this.viewDashboard();
      } else if (item.text === "Fleet vehicles") {
        this.viewAllVehicles();
      } else if (item.text === "Edit trip planning presets") {
        this.viewDefaults();
      } else if (item.text === "Manage account") {
        this.viewAccount();
      } else if (item.text === "Location manager") {
        this.viewLocationsManager();
      } else if (item.text === "Vehicle details") {
        this.viewVehicle(undefined);
      } else if (item.text === "Saved trips") {
        this.viewSavedTrips();
      }
    },
    resetScrollPosition() {
      this.$nextTick(() => {
        const myDiv = document.getElementById("dashboard-scroll-container");
        if (myDiv) myDiv.scrollTop = 0;
      });
    },
  },
  watch: {
    tab(value: DashboardDialogContent | undefined) {
      if (value === "vehicle-details" || value === "fleet-vehicles") {
        this.expand = 0;
      } else {
        this.expand = undefined;
      }
    },
    activator() {
      this.resetScrollPosition();
    },
  },
  components: {
    EditUserContent,
    FavLocationsMain,
    TripPlanningDefaults,
    VehicleDetails,
    VehicleList,
    DashboardMain,
    DashboardBreadcrumbs,
    DashboardMobileAppBar,
    SavedTripsContent,
  },
  data() {
    return {
      vehicleId: undefined as string | undefined,
      expand: undefined as number | undefined,
      showMenu: false,
    };
  },
  computed: {
    vehicleData(): Vehicle | undefined {
      return this.vehicleId
        ? this.$store.getters[GettersTypes.getVehicleById](this.vehicleId)
        : this.$store.getters[GettersTypes.selectedVehicleData];
    },
    isSelected(): boolean {
      return (
        this.vehicleData?.localId ===
        (this.$store.state as State).selectedVehicle
      );
    },
    ...mapState({
      tab: (state: unknown) => (state as State).dashboardDialogContent,
    }),
  },
});
</script>
<style scoped>
* >>> .v-slide-group__prev {
  flex: unset !important;
  min-width: 0px !important;
}

* >>> .v-slide-group {
  background-color: var(--v-background-base) !important;
}

.pwt-scroll-container {
  width: 100%;
  height: 100%;
}

.pwt-scrollbar-styles {
  scrollbar-color: #ffffff #e0e0e0;
  scrollbar-width: thin;
}

.pwt-scrollbar-styles:hover {
  scrollbar-color: #eeeeee #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar {
  width: 6px;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-track:hover {
  background: #e0e0e0;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb {
  background: #ffffff;
}

.pwt-scrollbar-styles::-webkit-scrollbar-thumb:hover {
  background: #eeeeee;
}
</style>
