<template>
  <svg
    width="747"
    height="747"
    viewBox="0 0 747 747"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_567_16279)">
      <path
        d="M280.189 560.291H653.607V498.055H280.189V560.291ZM93.4805 186.874V249.11H653.607V186.874H93.4805ZM280.189 404.701H653.607V342.464H280.189V404.701Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_567_16279"
        x="0.125977"
        y="0.164551"
        width="776.709"
        height="776.709"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="29.8734" dy="29.8734" />
        <feGaussianBlur stdDeviation="29.8734" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_567_16279"
        />
      </filter>
    </defs>
  </svg>
</template>
<script lang="ts">
/** Custom icon wrapped in a template to be used by vuetify to gain access to the vuetify component properties*/
export default {
  name: "ItineraryIcon",
};
</script>
