<template>
  <l-marker
    v-if="chargingStation.coordinates"
    :lat-lng="[
      chargingStation.coordinates.latitude,
      chargingStation.coordinates.longitude,
    ]"
    @click="pinClick"
    :icon="getIcon(pinIcon)"
  >
  </l-marker>
</template>

<script lang="ts">
import Charger from "@/logic/classes/charger_classes/charger";
import { MainDialogContent, MutationTypes } from "@/logic/store/store_types";
import L from "leaflet";
import Vue, { PropType } from "vue";

/** `Vue Leaflet Component:` renders map pins for charging stations, intended for use in the `MapPanel` component.
 *
 * @props `chargingStation` - the whole 'Charger' class object for charging station.
 */
export default Vue.extend({
  name: "ChargingPin",
  props: {
    chargingStation: Object as PropType<Charger>,
    isChargingStop: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    pinClick() {
      this.$store.commit(
        MutationTypes.setSelectedCharger,
        this.chargingStation.id
      );
      this.$store.commit(
        MutationTypes.setMainDialogContent,
        MainDialogContent.CHARGING_STATION_DETAILS
      );
    },
    getIcon(iconUrl: string) {
      return L.icon({
        iconUrl,
        iconSize: this.isChargingStop ? [65, 65] : [50, 50],
        iconAnchor: [25, 50],
      });
    },
  },
  computed: {
    pinIcon(): string {
      return this.chargingStation.pinIconSrc;
    },
  },
});
</script>
