<template>
  <svg
    width="663"
    height="672"
    viewBox="0 0 663 672"
    style="fill: currentColor"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_i_567_16246)">
      <path
        d="M247.459 522.445V601.986H326.086L557.984 367.393L479.356 287.852L247.459 522.445ZM618.788 305.881C626.966 297.609 626.966 284.246 618.788 275.974L569.725 226.34C561.548 218.068 548.339 218.068 540.161 226.34L501.791 265.156L580.418 344.697L618.788 305.881Z"
      />
      <path
        d="M344.026 369.317L251.758 464.514L227.798 456.656C222.207 454.676 218.471 449.389 218.471 443.459L218.471 76.3372C218.471 66.6719 228.032 59.914 237.143 63.14L333.086 85.7821C340.889 87.4136 344.056 92.5599 344.026 98.8158V369.317Z"
      />
      <path
        d="M373.899 338.495L373.899 102.912C373.899 97.1525 377.426 91.9808 382.788 89.8782L474.549 53.8946C475.359 53.577 476.196 53.3356 477.051 53.1735L478.421 52.9137C479.281 52.7506 480.157 52.6459 481.028 52.7356C487.297 53.3813 492.049 58.6088 492.049 65.1472V225.531L373.899 338.495Z"
      />
      <path
        d="M170.081 61.5015L56.8571 100.088C51.6762 101.835 47.9756 106.327 47.9756 112.067V489.425C47.9756 495.964 52.7271 501.191 58.9969 501.837C59.8677 501.927 60.7439 501.822 61.604 501.659L62.9736 501.399C63.8282 501.237 64.6658 500.996 65.4756 500.678L179.708 455.882C185.07 453.779 188.597 448.608 188.597 442.848L188.597 74.7531C188.597 65.1578 179.164 58.4062 170.081 61.5015Z"
      />
    </g>
    <defs>
      <filter
        id="filter0_i_567_16246"
        x="47.9756"
        y="52.7026"
        width="606.819"
        height="579.157"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="29.8734" dy="29.8734" />
        <feGaussianBlur stdDeviation="29.8734" />
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="shape"
          result="effect1_innerShadow_567_16246"
        />
      </filter>
    </defs>
  </svg>
</template>
<script lang="ts">
/** Custom icon wrapped in a template to be used by vuetify to gain access to the vuetify component properties*/
export default {
  name: "TripsIcon",
};
</script>
